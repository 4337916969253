import React, { Component } from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "bootstrap/dist/css/bootstrap.css";
import { connect } from "react-redux";
import {
  changeClientWebsiteFilter,
  changeClientDomainFilter,
  changeSocialMediaDomainFilter,
  changeEcommerceDomainFilter,
  changeAppStoreDomainFilter,
  changeTmchFilter,
  changeTrapPurchaseDomainFilter,
} from "./../store/actions/filterActions";
import {
  fetchInfrigementAllStatus,
  getInfrigementStatusText,
  fetchInfrigementEcommerceStatus,
} from "./../infrigementStatusCode";
import { castToDefaultDateFormat } from "./../settings/index";
import { fetchInfrigementAllPriority } from "./../infrigementPriority";

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteFilters: this.props.clientWebsiteFilter,
      filters: {
        domain: this.props.clientDomainFilter,
        website: this.props.clientWebsiteFilter,
        socialMedia: this.props.clientSocialMediaFilter,
        ecommerce: this.props.clientEcommerceFilter,
        appStore: this.props.clientAppStoreFilter,
        tmch: this.props.clientTmchFilter,
        trapPurchase: this.props.clientTrapPurchaseFilter,
      },
      brands: [],
      platforms: {},
    };

    this.changeInputFilterValue = this.changeInputFilterValue.bind(this);
  }

  changeInputFilterValue(filterType, attributeName, value) {
    if (filterType === "website") {
      let websiteFilterProps = this.props.clientWebsiteFilter;
      websiteFilterProps[attributeName] = value;
      this.props.dispatch(changeClientWebsiteFilter(websiteFilterProps));
    }

    if (filterType === "domain") {
      let domainFilterProps = this.props.clientDomainFilter;
      domainFilterProps[attributeName] = value;
      this.props.dispatch(changeClientDomainFilter(domainFilterProps));
    }

    if (filterType === "socialMedia") {
      let socialMediaFilterProps = this.props.clientSocialMediaFilter;
      socialMediaFilterProps[attributeName] = value;
      this.props.dispatch(
        changeSocialMediaDomainFilter(socialMediaFilterProps)
      );
    }

    if (filterType === "ecommerce") {
      let ecommerceFilterProps = this.props.clientEcommerceFilter;
      ecommerceFilterProps[attributeName] = value;
      this.props.dispatch(changeEcommerceDomainFilter(ecommerceFilterProps));
    }

    if (filterType === "appStore") {
      let appStoreFilterProps = this.props.clientAppStoreFilter;
      appStoreFilterProps[attributeName] = value;
      this.props.dispatch(changeAppStoreDomainFilter(appStoreFilterProps));
    }

    if (filterType === "tmch") {
      let tmchFilterProps = this.props.clientTmchFilter;
      tmchFilterProps[attributeName] = value;
      this.props.dispatch(changeTmchFilter(tmchFilterProps));
    }

    if (filterType === "trapPurchase") {
      let trapPurchaseFilterProps = this.props.clientTrapPurchaseFilter;
      trapPurchaseFilterProps[attributeName] = value;
      this.props.dispatch(changeTrapPurchaseDomainFilter(trapPurchaseFilterProps));
    }
  }

  renderInputAreaForFilters(
    inputDetails,
    filterType,
    attributeName,
    defaultValue
  ) {
    if (inputDetails.type === "input" || inputDetails.type === "date") {
      return (
        <>
          <div className="">
            <input
              type={inputDetails.type}
              value={this.state?.filters[filterType][attributeName]}
              onChange={(e) =>
                this.setState(() => {
                  let filters = this.state.filters;
                  filters[filterType][attributeName] = e.target.value;

                  return {
                    filters,
                  };
                })
              }
              className="form-control"
            />
            <button
              onClick={(e) =>
                this.changeInputFilterValue(
                  filterType,
                  attributeName,
                  this.state.filters[filterType][attributeName]
                )
              }
              className="btn btn-success mt-1"
            >
              Filter
            </button>
            <button
              onClick={(e) => {
                this.setState(() => {
                  let filters = this.state.filters;
                  filters[filterType][attributeName] = "";

                  return {
                    filters,
                  };
                });
                this.changeInputFilterValue(filterType, attributeName, "");
              }}
              className="btn btn-danger mt-1 mx-1"
            >
              Reset
            </button>
          </div>
        </>
      );
    }

    if (inputDetails.type === "select") {
      return (
        <>
          <select
            className="form-control"
            onChange={(e) =>
              this.setState(() => {
                let filters = this.state.filters;
                filters[filterType][attributeName] = e.target.value;

                return {
                  filters,
                };
              })
            }
          >
            {inputDetails.options.map((option, optionIndex) => (
              <option
                selected={
                  this.state.filters[filterType][attributeName] === option.code
                    ? "selected"
                    : ""
                }
                key={optionIndex}
                value={option.code}
              >
                {option.text}
              </option>
            ))}
          </select>
          <button
            onClick={(e) =>
              this.changeInputFilterValue(
                filterType,
                attributeName,
                this.state.filters[filterType][attributeName]
              )
            }
            className="btn btn-success mt-1"
          >
            Filter
          </button>
          <button
            onClick={(e) => {
              this.setState(() => {
                let filters = this.state.filters;
                filters[filterType][attributeName] = "";

                return {
                  filters,
                };
              });
              this.changeInputFilterValue(filterType, attributeName, "");
            }}
            className="btn btn-danger mt-1 mx-1"
          >
            Reset
          </button>
        </>
      );
    }
  }

  render() {
    const renderFilterPopover = (
      inputDetails,
      filterType,
      attributeName,
      defaultValue
    ) => {
      return (
        <Popover id="popover-positioned-right" title="Popover right">
          <div className="container py-1 px-1">
            <div className="row">
              <div className="col-12 mx-0 py-0">
                {this.renderInputAreaForFilters(
                  inputDetails,
                  filterType,
                  attributeName,
                  defaultValue
                )}
              </div>
            </div>
          </div>
        </Popover>
      );
    };

    return (
      <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0">
        <div className="main-navbar">
          <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
            <Link
              className="navbar-brand w-100 mr-0"
              href="#"
              to="/"
              style={{ lineHeight: "25px" }}
            >
              <div className="d-table m-auto">
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1"
                  style={{ maxWidth: "50px" }}
                  src="https://static.wixstatic.com/media/dbc393_7e1659bad6044278b30ff8d6d4667516~mv2_d_2963_1605_s_2.jpg/v1/fill/w_111,h_60,al_c,q_80,usm_0.66_1.00_0.01/LdotR_Logo.webp"
                  alt="LDotR"
                />
              </div>
            </Link>
            <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
              <i className="material-icons">&#xE5C4;</i>
            </a>
          </nav>
        </div>

        <div className="nav-wrapper">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                className={
                  window.location.pathname === "/client-dashboard"
                    ? "nav-link active"
                    : "nav-link"
                }
                to="/client-dashboard"
              >
                <span>DASHBOARD</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.tmch
                  ? "#tmchSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#tmchSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.tmch
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.tmch
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/tmch-dashboard" ? "active" : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.tmch
                    ? "/tmch-dashboard"
                    : "#"
                }
              >
                <span>TMCH</span>
              </Link>

              <ul
                className="collapse list-unstyled dropdown-menu"
                id="tmchSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "tmch",
                      "markName",
                      this.props.clientTmchFilter.markName
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Mark Name{" "}
                      <small>{this.props.clientTmchFilter.markName}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "tmch",
                      "markType",
                      this.props.clientTmchFilter.markType
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Mark Type{" "}
                      <small>{this.props.clientTmchFilter.markType}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "date" },
                      "tmch",
                      "reportingDate",
                      this.props.clientTmchFilter.reportingDate
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Reporting Date{" "}
                      <small>
                        {castToDefaultDateFormat(
                          this.props.clientTmchFilter.reportingDate
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "select", options: fetchInfrigementAllStatus() },
                      "tmch",
                      "status",
                      this.props.clientTmchFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientTmchFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.domain
                  ? "#domainSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#domainSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.domain
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.domain
                  ? "dropdown-toggle"
                  : ""
                  }  ${window.location.pathname === "/domain-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.domain
                    ? "/domain-dashboard"
                    : "#"
                }
              >
                <span>DOMAIN</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="domainSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "domain",
                      "domain",
                      this.props.clientDomainFilter.domain
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Domain{" "}
                      <small>{this.props.clientDomainFilter.domain}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "domain",
                      "tld",
                      this.props.clientDomainFilter.tld
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      TLD <small>{this.props.clientDomainFilter.tld}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "date" },
                      "domain",
                      "reportingDate",
                      this.props.clientDomainFilter.reportingDate
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Reporting Date{" "}
                      <small>
                        {castToDefaultDateFormat(
                          this.props.clientDomainFilter.reportingDate
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "domain",
                      "priority",
                      this.props.clientDomainFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>{this.props.clientDomainFilter.priority}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "select", options: fetchInfrigementAllStatus() },
                      "domain",
                      "status",
                      this.props.clientDomainFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientDomainFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.website
                  ? "#websiteSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#websiteSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.website
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.website
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/website-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.website
                    ? "/website-dashboard"
                    : "#"
                }
              >
                <span>WEBSITE</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="websiteSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "website",
                      "url",
                      this.props.clientWebsiteFilter.url
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      URL <small>{this.props.clientWebsiteFilter.url}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "date" },
                      "website",
                      "reportingDate",
                      this.props.clientWebsiteFilter.reportingDate
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Reporting Date{" "}
                      <small>
                        {castToDefaultDateFormat(
                          this.props.clientWebsiteFilter.reportingDate
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "website",
                      "priority",
                      this.props.clientWebsiteFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>{this.props.clientWebsiteFilter.priority}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "select", options: fetchInfrigementAllStatus() },
                      "website",
                      "status",
                      this.props.clientWebsiteFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientWebsiteFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.socialMedia
                  ? "#socialMediaSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#socialMediaSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.socialMedia
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.socialMedia
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/social-media-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.socialMedia
                    ? "/social-media-dashboard"
                    : "#"
                }
              >
                <span>SOCIAL MEDIA</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="socialMediaSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "socialMedia",
                      "platform",
                      this.props.clientSocialMediaFilter.platform
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Platform{" "}
                      <small>
                        {this.props.clientSocialMediaFilter.platform}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "socialMedia",
                      "heading",
                      this.props.clientSocialMediaFilter.heading
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Heading{" "}
                      <small>
                        {this.props.clientSocialMediaFilter.heading}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "socialMedia",
                      "link",
                      this.props.clientSocialMediaFilter.link
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Link{" "}
                      <small>{this.props.clientSocialMediaFilter.link}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "date" },
                      "socialMedia",
                      "reportingDate",
                      this.props.clientSocialMediaFilter.reportingDate
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Reporting Date{" "}
                      <small>
                        {castToDefaultDateFormat(
                          this.props.clientSocialMediaFilter.reportingDate
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "socialMedia",
                      "priority",
                      this.props.clientSocialMediaFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>
                        {this.props.clientSocialMediaFilter.priority}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "select", options: fetchInfrigementAllStatus() },
                      "socialMedia",
                      "status",
                      this.props.clientSocialMediaFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientSocialMediaFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.eCommerce
                  ? "#ecommerceSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#ecommerceSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.eCommerce
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.eCommerce
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/ecommerce-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.eCommerce
                    ? "/ecommerce-dashboard"
                    : "#"
                }
              >
                <span>ECOMMERCE</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="ecommerceSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "ecommerce",
                      "site",
                      this.props.clientEcommerceFilter.site
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Site{" "}
                      <small>{this.props.clientEcommerceFilter.site}</small>
                    </a>
                  </OverlayTrigger>
                </li>

                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "ecommerce",
                      "priority",
                      this.props.clientEcommerceFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>{this.props.clientEcommerceFilter.priority}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementEcommerceStatus(),
                      },
                      "ecommerce",
                      "status",
                      this.props.clientEcommerceFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientEcommerceFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.appStore
                  ? "#appStoreSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#appStoreSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.appStore
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.appStore
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/app-store-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.appStore
                    ? "/app-store-dashboard"
                    : "#"
                }
              >
                <span>APP STORE</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="appStoreSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "appStore",
                      "appName",
                      this.props.clientAppStoreFilter.appName
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      App Name{" "}
                      <small>{this.props.clientAppStoreFilter.appName}</small>
                    </a>
                  </OverlayTrigger>
                </li>

                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "appStore",
                      "priority",
                      this.props.clientAppStoreFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>{this.props.clientAppStoreFilter.priority}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "select", options: fetchInfrigementAllStatus() },
                      "appStore",
                      "status",
                      this.props.clientAppStoreFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientAppStoreFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                data-toggle="collapse"
                data-target={`${this.props.selectedBrand?.platforms?.eCommerce
                  ? "#trapPurchaseSubmenu"
                  : ""
                  }`}
                aria-expanded="false"
                href="#trapPurchaseSubmenu"
                style={
                  this.props.selectedBrand?.platforms?.eCommerce
                    ? {}
                    : { color: "#a1a1a1" }
                }
                className={`nav-link ${this.props.selectedBrand?.platforms?.eCommerce
                  ? "dropdown-toggle"
                  : ""
                  } ${window.location.pathname === "/trap-purchase-dashboard"
                    ? "active"
                    : ""
                  }`}
                to={
                  this.props.selectedBrand?.platforms?.eCommerce
                    ? "/trap-purchase-dashboard"
                    : "#"
                }
              >
                <span>TRAP PURCHASE</span>
              </Link>
              <ul
                className="collapse list-unstyled dropdown-menu"
                id="trapPurchaseSubmenu"
              >
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      { type: "input" },
                      "trapPurchase",
                      "site",
                      this.props.clientTrapPurchaseFilter.site
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Site{" "}
                      <small>{this.props.clientTrapPurchaseFilter.site}</small>
                    </a>
                  </OverlayTrigger>
                </li>

                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementAllPriority(),
                      },
                      "trapPurchase",
                      "priority",
                      this.props.clientTrapPurchaseFilter.priority
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      Priority{" "}
                      <small>{this.props.clientTrapPurchaseFilter.priority}</small>
                    </a>
                  </OverlayTrigger>
                </li>
                <li className="dropdown-item">
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="right"
                    overlay={renderFilterPopover(
                      {
                        type: "select",
                        options: fetchInfrigementEcommerceStatus(),
                      },
                      "trapPurchase",
                      "status",
                      this.props.clientTrapPurchaseFilter.status
                    )}
                  >
                    <a className="capitalize-text" href="#">
                      status{" "}
                      <small>
                        {getInfrigementStatusText(
                          this.props.clientTrapPurchaseFilter.status
                        )}
                      </small>
                    </a>
                  </OverlayTrigger>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  clientWebsiteFilter: state.clientWebsiteFilter,
  clientDomainFilter: state.clientDomainFilter,
  clientSocialMediaFilter: state.clientSocialMediaFilter,
  clientEcommerceFilter: state.clientEcommerceFilter,
  clientAppStoreFilter: state.clientAppStoreFilter,
  clientTmchFilter: state.clientTmchFilter,
  clientTrapPurchaseFilter: state.clientTrapPurchaseFilter,
  selectedBrand: state.selectedBrand,
  selectedCompany: state.selectedCompany,
});

export default connect(mapStateToProps)(SideNav);
