import React, {Component} from 'react'
import storage from 'local-storage-fallback';
import Footer from '../../common/Footer.js'
import AnalystSideNav from '../../common/AnalystSideNav.js'
import TopNav from '../../common/TopNav.js'

export default class CreateDomainRecord extends Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
        const token = storage.getItem('token')

        if(!token){
            return this.props.history.push('/login');
        }
    }

    render(){
        return <div className="row">
        
        <AnalystSideNav />
        
        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          
          <TopNav />
          
          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                            <span className="text-uppercase page-subtitle">Create Domain Record</span>
                        </div>
                    </div> 
                </div>
            </div>
                        

          </div>    
          <Footer />
        </main>
      </div>
    }
}