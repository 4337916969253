import React, { Component } from "react";
import Footer from "../../common/Footer.js";
import ClientSideNav from "../../common/ClientSideNav";
import TopNav from "../../common/TopNav.js";
import * as moment from "moment";
import debounce from "lodash.debounce";
import download from "downloadjs";
import { Table } from "react-bootstrap";
import requireAuth from "../../hoc/requireAuth";
import Swal from "sweetalert2/dist/sweetalert2.js";
import authorizeClient from "../../hoc/authorizeClient";
import NotAccessablePage from "../NotAccessablePage.js";
import { getNewSortOrder } from "../../helpers.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import Loader from "react-loader-spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import "bootstrap-daterangepicker/daterangepicker.css";
import { castToDefaultDateFormat, castToServerDateFormat } from "../../settings/index";
import { changeClientDateFilter } from "../../store/actions/filterActions";
import { withAlert } from "react-alert";
import InfrigementStatusDropdownComponent from "./../../components/InfrigementStatusDropdownComponent";
import "./trapPurchaseDashboard.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class TrapPurchaseDashboard extends Component {
  constructor(props) {
    super(props);
    this.getTrapPurchaseData = debounce(this.getTrapPurchaseData.bind(this), 1000);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      sorting: {
        sortBy: "",
        sortIconClass: "sort-icon-nutral",
        sortOrder: "",
      },
      authorised: true,
      domainUniqueSites: [],
      visibleRecords: "",
      dropdownCount: false,
      domains: [],
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      hasMoreDomainData: true,
      isLoading: false,
      locale: { format: "DD/MM/YYYY" },
    };

    window.onscroll = debounce(async () => {
      const {
        getTrapPurchaseData,
        state: { hasMoreDomainData, dropdownCount },
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      // Calculate the scroll position
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;

      // Check if scrolled to the bottom
      if (scrollPosition === document.documentElement.offsetHeight) {
        if (dropdownCount) {
          // Set dropdownCount to false to fetch all data
          this.setState({ dropdownCount: false });
        } else {
          // Paginate the data
          await getTrapPurchaseData();
        }
      }
    }, 100);
  }

  changeSorting(sortBy) {
    this.setState((prevState) => {
      const { sortOrder, classPostFix } = getNewSortOrder(prevState);

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder,
        },
      };
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sorting.sortBy !== this.state.sorting.sortBy ||
      prevState.sorting.sortOrder !== this.state.sorting.sortOrder ||
      prevProps.selectedBrand !== this.props.selectedBrand ||
      prevProps.clientTrapPurchaseFilter !== this.props.clientTrapPurchaseFilter ||
      prevProps.clientDateFilter !== this.props.clientDateFilter ||
      prevProps.location?.state?.notification !==
      this.props.location?.state?.notification
    ) {
      this.getTrapPurchaseData(true);
    }

    if (prevState.domains.length !== this.state.domains.length) {
      this.setState({ visibleRecords: this.state.domains.length }, () => { });
    }
  }

  async downloadExcel() {
    if (this.state.pagination.totalDocs > 25000) {
      Swal.fire(
        "More than 25,000 records cannot be downloaded. Please contact administrator.",
        "",
        "warning"
      );
    } else {
      const options = {
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-disposition": 'attachment; filename=ecommerce.xlsx"',
        },
      };

      let reqData = {
        startDate: castToServerDateFormat(
          this.props.clientDateFilter.startDate
        ),
        endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
        ...this.props?.clientTrapPurchaseFilter,
      };

      if (this.props.selectedBrand?.id) {
        reqData.brand = this.props.selectedBrand?.id;
      }

      if (this.props.selectedCompany?.id) {
        reqData.clientCompanyId = this.props.selectedCompany?.id;
      }

      await window.axios
        .get(
          "/ecommerce/download-xls",
          {
            params: reqData,
            responseType: "blob",
            timeout: 100000,
          },
          options
        )
        .then((res) => {
          download(res.data, "ecommerce.xls");
        });
    }
  }

  async getTrapPurchaseData(reArrangeRecords = false) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    let reqData = {
      dropdownCount: this.state.dropdownCount,
      sortBy: this.state.sorting.sortBy,
      sortOrder: this.state.sorting.sortOrder,
      page: reArrangeRecords ? 1 : this.state.pagination.nextPage, // Use nextPage for pagination
      perPage: this.state.perPage,
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      ...this.props.clientTrapPurchaseFilter,
    };

    if (this.props.location?.state?.notification) {
      reqData.notification = this.props.location?.state?.notification;
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id;
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }
    
    reqData.module = 'trap-purchase';
    this.setState({ isLoading: true });

    try {
      const res = await window.axios.get(
        "/trapPurchase/v2/getTrapPurchaseRecord",
        { params: reqData },
        options
      );

      let domainData = res.data.docs;

      if (reArrangeRecords) {
        this.setState({
          domains: domainData,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
          isLoading: false,
        });
        return;
      }

      if (reqData.dropdownCount) {
        domainData = res.data.docs; // Only update with the current response data
      } else if (this.state.domains.length > 0) {
        domainData = this.state.domains.concat(domainData); // Append new data to existing data
      }

      this.setState({
        pagination: {
          page: res.data.page,
          nextPage: res.data.nextPage,
          prevPage: res.data.prevPage,
          hasNextPage: res.data.hasNextPage,
          hasPrevPage: res.data.hasPrevPage,
          pagingCounter: res.data.pagingCounter,
          totalDocs: res.data.totalDocs,
          totalPages: res.data.totalPages,
        },
        domains: domainData,
        hasMoreDomainData: res.data.hasNextPage,
        isLoading: false,
      });
      this.setState({ domainUniqueSites: res.data.domainUniqueSites });
    } catch (error) {
      // Handle error
      this.setState({ isLoading: false });
      console.error("Error fetching ecommerce data:", error);
    }
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: this.state.locale,
    };
  }

  handleCallback = async (event, picker) => {
    this.props.dispatch(
      changeClientDateFilter({
        startDate: picker.startDate,
        endDate: picker.endDate,
      })
    );
  };

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000} //3 secs
          />
        </div>
      );
    }
  }

  componentDidMount() {
    this.getTrapPurchaseData();
    this.setState({ visibleRecords: this.state.domains.length }, () => { });
  }

  updateClientCommentInArray(value, key) {
    let records = this.state.domains;

    records[key]["clientComment"] = value;

    this.setState(() => ({
      domains: records,
    }));
  }

  async updateClientCommentToDatabase(key) {
    let clientComment = this.state.domains[key]["clientComment"];
    let infrigementId = this.state.domains[key]["id"];

    if (!clientComment) {
      return;
    }

    const options = {
      headers: { "Content-Type": "application/json" },
    };

    await window.axios
      .post(
        `/ecommerce/${infrigementId}/update-client-comment`,
        {
          clientComment,
          module :'trap-purchase-dashboard',
        },
        options
      )
      .then((res) => {
        this.props.alert.success("Comment upated successfully.");
      })
      .catch(function (error) {
        this.props.alert.error(error.response.data.message);
      });
  }

  handleSelect(countValue) {
    this.setState({ dropdownCount: countValue });
    this.getTrapPurchaseData();
  }

  printPaginationInfo() {
    let totalDocs = this.state.pagination.totalDocs
      ? this.state.pagination.totalDocs
      : 0;
    let visibleRecords = 0;
    console.log("totalDocs  :  ", totalDocs);
    console.log("page  :  ", this.state.pagination.page);
    console.log("perPage  :  ", this.state.perPage);

    if (totalDocs) {
      visibleRecords =
        totalDocs > this.state.perPage * this.state.pagination.page
          ? this.state.perPage * this.state.pagination.page
          : totalDocs;
    }

    return (
      <>
        <div className="d-flex align-items-center">
          <span className="text-uppercase page-subtitle">
            Showing{" "}
            <strong>
              {/* {this.state.dropdownRecordsPlug
                ? this.state.dropdownRecords.length
                : visibleRecords} */}
              {this.state.visibleRecords}
            </strong>{" "}
            records of <strong>{totalDocs}</strong>
          </span>
          <div className="dropdown ml-3">
            <button
              className="btn btn-secondary dropdown-toggle custom-dropdown-button"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                backgroundColor: "white",
                width: "130px",
                color: "black", // Add the color property for text visibility
                height: "20px", // Adjust the height as per your requirements
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1",
              }}
            >
              {/* {this.state.dropdownRecordsPlug
                ? this.state.dropdownRecords.length
                : visibleRecords} */}
              {this.state.visibleRecords} Records
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(10)}
              >
                Show 10 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(20)}
              >
                Show 20 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(50)}
              >
                Show 50 records
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderHtml() {
    if (!this.props.selectedBrand?.platforms?.eCommerce) {
      return <NotAccessablePage />;
    }

    return (
      <div className="row">
        <ClientSideNav domainUniqueSites={this.state.domainUniqueSites} />

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-5 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Trap Purchase Dashboard
                    </span>
                    {this.printPaginationInfo()}
                  </div>
                  <div className="col-2 text-sm-right mb-0">
                    {this.renderLoader()}
                  </div>
                  <div className="col-4 text-sm-right mb-0">
                    <span>
                      <strong>Reporting Date: </strong>
                    </span>
                    <DateRangePicker
                      onApply={this.handleCallback}
                      initialSettings={this.getDateFilters()}
                      alwaysShowCalendars={true}
                    >
                      <input className="from-control" />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>

            <div id="scrollableDiv" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col">
                  {/* table here */}
                  <Table className="table-responsive">
                    <thead className="uppercase-text">
                      <tr>
                        <th scope="col" width="3%" className="border-0">
                          {" "}
                          #
                        </th>
                        <th
                          width="3%"
                          scope="col"
                          className="border-0 text-uppercase"
                        >
                          Image
                        </th>
                        <th
                          width="10%"
                          scope="col"
                          onClick={(e) => this.changeSorting("title")}
                          className={`border-0 text-uppercase ${this.state.sorting.sortBy === "title"
                            ? this.state.sorting.sortIconClass + " sort-icon"
                            : "sort-icon sort-icon-nutral"
                            }`}
                        >
                          Title
                        </th>
                        <th
                          width="10%"
                          scope="col"
                          onClick={(e) => this.changeSorting("site")}
                          className={`border-0 text-uppercase ${this.state.sorting.sortBy === "site"
                            ? this.state.sorting.sortIconClass + " sort-icon"
                            : "sort-icon sort-icon-nutral"
                            }`}
                        >
                          Site
                        </th>
                        <th
                          width="10%"
                          scope="col"
                          onClick={(e) => this.changeSorting("reportingDate")}
                          className={`border-0 text-uppercase ${this.state.sorting.sortBy === "reportingDate"
                            ? this.state.sorting.sortIconClass + " sort-icon"
                            : "sort-icon sort-icon-nutral"
                            }`}
                        >
                          Reporting Date
                        </th>
                        <th
                          width="10%"
                          scope="col"
                          onClick={(e) => this.changeSorting("priority")}
                          className={`border-0 text-uppercase ${this.state.sorting.sortBy === "priority"
                            ? this.state.sorting.sortIconClass + " sort-icon"
                            : "sort-icon sort-icon-nutral"
                            }`}
                        >
                          Priority
                        </th>
                        <th
                          width="10%"
                          scope="col"
                          onClick={(e) => this.changeSorting("status")}
                          className={`border-0 text-uppercase ${this.state.sorting.sortBy === "status"
                            ? this.state.sorting.sortIconClass + " sort-icon"
                            : "sort-icon sort-icon-nutral"
                            }`}
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.domains.length > 0 && this.state.domains.map((domain, key) => {
                        // Your code logic for each domain
                        return (
                          <>
                            <tr
                              key={key}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + key}
                              aria-controls={"multiCollapseExample" + key}
                            >
                              <td>{key + 1}</td>
                              <td>
                                <img
                                  className="user-avatar"
                                  width="50"
                                  src={domain.imageUrl}
                                  alt={domain.title}
                                />
                              </td>
                              <td>
                                {" "}
                                <a
                                  href={domain.url}
                                  target="_blank"
                                  title={domain.title}
                                  className="bind-width"
                                >
                                  <p
                                    title={domain.title}
                                    className="bind-width"
                                  >
                                    {domain.title}
                                  </p>
                                </a>
                              </td>
                              <td>
                                <p className="bind-width" title={domain.site}>
                                  {domain.site}
                                </p>{" "}
                              </td>
                              <td>
                                {" "}
                                <p
                                  title={castToDefaultDateFormat(
                                    domain.reportingDate
                                  )}
                                  className="bind-width"
                                >
                                  {castToDefaultDateFormat(
                                    domain.reportingDate
                                  )}
                                </p>
                              </td>
                              <td>{domain.priority}</td>
                              <td>
                                <InfrigementStatusDropdownComponent
                                  selectedInfrigemnt={domain}
                                  selectedInfrigemntIndex={key}
                                  infrigementType="e-commerce"
                                />
                              </td>
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + key}
                              id={"multiCollapseExample" + key}
                            >
                              <td colSpan={10}>
                                <div className="row ">
                                  <div
                                    className="col-md-3 "
                                    style={{ height: "50px" }}
                                  >
                                    <img
                                      width="200"
                                      src={domain.purchaseId?.imageUrls[0]}
                                      className="thumbnail"
                                      data-toggle="modal"
                                      data-target={`#imageModal${key}`}
                                      id="mainImage"
                                      alt="Image"
                                    />
                                    {/* Modal */}
                                    <div className="input-box">
                                      <span className="text-muted d-block mb-2">
                                        LDotR Note
                                      </span>
                                      <textarea
                                        value={domain.purchaseId?.ldotrNote || ''}
                                        className="input-content2"
                                      ></textarea>
                                      <br />
                                      <br />
                                    </div>
                                  </div>

                                  <div className="col-md-9">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Seller Name
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.sellerName || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.sellerName || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Seller Name"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span
                                            className="text-muted d-block mb-2"
                                            data-toggle="tooltip"
                                            data-html="true"
                                            title={domain.site}
                                          >
                                            Seller Address
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.sellerAddress || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.sellerAddress || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Seller Address"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Seller City
                                          </span>{" "}
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.sellerCity || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.sellerCity || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Seller City"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Seller State
                                          </span>{" "}
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.sellerState || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.sellerState || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Seller State"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Seller Country
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.sellerCountry || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.sellerCountry || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Seller Country"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Price Paid
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.pricePaid || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.pricePaid || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Price Paid"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Pan
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.pan || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.pan || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Pan"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Invoice Number
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.invoice || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.invoice || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Invoice"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            Invoice File
                                          </span>

                                          <a
                                            href={domain.purchaseId?.invoiceFile}  // Replace with the actual URL of the file
                                          >
                                            Download
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            GST Number
                                          </span>
                                          <OverlayTrigger
                                            placement="left"
                                            overlay={
                                              <Tooltip id="tooltip">
                                                {domain.purchaseId?.gstNumber || ''}
                                              </Tooltip>
                                            }
                                          >
                                            <input
                                              type="text"
                                              value={domain.purchaseId?.gstNumber || ''}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="GST Number"
                                              aria-describedby="basic-addon1"
                                            />
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <span className="text-muted d-block mb-2">
                                            GST File
                                          </span>

                                          <a
                                            href={domain.purchaseId?.gstFile}  // Replace with the actual URL of the file
                                          >
                                            Download
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    
                                     {/* ----------- COMMENTS SECTION Start----------------- */}
                                        <div className="row">
                                          <div className="col-md-12">
                                            <span className="text-muted d-block mb-2">
                                              Comment
                                            </span>
                                            <div className="form-group d-flex align-items-center">
                                              <button
                                                style={{ marginRight: "10px" }}
                                                onClick={(e) => 
                                                  this.updateClientCommentToDatabase(
                                                    key
                                                  )
                                                }
                                                className="btn btn-success"
                                              >
                                                Update comment
                                              </button>

                                              <div className="flex-grow-1">
                                                <textarea
                                                  onChange={(e) =>
                                                    this.updateClientCommentInArray(
                                                      e.target.value,
                                                      key
                                                    )
                                                  }
                                                  className="form-control"
                                                  value={domain?.clientComment || ""}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* ----------- COMMENTS SECTION END----------------- */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <div className="modal" id={`imageModal${key}`} tabIndex="-1" role="dialog" aria-labelledby={`imageModalLabel${key}`} aria-hidden="true">
                              <div className="modal-dialog modal-800" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h4 className="modal-title">Image</h4>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div id={`imageCarousel${key}`} className="carousel slide" data-ride="carousel">
                                      <div className="carousel-inner">
                                        {domain.purchaseId?.imageUrls.map((image, index) => (
                                          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                            <img src={image} className="d-block mx-auto my-auto w-200 larger-image-trap" alt={`Image ${index + 1}`} />
                                            <a href={image} download className="download-link">
                                              Download
                                            </a>
                                          </div>
                                        ))}
                                      </div>
                                      <a className="carousel-control-prev" href={`#imageCarousel${key}`} role="button" data-slide="prev">
                                        <span className="carousel-control-prev-icon bg-dark rounded-circle" aria-hidden="true"></span>
                                        <span className="sr-only">Previous</span>
                                      </a>
                                      <a className="carousel-control-next" href={`#imageCarousel${key}`} role="button" data-slide="next">
                                        <span className="carousel-control-next-icon bg-dark rounded-circle" aria-hidden="true"></span>
                                        <span className="sr-only">Next</span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  clientTrapPurchaseFilter: state.clientTrapPurchaseFilter,
  clientDateFilter: state.clientDateFilter,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeClient(requireAuth(TrapPurchaseDashboard)))
);
