import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";
import {
  colorMap,
  toCapitalCase,
  statusTextColorMap,
} from "../../../common/utils";
import "../charts.css";
import React, { useMemo } from "react";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "white",
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p className="label" style={{ fontWeight: "bold" }}>{`${label}`}</p>
        {Object.keys(data).map((el, index) => {
          if (el === "label" || el === "total" || el === "percentages")
            return null;

          if (data[el] <= 0) {
            return null;
          }

          return (
            <p
              className="desc text-left"
              style={{ margin: 0, marginBottom: "2px", width: "100%" }}
              key={el}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "4px",
                  height: "15px",
                  backgroundColor: colorMap[index % colorMap.length],
                  marginRight: "5px",
                }}
              ></span>
              {el}: {data[el]}
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

const BarChartGeneric = ({
  title,
  data,
  modal = false,
  openModel = null,
  xLabel,
  yLabel,
  showPercentage = true,
}) => {
  const processedData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((entry) => {
      const numericKeys = Object.keys(entry).filter(
        (key) => key !== "label" && typeof entry[key] === "number"
      );

      let percentages;

      if (showPercentage) {
        const total = numericKeys.reduce((sum, key) => sum + entry[key], 0);
        percentages = numericKeys.reduce((acc, key) => {
          acc[key] = total > 0 ? ((entry[key] / total) * 100).toFixed(0) : "0";
          return acc;
        }, {});
      }

      return {
        ...entry,
        percentages,
      };
    });
  }, [data, showPercentage]);

  const numericKeys = useMemo(() => {
    if (processedData.length === 0) return [];
    return Object.keys(processedData[0]).filter(
      (key) => key !== "label" && key !== "percentages"
    );
  }, [processedData]);

  const BarElements = numericKeys.map((key, index) => (
    <Bar
      key={key}
      dataKey={key}
      stackId="all"
      radius={[2, 2, 0, 0]}
      fill={colorMap[index % colorMap.length]}
    >
      <LabelList
        dataKey={key}
        position="center"
        content={({ x, y, width, height, value, index }) => {
          const percentage = processedData[index]?.percentages?.[key] ?? "0";

          return value > 0 ? (
            <text
              x={x + width / 2}
              y={y + height / 2}
              fill={statusTextColorMap[key]}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={12}
            >
              {percentage === "0" || percentage < 9 ? "" : `${percentage}%`}
            </text>
          ) : null;
        }}
      />
    </Bar>
  ));

  return (
    <div
      className="position-relative"
      style={{
        padding: `${modal ? 0 : "16px"}`,
        width: "100%",
        height: modal ? "480px" : "410px",
      }}
    >
      {title && (
        <div className="text-center mb-2 position-relative">
          <span className="uppercase-text">
            <strong>{title}</strong>
          </span>

          <button
            className="position-absolute btn btn-outline-light btn-sm"
            style={{ top: -8, right: 0 }}
            onClick={(e) => {
              e.preventDefault();
              openModel && openModel();
            }}
          >
            <img
              src="/icons/popup.svg"
              alt="bar-popup-icon"
              width={22}
              height={22}
            />
          </button>
        </div>
      )}

      {/* chart element start here  */}
      <ResponsiveContainer width="100%" height="100%">
        {processedData.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%", paddingTop: "50px" }}
          >
            <p className="text-center text-decoration-underline">
              No data available
            </p>
          </div>
        ) : (
          <BarChart
            data={processedData}
            margin={{
              top: 18,
              bottom: 28,
            }}
            barGap={1}
            maxBarSize={60}
          >
            <XAxis
              dataKey="label"
              tickFormatter={(value) => toCapitalCase(value)}
              style={{
                fontSize: processedData.length >= 12 ? "12px" : "14px",
              }}
            >
              <Label
                value={xLabel ? xLabel : ""}
                offset={-20}
                position="insideBottom"
                style={{ fontSize: "16px" }}
              />
            </XAxis>

            <YAxis>
              <Label
                value={yLabel ? yLabel : "Record Counts"}
                angle={-90}
                offset={8}
                position="insideLeft"
                style={{ textAnchor: "middle", fontSize: "16px" }}
              />
            </YAxis>

            <Tooltip content={<CustomTooltip />} />
            <Legend
              wrapperStyle={{
                top: 0,
                maxWidth: "100%",
                whiteSpace: "normal",
                textAlign: "center",
              }}
              iconSize={10}
              align="center"
              iconType="circle"
              verticalAlign="top"
              formatter={(value) => {
                if (value.includes(".")) {
                  return value.split(".")[1];
                } else if (value.includes("_")) {
                  return value.replace("_", " ");
                } else {
                  return value;
                }
              }}
            />

            {BarElements}
          </BarChart>
        )}
      </ResponsiveContainer>

      {modal && (
        <div
          className="d-inline-flex position-absolute"
          style={{ bottom: "-15px", right: "17px" }}
        >
          <button
            className="btn btn-outline-light btn-sm mr-3"
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            <img
              src="/icons/print.svg"
              alt="bar-print-icon"
              width={25}
              height={25}
            />
          </button>

          {/* <button
            className="btn btn-outline-light btn-sm"
            onClick={(e) => {
              e.preventDefault();
            }}
            disabled
          >
            <img
              src="/icons/download.svg"
              alt="bar-download-icon"
              width={25}
              height={25}
            />
          </button> */}
        </div>
      )}
    </div>
  );
};

export default BarChartGeneric;
