export const keyToReasonCode = {
    "000": "NotApplicable",
    "123": "TM",
    "567": "MRP",
    "234": "Fake",
    "891": "Compliance",
    "912": "Design",
    "789": "Import",
    "678": "Extensions",
    "345": "Combo",
    "456": "Tradedress",
    "125": "AuthorDiff",
    "127": "AuthorIncorrect",
    "129": "AuthorMissing",
    "131": "BookCoverDiff",
    "133": "Combo",
    "135": "Counterfeit",
    "137": "DiscountHigh",
    "139": "EditionWrong",
    "141": "Error",
    "143": "ISBNIncorrect",
    "145": "ISBNMissing",
    "147": "LanguageDiff",
    "149": "ManPackDiff",
    "151": "ManPackIncorrect",
    "153": "ManPackMissing",
    "155": "MonthDiff",
    "157": "MRPHigh",
    "159": "NotAnInfgmnt",
    "161": "PagesDiff",
    "163": "PriceDiffMoreThan20%",
    "165": "PublisherDiff",
    "167": "PublisherMissing",
    "169": "SpecificationDiff",
    "171": "TitleIncorrect",
    "173": "YearDiff",
    "175": "YearMissing",
    "177": "BindingDiff",
    "179": "MRPLow",
    "180": "Expired",
    "181": "D2C",
    "182": "Lookalike",
    "183": "Mismatch",
    // New Reason code starts //
    "12301": "TM_Title",
    "12302": "TM_Description",
    "12303": "TM_StyleCode",
    "12304": "TM_ModelNumber",
    "12305": "TM_BrandColor",
    "12306": "TM_SalesPackage",
    "12307": "TM_Title_WrongSpelling",
    "12308": "TM_Description_WrongSpelling",
    "12309": "TM_StyleCode_WrongSpelling",
    "12310": "TM_ModelNumber_WrongSpelling",
    "12311": "TM_SalesPackage_WrongSpelling",
    "12312": "TM_LogoButMissingBrandName",
    "12313": "TM_Misspelled_GenuinePackaging",
    "12314": "TM_LogoButMissingBrandInTitle	",
    "18201": "Lookalike_MisspelledBrand",
    "18202": "Lookalike_EditedBrand",
    "18203": "Lookalike_RemovedBrand",
    "18204": "Lookalike_SimilarPackaging",
    "18101": "D2C_NotAllowed",
    "56701": "MRP_Discount80",
    "56702": "MRP_Higher30",
    "56703": "MRP_Lower30",
    "23401": "Fake_TM_MRP",
    "23402": "Fake_ImageEdited",
    "91201": "Design_SimilarDesign",
    "78901": "Import_Language",
    "78902": "Import_ImportWord",
    "78903": "Import_NotOnIndianPlatform",
    "78904": "Import_NoImportLicensee",
    "67801": "RangeExtension_DiffTMClass",
    "34501": "Combo_Branded",
    "18001": "Expired_PastDate",
    "18301": "Mismatch_BrandNotFirstWordinTitle",
    "18205": "Lookalike_CopyrightImageUse",
    "42301": "Unauth_Seller",
    // New Reason code ends //
}
export const reasonCodeToKey = {
    "NotApplicable": "000",
    "TM": "123",
    "MRP": "567",
    "Fake": "234",
    "Compliance": "891",
    "Design": "912",
    "Import": "789",
    "Extensions": "678",
    "Combo": "345",
    "Tradedress": "456",
    "AuthorDiff": "125",
    "AuthorIncorrect": "127",
    "AuthorMissing": "129",
    "BookCoverDiff": "131",
    "Combo": "133",
    "Counterfeit": "135",
    "DiscountHigh": "137",
    "EditionWrong": "139",
    "Error": "141",
    "ISBNIncorrect": "143",
    "ISBNMissing": "145",
    "LanguageDiff": "147",
    "ManPackDiff": "149",
    "ManPackIncorrect": "151",
    "ManPackMissing": "153",
    "MonthDiff": "155",
    "MRPHigh": "157",
    "NotAnInfgmnt": "159",
    "PagesDiff": "161",
    "PriceDiffMoreThan20%": "163",
    "PublisherDiff": "165",
    "PublisherMissing": "167",
    "SpecificationDiff": "169",
    "TitleIncorrect": "171",
    "YearDiff": "173",
    "YearMissing": "175",
    "BindingDiff": "177",
    "MRPLow": "179",
    "Expired": "180",
    "D2C": "181",
    "Lookalike": "182",
    "Mismatch": "183",
    // New Reason code starts //
    "TM_Title": "12301",
    "TM_Description": "12302",
    "TM_StyleCode": "12303",
    "TM_ModelNumber": "12304",
    "TM_BrandColor": "12305",
    "TM_SalesPackage": "12306",
    "TM_Title_WrongSpelling": "12307",
    "TM_Description_WrongSpelling": "12308",
    "TM_StyleCode_WrongSpelling": "12309",
    "TM_ModelNumber_WrongSpelling": "12310",
    "TM_SalesPackage_WrongSpelling": "12311",
    "TM_LogoButMissingBrandName": "12312",
    "TM_Misspelled_GenuinePackaging": "12313",
    "TM_LogoButMissingBrandInTitle	": "12314",
    "Lookalike_MisspelledBrand": "18201",
    "Lookalike_EditedBrand": "18202",
    "Lookalike_RemovedBrand": "18203",
    "Lookalike_SimilarPackaging": "18204",
    "D2C_NotAllowed": "18101",
    "MRP_Discount80": "56701",
    "MRP_Higher30": "56702",
    "MRP_Lower30": "56703",
    "Fake_TM_MRP": "23401",
    "Fake_ImageEdited": "23402",
    "Design_SimilarDesign": "91201",
    "Import_Language": "78901",
    "Import_ImportWord": "78902",
    "Import_NotOnIndianPlatform": "78903",
    "Import_NoImportLicensee": "78904",
    "RangeExtension_DiffTMClass": "67801",
    "Combo_Branded": "34501",
    "Expired_PastDate": "18001",
    "Mismatch_BrandNotFirstWordinTitle": "18301",
    "Lookalike_CopyrightImageUse":"18205",
    "Unauth_Seller":"42301",
    // New Reason code ends //
}

