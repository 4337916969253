import storage from 'local-storage-fallback'
import store from '../index';

export const changeBrand = (brand) => {
    return (dispatch) => {

        const storeData = store.getState();
        let brands;
        if(brand.companyId){
            brands = storeData?.login?.user?.brandDetails.filter((item) => {
                if(item.company === brand.companyId) return item;
            })
        }
        else if(storeData?.login?.user?.brandDetails){
            brands = storeData?.login?.user?.brandDetails.filter((item) => {
                if(item.id === brand.id) return brand;
            })
        }
        let platforms = {
            domain: false,
            website: false,
            socialMedia: false,
            eCommerce: false,
            appStore: false,
            tmch: false
        }

        if(brand.id === null){

            for(let brandData of brands){

                if(!brandData.platform || Object.getPrototypeOf(brandData.platform) != Object.prototype){
                    continue;
                }

                if('domain' in brandData.platform){
                    platforms.domain = true
                }
                if('website' in brandData.platform){
                    platforms.website = true
                }
                if('socialMedia' in brandData.platform){
                    platforms.socialMedia = true
                }
                if('eCommerce' in brandData.platform){
                    platforms.eCommerce = true
                }
                if('appStore' in brandData.platform){
                    platforms.appStore = true
                }
                if('tmch' in brandData.platform){
                    platforms.tmch = true
                }
            }
        }else{
            const brandData = brands.find(brandData => brandData.id === brand.id)

            if(brandData.platform && Object.getPrototypeOf(brandData.platform) === Object.prototype){
                if('domain' in brandData.platform){
                    platforms.domain = true
                }
                if('website' in brandData.platform){
                    platforms.website = true
                }
                if('socialMedia' in brandData.platform){
                    platforms.socialMedia = true
                }
                if('eCommerce' in brandData.platform){
                    platforms.eCommerce = true
                }
                if('appStore' in brandData.platform){
                    platforms.appStore = true
                }
                if('tmch' in brandData.platform){
                    platforms.tmch = true
                }
            }
        }

        brand.platforms = platforms

        storage.setItem('selectedBrand', JSON.stringify(brand))

        dispatch({
            type: 'CHANGE_BRAND_SELECTION',
            brand
        })
    }
}