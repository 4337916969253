import storage from 'local-storage-fallback';

export const changeCompany = (companyData) => {
    return (dispatch) => {
        storage.setItem('selectedCompany', JSON.stringify(companyData))

        dispatch({
            type: 'CHANGE_COMPANY_SELECTION',
            companyData
        })
    }
}