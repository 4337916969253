import React, { Component } from 'react';
import Footer from '../../common/Footer.js';
import AdminSideNav from '../../common/AdminSideNav.js';
import TopNav from '../../common/TopNav.js';
import { Button, Modal, Form, Table, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import requireAuth from '../../hoc/requireAuth';
import authorizeAdmin from '../../hoc/authorizeAdmin';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";
import 'sweetalert2/src/sweetalert2.scss';
import { castToServerDateFormat } from '../../settings/index';
import Loader from "react-loader-spinner";
import { withAlert } from 'react-alert';
import './../../itemsAlignment.css';
import PaginationComponentV1 from '../../common/PaginationComponentV1';
const FormData = require("form-data");

class WhiteLabel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fileContent: [],
            availableCategoryFilters: [],
            availableCompanies: [],
            filteredCategory: [],
            companyFilter: null,
            categoryFilter: null,
            statusFilter: null,
            filteredCompany: [],
            selectedBrand: null,
            status: true,
            showModal: false,
            showAddPlatformModal: false,
            authorised: true,
            brands: [],
            errorMessage: null,
            brandName: '',
            searchTerm: '',
            category: '',
            selectedCompany: null,
            companies: [],
            WhiteLabel: [],
            platform: {},
            pagination: {
                totalDocs: null,
                totalPages: null,
                page: 1,
                pagingCounter: 1,
                hasPrevPage: false,
                hasNextPage: true,
                prevPage: null,
                nextPage: 1
            },
            perPage: 20,
            sortBy: '_id',       // column to sort by
            sortDirection: -1, // sort direction (asc or desc)
            isLoading: false,
            hasMoreDomainData: true,
            search: '',
            selectedPlatform: '',
            selectedStartDate: '',
            selectedEndDate: '',
            selectedReportingPeriod: '',
            availablePlatforms: null,
            whitelabelId: null,
            isUpdate: 1,
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenAddPlatformModal = this.handleOpenAddPlatformModal.bind(this);
        this.handleCloseAddPlatformModal = this.handleCloseAddPlatformModal.bind(this);
        this.saveWhiteLabel = this.saveWhiteLabel.bind(this);
        this.listAllWhiteLabelRecords = this.listAllWhiteLabelRecords.bind(this)
        this.handleLimit = this.handleLimit.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.inputSearchBox = this.inputSearchBox.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
        this.myPageChange = this.myPageChange.bind(this);
    }

    myPageChange(newPage) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: newPage
            }
        });
    }

    renderSaveOrUpdateButton() {
        if (this.state.whitelabelId) {
            return <Button onClick={() => this.saveWhiteLabel(false)} variant="primary">Update</Button>
        } else {
            return <Button onClick={() => this.saveWhiteLabel(true)} variant="primary">Save</Button>
        }
    }

    handleSearchButton() {
        this.listAllWhiteLabelRecords(true);
    };

    inputSearchBox(search) {
        this.setState({ search });
    };

    sortTable = (column) => {
        let { sortBy, sortDirection } = this.state;

        if (sortBy === column) {
            sortDirection = sortDirection === 1 ? -1 : 1;
        } else {
            sortBy = column;
            sortDirection = 1;
        }

        // Update state
        this.setState({ sortBy, sortDirection });
    };

    handleLimit(e) {
        this.setState({ perPage: e })
        this.setState(prevState => ({
            pagination: {
                ...prevState.pagination,
                page: 1
            }
        }));

    }

    renderLoader() {
        if (this.state.isLoading) {
            return <div className="text-center">
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={200}
                    timeout={5000} //3 secs
                />
            </div>
        }
    }

    async saveWhiteLabel(update) {
        let reqParam = {
            company: this.state.selectedCompany?.value,
            brand: this.state.selectedBrand?.value,
            platform: this.state.platform,
            locationURL: this.state.excelData,
            status: this.state.status
        }

        let endPoint = '/white-label/save';
        if (this.state.whitelabelId) {
            reqParam = {};
            reqParam.whitelabelId = this.state.whitelabelId;
            reqParam.whitelabelUrl = this.state.fileContent;
            reqParam.status = this.state.status;
            endPoint = `/white-label/edit`;
        }

        await window.axios.post(`${endPoint}`, reqParam)
            .then((response) => {
                this.props.alert.show(response.data.message);
                this.handleCloseModal();
                this.listAllWhiteLabelRecords(update);

            })
            .catch((error) => {
                let statusCode = error.response.status
                let errors = error.response.data.errors
                let errorMessage = error.response.data.message

                if (statusCode === 401 || statusCode === 400 || statusCode === 500) {
                    this.props.alert.show(`Oops ${errorMessage}`, { type: 'error' })
                    return
                }

                if (statusCode === 422) {
                    let errorMessageObj = {}

                    for (let errorData of errors) {
                        let errorKeyName = Object.keys(errorData)[0]
                        errorMessageObj[errorKeyName] = errorData[errorKeyName]
                    }

                    this.setState(() => {
                        return { errorMessage: errorMessageObj }
                    })

                    this.props.alert.show(`Oops ${errorMessage}`, { type: 'error' })

                    return
                }
                this.props.alert.show(error.response.data.message, { type: 'error' })
            })

    }

    renderPlatforms() {
        return <>
            <div style={{ marginTop: 10 }} className="row">
                <div style={{ fontWeight: 500 }} className="col-2">Platform</div>
                <div style={{ fontWeight: 500 }} className="col-2">Start Date</div>
                <div style={{ fontWeight: 500 }} className="col-2">End Date</div>
                <div style={{ fontWeight: 500 }} className="col-2">Reporting Period</div>
            </div>
            {this.state.platform ? Object.keys(this.state.platform).map((key) =>
                <>
                    <div style={{ marginTop: 10 }} className="row">
                        <div className="col-2">
                            <p>{key}</p>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].startDate}</label>
                            </Form.Group>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].endDate}</label>
                            </Form.Group>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].reportingPeriod}</label>
                            </Form.Group>
                        </div>
                    </div>
                </>
            ) : ''}
        </>
    }

    handleOpenModal() {
        this.setState((prevState) => {
            return {
                showModal: true
            }
        });
        this.listAllCompanies();
    }

    async handleCloseAddPlatformModal() {
        this.setState(() => ({ showAddPlatformModal: false }))
    }

    async handleOpenAddPlatformModal() {
        this.setState(() => ({ showAddPlatformModal: true }))
    }

    async removeWhiteLabel(whitelabel) {
        await window.axios.delete(`/white-label/delete/${whitelabel?._id}`)
            .then((response) => {
                this.listAllWhiteLabelRecords(true)
                this.props.alert.show(response.data.message)
            })
            .catch((error) => {
                this.props.alert.show(error.response.data, { type: 'error' })
            })
    }

    async showEditPopup(whitelabel) {
        await this.setState((prevState) => {
            return {
                whitelabelId: whitelabel._id,
                status: whitelabel.status ? true : false,
                showModal: true,
                platform: whitelabel.platform ? whitelabel.platform : {},
                selectedBrand: whitelabel.brand ? { label: whitelabel.brand.brandName, value: whitelabel.brand._id } : null,
                selectedCompany: whitelabel.company ? { label: whitelabel.company.name, value: whitelabel.company._id } : null,
                fileContent: whitelabel.whitelabelUrl ? whitelabel.whitelabelUrl : '',
                isUpdate: 0
            }
        }
        )
    }

    handleCloseModal() {
        this.setState((prevState) => {
            return {
                selectedBrand: null,
                selectedCompany: null,
                showModal: false,
                platform: {},
                status: false,
                fileContent: [],
                whitelabelId: null,
                isUpdate: 0,
            }
        });
    }

    async listAllWhiteLabelRecords(reArrangeRecords = false) {
        let reqData = {
            page: this.state.pagination.page,
            perPage: this.state.perPage,
        }

        reqData.sortBy = this.state.sortBy;
        reqData.sortDirection = this.state.sortDirection;
        if (reArrangeRecords) {
            reqData.page = 1
        }
        reqData.search = this.state.search;

        this.setState(() => ({ isLoading: true }));

        await window.axios.get('/white-label/getRecords', {
            params: reqData
        }).then(res => {
            this.setState(() => ({
                WhiteLabel: res.data.results,
                isLoading: false,
                pagination: {
                    page: res.data.pagination.page,
                    nextPage: res.data.pagination.nextPage,
                    prevPage: res.data.pagination.prevPage,
                    hasNextPage: res.data.pagination.hasNextPage,
                    hasPrevPage: res.data.pagination.hasPrevPage,
                    pagingCounter: res.data.pagination.pagingCounter,
                    totalDocs: res.data.pagination.totalDocs,
                    totalPages: res.data.pagination.totalPages,
                },
                hasMoreDomainData: res.data.pagination.hasNextPage
            }));
        });
    }

    async changeInputBox(e, column) {

        let state = {}
        state[column] = e.target.value
        await this.setState(() => {
            return state
        })
    }

    componentDidMount() {
        this.listAllWhiteLabelRecords()
        this.fetchAllUniqueCategory();
    }


    async getBrands() {
        await window.axios
            .get("/brand", {
                params: {
                    disabledBrands: true,
                    company: this.state.selectedCompany.value,
                    page: 1,
                    perPage: 800,
                },
            })
            .then((res) => {
                this.setState({
                    brands: res.data.docs,
                    isUpdate: 0
                });
            });
    }

    componentDidUpdate(prevProps, prevState) {
        // This runs when the component updates
        // You can check if specific state or props changed and perform side effects accordingly
        if (this.state.pagination.page !== prevState.pagination.page || this.state.perPage !== prevState.perPage || this.state.sortBy !== prevState.sortBy || this.state.sortDirection !== prevState.sortDirection) {
            this.listAllWhiteLabelRecords();
        }

        if (prevState.selectedCompany !== this.state.selectedCompany && this.state.isUpdate) {
            this.getBrands();
        }

        if (
            this.state.selectedBrand &&
            this.state.selectedBrand !== prevState.selectedBrand // Check if the brand has changed
        ) {
            let selectedBrand = this.state.brands.filter(
                (brand) => brand.id === this.state.selectedBrand.value
            );

            if (selectedBrand.length) {
                let platform = selectedBrand[0].platform;

                this.setState(() => ({
                    availablePlatforms: platform,
                }));
            } else {
                this.setState(() => ({
                    availablePlatforms: null,
                }));
            }
        }
    }

    async getCompanies() {
        await window.axios.get('/company', {
            params: {
                perPage: 100
            }
        }).then(res => {
            this.setState({
                availableCompanies: res.data.docs
            })
        });
    }

    async listAllCompanies() {

        await window.axios.get('/company', {
            params: {
                perPage: 100
            }
        }).then(res => {

            let companies = []

            res?.data?.docs.map((company) => (
                companies.push({
                    label: company.name,
                    value: company._id
                })
            ))

            this.setState({ companies: companies });
        });
    }

    showCompanySelectBox() {
        return <div className="col-6">
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Company *</Form.Label>
                <Select name="company" value={this.state.selectedCompany} onChange={(e) => this.changeCompany(e)} options={this.state.companies} isDisabled={this.state.whitelabelId} />
                <span className="form-text-error-text">{this.state.errorMessage?.company}</span>
            </Form.Group>
        </div>
    }

    showBrandSelectBox() {
        return <div className="col-6">
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Brand</Form.Label>
                <Select name="company" value={this.state.selectedBrand} onChange={(e) => this.changeBrand(e)} options={this.state.brands.map((brand, brandKey) => ({
                    value: brand.id,
                    label: brand.brandName,
                }))} isDisabled={this.state.whitelabelId}/>
                <span className="form-text-error-text">{this.state.errorMessage?.brand}</span>
            </Form.Group>
        </div>
    }

    async changeCompany(e) {
        await this.setState(() => {
            return {
                selectedCompany: e,
                isUpdate:1
            }
        })
    }

    handleSingleImageUpload = async (files) => {
        const input = document.getElementById("input");
        let file = input.files[0];

        if (file && file.type === "text/plain") {
            let formData = new FormData();
            formData.append("files", file);
            let fileContentArray;

            const reader = new FileReader();
            reader.onload = (e) => {
                const fileContent = e.target.result;
                fileContentArray = fileContent.split("\n");

                const options = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Access-Control-Allow-Origin": "true",
                    },
                };

                window.axios
                    .post("/upload-images-to-s3/white-label", formData, options)
                    .then((res) => {
                        let awsResponse = res.data.Data[0];
                        this.setState({ fileContent: fileContentArray });
                        this.setState({ excelData: awsResponse.Location });
                    })
                    .catch((error) => {
                        this.setState({ dataSaveError: { data: 'Error uploading file.' } })
                        setTimeout(() => {
                            this.setState({ dataSaveError: null })
                        }, 3000);
                        console.error("Error uploading file:", error);
                    });
            };

            reader.readAsText(file);
        } else {
            this.setState({ dataSaveError: { data: 'Please upload a valid .txt file.' } })
            setTimeout(() => {
                this.setState({ dataSaveError: null })
            }, 3000);
        }
    };


    async changeBrand(e) {
        await this.setState(() => {
            return {
                selectedBrand: e
            }
        })
    }

    async changePlatform(e) {
        await this.setState(() => ({ selectedPlatform: e }))
    }

    printPlatform(key = '', brand) {

        const platform = brand.platform ? brand.platform[key] : null;

        if (!platform) {
            return null;
        }

        return <div className="row">
            <div className="col-md-12">
                <h6 className="capitalize-text">{key}</h6>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">Start Date</span>
                    <input type="text" value={castToServerDateFormat(platform.startDate)} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">End Date</span>
                    <input type="text" value={castToServerDateFormat(platform.endDate)} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">Reporting Period</span>
                    <input type="text" value={platform.reportingPeriod} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
        </div>;
    }

    handleStatusChange = async (e) => {
        await this.setState(() => ({
            status: e.target.checked
        }))
    }

    renderWhiteLabel() {
        return this.state.WhiteLabel.map((whitelabel, whitelabelKey) =>
            <>
                <tr
                    key={whitelabelKey}
                    data-toggle="collapse"
                    data-target={'.multi-collapse' + whitelabelKey}
                    aria-controls={'multiCollapseExample' + whitelabelKey}
                >
                    <td><p className="mx-0 my-0">{this.state.pagination.pagingCounter + whitelabelKey}</p></td>
                    <td><p className="mx-0 my-0">{whitelabel.company.name}</p></td>
                    <td><p className="mx-0 my-0">{whitelabel.brand.brandName}</p></td>
                    <td><p className="mx-0 my-0">{whitelabel.whitelabelUrl}</p></td>
                    <td><p className="mx-0 my-0 truncate" >{whitelabel?.platform}</p></td>
                    <td><span className={whitelabel.status ? 'text-success' : 'text-danger'}>{whitelabel.status ? 'Enabled' : 'Disabled'}</span></td>
                    <td>
                        <a className="text-danger" onClick={(e) => this.removeWhiteLabel(whitelabel)} href="#"><i className="fa fa-trash"></i></a>
                        <a className="text-primary mx-1" onClick={(e) => this.showEditPopup(whitelabel)} href="#"><i className="fa fa-edit"></i></a>
                    </td>
                </tr>
            </>
        )
    }

    async handleCompanyFilterChange(e) {
        await this.setState(() => ({
            filteredCompany: e.target.value
        }));
    }


    async handleCategoryFilterChange(e) {
        await this.setState(() => ({
            categoryFilter: e.target.value
        }));
    }



    async handleStatusFilterChange(e) {
        await this.setState(() => ({
            statusFilter: e.target.value
        }));
    }

    async fetchAllUniqueCategory() {
        await window.axios.get('/brand/fetch-unique-categories').then(async (response) => {
            await this.setState(() => ({
                availableCategoryFilters: response.data
            }
            ))
        }).catch(() => {
            console.log('error')
        })
    }

    changePlatformSelectBox(e) {
        let selectedKeyValue = "domainKeys";

        let key = e.target.value;

        if (key === "website") {
            selectedKeyValue = "websiteKeys";
        }
        if (key === "social-media") {
            selectedKeyValue = "socialMediaKeys";
        }
        if (key === "e-commerce") {
            selectedKeyValue = "ecommerceKeys";
        }
        if (key === "app-store") {
            selectedKeyValue = "appStoreKeys";
        }

        this.setState({
            platform: e.target.value,
            excelData: [],
            selectedKey: selectedKeyValue,
        });
    }

    renderHtml() {
        return <div className="row">

            <AdminSideNav />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="d-flex align-items-center sticky-div-brand justify-content-between mb-2 mt-2">
                        <div className="d-flex gap-4 align-items-center">
                            <InputGroup className="">
                                <FormControl
                                    placeholder=""
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    value={this.state.search}
                                    onChange={(e) => { this.inputSearchBox(e.target.value) }}
                                    style={{ width: '200px' }}
                                />
                            </InputGroup>
                            <Button className='btn-success' onClick={this.handleSearchButton}>Search</Button>
                            <Button onClick={this.handleOpenModal}>Add White Label</Button>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <PaginationComponentV1
                                totalRecords={this.state.pagination.totalDocs}
                                itemsPerPage={this.state.perPage}
                                currentPage={this.state.pagination.page}
                                onPageChange={this.myPageChange}
                                displayPagesCount={8} />

                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <Dropdown style={{ width: '80px' }} onSelect={this.handleLimit}>
                                <Dropdown.Toggle variant="danger" id="limit">
                                    {this.state.perPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="10" active={this.state.perPage == 10}>10</Dropdown.Item>
                                    <Dropdown.Item eventKey="20" active={this.state.perPage == 20}>20</Dropdown.Item>
                                    <Dropdown.Item eventKey="50" active={this.state.perPage == 50}>50</Dropdown.Item>
                                    <Dropdown.Item eventKey="100" active={this.state.perPage == 100}>100</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <span>
                                {this.state.pagination.totalDocs} records
                            </span>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            <div className="card card-small mb-4">

                                <div className="card-body p-0">
                                    <Table width="100%" id="brand-table" className="table mb-0">
                                        <thead className="bg-light uppercase-th">
                                            <tr key="row_header">
                                                <th width="3%" scope="col" className="border-0" onClick={() => this.sortTable('_id')}>Sr. {this.state.sortBy === '_id' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="3%" scope="col" className="border-0" onClick={() => this.sortTable('company.name')}>Company {this.state.sortBy === '_id' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="3%" scope="col" className="border-0" onClick={() => this.sortTable('brand.brandname')}>Brand {this.state.sortBy === '_id' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('whitelabelUrl')}>Whitelabel URL {this.state.sortBy === 'brandName' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('platform')}>Platform {this.state.sortBy === 'searchTerm' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('status')}>Status {this.state.sortBy === 'status' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderWhiteLabel()}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    }

    render() {
        return <>
            {this.renderHtml()}
            {/* add new platform modal start */}
            <Modal
                size="xl"
                show={this.state.showModal}
                onHide={this.handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d32f2f' }}>White Label Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            {this.showCompanySelectBox()}
                            {this.showBrandSelectBox()}
                            <div className="col-6">
                                <div className="form-group">
                                    <span className="text-muted d-block mb-2">
                                        Platform *
                                    </span>
                                    <select
                                        value={this.state.platform}
                                        onChange={(e) => this.changePlatformSelectBox(e)}
                                        className="form-control"
                                        disabled={this.state.whitelabelId}
                                    >
                                        <option value="">Select from dropdown</option>
                                        {((this.state.availablePlatforms &&
                                            this.state.availablePlatforms.domain) ||
                                            !this.state.availablePlatforms) && (
                                                <option value="domain">Domain</option>
                                            )}
                                        {((this.state.availablePlatforms &&
                                            this.state.availablePlatforms.website) ||
                                            !this.state.availablePlatforms) && (
                                                <option value="website" disabled>Website</option>
                                            )}
                                        {((this.state.availablePlatforms &&
                                            this.state.availablePlatforms.socialMedia) ||
                                            !this.state.availablePlatforms) && (
                                                <option value="social-media">
                                                    Social Media
                                                </option>
                                            )}
                                        {((this.state.availablePlatforms &&
                                            this.state.availablePlatforms.eCommerce) ||
                                            !this.state.availablePlatforms) && (
                                                <option value="e-commerce">E-commerce</option>
                                            )}
                                        {((this.state.availablePlatforms &&
                                            this.state.availablePlatforms.appStore) ||
                                            !this.state.availablePlatforms) && (
                                                <option value="app-store" disabled>App Store</option>
                                            )}
                                    </select>
                                    {this.state.dataSaveError?.platform && (
                                        <span className="text-danger">
                                            {this.state.dataSaveError?.platform}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    {
                                        this.state.whitelabelId ? (
                                            <>
                                                <span className="text-muted d-block mb-2">
                                                    White Label URL *
                                                </span>
                                                <input
                                                    onChange={(e) =>
                                                        this.setState({
                                                            fileContent: e.target.value,
                                                        })
                                                    }
                                                    value={this.state.fileContent}
                                                    className="form-control"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-muted d-block mb-2">
                                                        Upload *
                                                </span>
                                                <input
                                                    id="input"
                                                    name="files"
                                                    multiple
                                                    type="file"
                                                    onChange={this.handleSingleImageUpload}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                                {this.state.dataSaveError?.data && (
                                    <span className="text-danger">
                                        {this.state.dataSaveError?.data}
                                    </span>
                                )}
                            </div>
                            <div className='col-6'>
                                <div className="form-check form-switch">
                                    <input style={{ pointerEvents: "auto", cursor: '' }} onClick={(e) => this.handleStatusChange(e)} checked={this.state.status ? true : false} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Status</label>
                                </div>
                            </div>
                            {!this.state.whitelabelId && <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <span className="text-muted d-block mb-2">
                                            File content
                                        </span>
                                        <textarea
                                            value={this.state.fileContent.join('\n')}
                                            placeholder="Upload a file in .txt format"
                                            rows="10"
                                            cols="80"
                                            disabled={true}
                                            onChange={(e) => {
                                                this.setState({ fileContent: e.target.value.split('\n') });
                                            }}
                                        />
                                    </div>
                                    {this.state.dataSaveError?.reportingDate && (
                                        <span className="text-danger">
                                            {this.state.dataSaveError?.reportingDate}
                                        </span>
                                    )}
                                </div>
                            </div>
                            }
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal}>
                        Close
                    </Button>
                    {this.renderSaveOrUpdateButton()}
                </Modal.Footer>
            </Modal>
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default withAlert()(connect(mapStateToProps)(authorizeAdmin(requireAuth(WhiteLabel))));