import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Legend,
  Cell,
  Tooltip,
  Label,
} from "recharts";
import { statusColorMap, toCapitalCase } from "../../../common/utils";
import "../charts.css";

const PieChartComponents = ({
  data,
  title,
  totalDocs,
  modal = false,
  openModel = null,
}) => {
  // Custom label function to show percentage
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.9;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={modal ? 14 : 12}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div
      className="position-relative"
      style={{
        padding: `${modal ? "1px" : "16px"}`,
        width: "100%",
        height: "inherit",
      }}
    >
      {title && (
        <div className="text-center mb-2 position-relative">
          <span className="uppercase-text">
            <strong>{title}</strong>
          </span>

          <button
            className="position-absolute btn btn-outline-light btn-sm non-print"
            style={{ top: "-8px", right: 0 }}
            onClick={(e) => {
              e.preventDefault();
              openModel && openModel();
            }}
          >
            <img
              src="/icons/popup.svg"
              alt="bar-popup-icon"
              width={22}
              height={22}
            />
          </button>
        </div>
      )}

      <ResponsiveContainer width="100%" height={modal ? 580 : 350}>
        {!data || data.length === 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%", paddingTop: "50px" }}
          >
            <p className="text-center">No data available</p>
          </div>
        ) : (
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={modal ? 100 : 60}
              outerRadius={modal ? 180 : 100}
              label={renderCustomizedLabel}
              labelLine={true}
              paddingAngle={5}
            >
              {data.map((entry, index) => {
                const { name } = entry;
                return (
                  <Cell key={`cell-${index}`} fill={statusColorMap[name]} />
                );
              })}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={viewBox.cy - (modal ? 10 : 4)}
                          className={`fill-foreground ${
                            modal ? "text-5xl" : "text-3xl"
                          } font-bold`}
                        >
                          {totalDocs.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + (modal ? 30 : 15)}
                          className={`fill-muted-foreground ${
                            modal ? "text-lg" : ""
                          }`}
                        >
                          Infringements
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
            <Tooltip />
            <Legend
              wrapperStyle={{
                top: 0,
                maxWidth: "100%",
                whiteSpace: "normal",
                textAlign: "center",
              }}
              iconSize={10}
              align="center"
              iconType="circle"
              verticalAlign="top"
              formatter={(value) => toCapitalCase(value)}
            />
          </PieChart>
        )}
      </ResponsiveContainer>

      {modal && (
        <div
          className="d-inline-flex position-absolute non-print"
          style={{ bottom: 0, right: "17px" }}
        >
          <button
            className="btn btn-outline-light btn-sm mr-3"
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            <img
              src="/icons/print.svg"
              alt="bar-print-icon"
              width={25}
              height={25}
            />
          </button>
          {/* <button
            disabled
            className="btn btn-outline-light btn-sm"
            onClick={(e) => e.preventDefault()}
          >
            <img
              src="/icons/download.svg"
              alt="bar-download-icon"
              width={25}
              height={25}
            />
          </button> */}
        </div>
      )}
    </div>
  );
};

export default PieChartComponents;
