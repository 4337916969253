import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js'
import {Provider} from 'react-redux'
import store from './store/index'
import reportWebVitals from './reportWebVitals';
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const alertOptions = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  timeout: 5000,
  type: 'success',
  // you can also just use 'scale'
  // transition: transitions.SCALE,
  containerStyle: {
    zIndex: 10000
  }
};


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
