import React from 'react';
import propTypes from 'prop-types';

class PlatformDropdownCompoent extends React.Component {
    constructor(props){
        super(props);
        this.initialPlatform = {key: "", name: ""}
        this.state = {
            selectedPlatform: this.initialPlatform,
            platforms: [
                {key: "domain", name: "Domain"},
                {key: "website", name: "Website"},
                {key: "social-media", name: "Social Media"},
                {key: "e-commerce", name: "E Commerce"},
                {key: "app-store", name: "App Store"}
            ]
        }

        this.valueChanged = this.valueChanged.bind(this);
    }

    async valueChanged(value){
        
        await this.setState(() => ({
            selectedPlatform: value
        }))

        this.props.onPlatformChanged(this.state.selectedPlatform);
    }

    render(){
        return (
            <select className={this.props.className} onChange={(e) => this.valueChanged(JSON.parse(e.target.value))} >
                <option value={JSON.stringify(this.initialPlatform)}>Select Platform</option>
                {this.state.platforms.map((platform, index) => (
                    <option key={index} value={JSON.stringify({key: platform.key, name: platform.name})}>{platform.name}</option>
                ))}
            </select>
        )
    }
}

PlatformDropdownCompoent.propTypes = {
    onPlatformChanged: propTypes.func,
    className : propTypes.string
}

export default PlatformDropdownCompoent;