import React from 'react';
import propTypes from 'prop-types'
import {
    AWAITING_APPROVAL, 
    APPROVED, 
    NO_ACTION,
    PURCHASE,
    CLIENT_REVIEW,
    fetchInfrigementAllStatus
  } from './../infrigementStatusCode'
import { withAlert } from 'react-alert'

class InfrigementStatusDropdown extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            statusArray:[AWAITING_APPROVAL.code, APPROVED.code, NO_ACTION.code, CLIENT_REVIEW.code]
        }
    }

    renderContent(){
        return <> 
            <select title="Change Status" onChange={e => this.props.onStatusChange(e)}>            
                {fetchInfrigementAllStatus().map((infrigementStatusCode) => 
                    <option key={infrigementStatusCode.code} value={infrigementStatusCode.code}>{infrigementStatusCode.text}</option>
                )}
            </select>
        </>
    }

    render(){
        return <>
            {this.renderContent()}
        </>
    }
}

InfrigementStatusDropdown.propTypes = {
    onStatusChange: propTypes.func
}

export default withAlert()(InfrigementStatusDropdown);