import {
    getClientWebsiteFilter,
    getClientDomainFilter,
    getClientSocialMediaFilter,
    getClientEcommerceMediaFilter,
    getClientAppStoreMediaFilter,
    getClientTmchMediaFilter,
    getClientTrapPurchaseMediaFilter
} from "./../storage";

// website reducer initial state
const initialState = getClientWebsiteFilter() ? getClientWebsiteFilter() : { url: '', reportingDate: '', priority: '', status: '' }

// domain reducer initial state
const domainReducerInitialState = getClientDomainFilter() ? getClientDomainFilter() : { reportingDate: '', domain: '', tld: '', priority: '', status: '' }

// social media initial state
const socialMediaReducerInitialState = getClientSocialMediaFilter() ? getClientSocialMediaFilter() : { platform: '', heading: '', link: '', reportingDate: '', priority: '', status: '' }

// ecommerce initial state
const ecommerceReducerInitialState = getClientEcommerceMediaFilter() ? getClientEcommerceMediaFilter() : { site: '', reportingDate: '', url: '', title: '', price: '', reason: '', priority: '', status: '' }

// app store initial state
const appStoreReducerInitialState = getClientAppStoreMediaFilter() ? getClientAppStoreMediaFilter() : { appName: '', appStore: '', url: '', reportingDate: '', developerName: '', latestPublishedDate: '', price: '', priority: '', status: '' }

// tmch initial state
const tmchReducerInitialState = getClientTmchMediaFilter() ? getClientTmchMediaFilter() : { markName: '', markType: '', status: '', reportingDate: '' }

// trap purchase initial state
const trapPurchaseReducerInitialState = getClientTrapPurchaseMediaFilter() ? getClientTrapPurchaseMediaFilter() : { site: '', reportingDate: '', url: '', title: '', price: '', reason: '', priority: '', status: '' }

// Domain filter reducer
export const clientDomainFilterReducer = (state = domainReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_DOMAIN_FILTER': {
            return {
                reportingDate: action.filter?.reportingDate,
                domain: action.filter?.domain,
                tld: action.filter?.tld,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// website filter reducer
export const clientWebsiteFilterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_WEBSITE_FILTER': {
            return {
                url: action.filter?.url,
                reportingDate: action.filter?.reportingDate,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// social media filter reducer
export const clientSocialMediaFilterReducer = (state = socialMediaReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_SOCIAL_MEDIA_FILTER': {
            return {
                platform: action.filter?.platform,
                heading: action.filter?.heading,
                link: action.filter?.link,
                reportingDate: action.filter?.reportingDate,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// ecommerce filter reducer
export const clientEcommerceFilterReducer = (state = ecommerceReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_ECOMMERCE_FILTER': {
            return {
                site: action.filter?.site,
                reportingDate: action.filter?.reportingDate,
                url: action.filter?.url,
                title: action.filter?.title,
                price: action.filter?.price,
                reason: action.filter?.reason,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// App Store filter reducer
export const clientAppStoreFilterReducer = (state = appStoreReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_APP_STORE_FILTER': {
            return {
                appName: action.filter?.appName,
                appStore: action.filter?.appStore,
                url: action.filter?.url,
                reportingDate: action.filter?.reportingDate,
                developerName: action.filter?.developerName,
                latestPublishedDate: action.filter?.latestPublishedDate,
                price: action.filter?.price,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// Tmch filter reducer
export const clientTmchFilterReducer = (state = tmchReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_TMCH_FILTER': {
            return {
                markName: action.filter?.markName,
                markType: action.filter?.markType,
                reportingDate: action.filter?.reportingDate,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}

// TrapPurchase filter reducer
export const clientTrapPurchaseFilterReducer = (state = trapPurchaseReducerInitialState, action) => {
    switch (action.type) {
        case 'CHANGE_CLIENT_TRAP_PURCHASE_FILTER': {
            return {
                site: action.filter?.site,
                reportingDate: action.filter?.reportingDate,
                url: action.filter?.url,
                title: action.filter?.title,
                price: action.filter?.price,
                reason: action.filter?.reason,
                priority: action.filter?.priority,
                status: action.filter?.status
            }
        }
        default: {
            return state
        }
    }
}