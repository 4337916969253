import React from "react";
import Footer from "../common/Footer.js";
import ClientSideNav from "../common/ClientSideNav";
import TopNav from "../common/TopNav.js";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";
import { availablePlatformsAtClientDashboard } from "../helpers";
import * as moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { connect } from "react-redux";
import requireAuth from "../hoc/requireAuth";
import authorizeClient from "../hoc/authorizeClient";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { castToServerDateFormat } from "./../settings/index";
import { changeClientDateFilter } from "./../store/actions/filterActions";
import { changeBrand } from "../store/actions/changeBrandAction";
import { Bar } from "react-chartjs-2";
import Card from "react-bootstrap/Card";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart } from "chart.js";

export const baseChartData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      backgroundColor: "rgba(239, 108, 0, 0.5)",
    },
  ],
};

class ClientDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      dashboardData: {},
      selectedBrand: null,
      isLoading: true,
      platforms: {},
      countOfListingsPerSeller: JSON.parse(JSON.stringify(baseChartData)),
      countOfListingsPerStatus: JSON.parse(JSON.stringify(baseChartData)),
      listingReportedChart: JSON.parse(JSON.stringify(baseChartData)),
      sumOfPriceInCategory: JSON.parse(JSON.stringify(baseChartData)),
      activeTabKey: "home",
    };

    this.getDashboard = this.getDashboard.bind(this);
  }

  getGraphsOptions(graphTitle) {
    return {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        anchor: "end",
        offset: -20,
        align: "start",
        legend: {
          position: "top",
        },
        title: {
          display: false,
          text: `${graphTitle}`,
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      scales: {
        y: {
          suggestedMin: 2,
          suggestedMax: 8,
        },
      },
    };
  }

  componentDidMount() {
    Chart.register(ChartDataLabels);
    const platforms = availablePlatformsAtClientDashboard();

    this.setState(() => ({ platforms: platforms }));

    this.getDashboard();
    this.getEcommerceGraphs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      this.getDashboard();
      this.getEcommerceGraphs();
    }

    if (prevProps.clientDateFilter !== this.props.clientDateFilter) {
      this.getDashboard();
      this.getEcommerceGraphs();
    }

    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      if (
        this.state.activeTabKey === "ecommerce-graphs" &&
        this.props.selectedBrand?.platforms?.eCommerce
      ) {
        this.handleTabChange("ecommerce-graphs");
      } else {
        this.handleTabChange("home");
      }
    }
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: { format: "DD-MM-YYYY" },
    };
  }

  async getEcommerceGraphs() {
    if (!this.props.selectedBrand?.platforms?.eCommerce) {
      return;
    }

    const options = {
      headers: { "Content-Type": "application/json" },
    };

    let reqData = {
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
    };

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id;
    }

    await window.axios
      .get("/dashboard/client/chart/ecommerce", { params: reqData }, options)
      .then(async (res) => {
        const {
          countOfListingsPerSeller,
          countOfListingsPerStatus,
          listingReportedChart,
          sumOfPriceInCategory,
        } = res.data;
        await this.setState(() => ({
          countOfListingsPerSeller,
          countOfListingsPerStatus,
          listingReportedChart,
          sumOfPriceInCategory,
        }));
      })
      .catch((error) => {
        // this.setState({isLoading: false})
      });
  }

  async getDashboard() {
    this.setState({ isLoading: true });
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    let reqData = {
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      companyId: this.props.selectedCompany.id || '',
    };

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id;
    }

    await window.axios
      .get("/dashboard/client", { params: reqData }, options)
      .then((res) => {
        this.setState({ dashboardData: res.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }

  changeBrandAndRedirect(e, brand, redirectPath) {
    e.preventDefault();
    let availableBrands = [];

    if (this.props.login?.user && this.props.login?.user?.brandDetails) {
      availableBrands = this.props.login?.user?.brandDetails;
    }

    if (availableBrands && availableBrands.length) {
      let selectedBrand = availableBrands.filter(
        (availableBrand) => availableBrand.brandName === brand
      );

      if (selectedBrand.length) {
        selectedBrand = selectedBrand[0];

        this.props.dispatch(
          changeBrand({
            brandName: selectedBrand?.brandName,
            id: selectedBrand?.id,
          })
        );
      }
    }

    return this.props.history.push(`${redirectPath}`);
  }

  renderDomainDashboard() {
    if (this.state.dashboardData.Domain && this.state.platforms.domain) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/domain-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/domain-dashboard">
                      <span style={{ color: "red" }}>DOMAIN</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {this.state.dashboardData.Domain.map(
                        (domain, domainIndex) => (
                          <tr
                            onClick={(e) =>
                              this.changeBrandAndRedirect(
                                e,
                                domain?.brand,
                                "domain-dashboard"
                              )
                            }
                            key={domainIndex}
                          >
                            <td>{domainIndex + 1}</td>
                            <td>{domain.brand}</td>
                            <td>{domain.highPriority}</td>
                            <td>{domain.lowPriority}</td>
                            <td>{domain.enforcement}</td>
                            <td>{domain.success}</td>
                          </tr>
                        )
                      )} */}
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.Domain?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.Domain?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.Domain?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.Domain?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.Domain?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  renderWebsiteDashboard() {
    if (this.state.dashboardData.Website && this.state.platforms.website) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/website-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/website-dashboard">
                      <span style={{ color: "red" }}>WEBSITE</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {this.state.dashboardData.Website.map(
                        (website, websiteIndex) => (
                          <tr
                            onClick={(e) =>
                              this.changeBrandAndRedirect(
                                e,
                                website?.brand,
                                "website-dashboard"
                              )
                            }
                            key={websiteIndex}
                          >
                            <td>{websiteIndex + 1}</td>
                            <td>{website.brand}</td>
                            <td>{website.highPriority}</td>
                            <td>{website.lowPriority}</td>
                            <td>{website.enforcement}</td>
                            <td>{website.success}</td>
                          </tr>
                        )
                      )} */}
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.Website?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.Website?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.Website?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.Website?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.Website?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  async handleTabChange(key) {
    await this.setState(() => ({
      activeTabKey: key,
    }));
  }

  renderSocialMediaDashboard() {
    if (
      this.state.dashboardData.SocialMedia &&
      this.state.platforms.socialMedia
    ) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/social-media-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/social-media-dashboard">
                      <span style={{ color: "red" }}>SOCIAL MEDIA</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {this.state.dashboardData.SocialMedia.map(
                        (socialMedia, socialMediaIndex) => (
                          <tr
                            onClick={(e) =>
                              this.changeBrandAndRedirect(
                                e,
                                socialMedia?.brand,
                                "social-media-dashboard"
                              )
                            }
                            key={socialMediaIndex}
                          >
                            <td>{socialMediaIndex + 1}</td>
                            <td>{socialMedia.brand}</td>
                            <td>{socialMedia.highPriority}</td>
                            <td>{socialMedia.lowPriority}</td>
                            <td>{socialMedia.enforcement}</td>
                            <td>{socialMedia.success}</td>
                          </tr>
                        )
                      )} */}
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.SocialMedia?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.SocialMedia?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.SocialMedia?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.SocialMedia?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.SocialMedia?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  renderEcommerceDashboard() {
    if (this.state.dashboardData.Ecommerce && this.state.platforms.eCommerce) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/ecommerce-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/ecommerce-dashboard">
                      <span style={{ color: "red" }}>ECOMMERCE</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {this.state.dashboardData.Ecommerce.map(
                        (ecommerce, ecommerceIndex) => (
                          <tr
                            onClick={(e) =>
                              this.changeBrandAndRedirect(
                                e,
                                ecommerce?.brand,
                                "ecommerce-dashboard"
                              )
                            }
                            key={ecommerceIndex}
                          >
                            <td>{ecommerceIndex + 1}</td>
                            <td>{ecommerce.brand}</td>
                            <td>{ecommerce.highPriority}</td>
                            <td>{ecommerce.lowPriority}</td>
                            <td>{ecommerce.enforcement}</td>
                            <td>{ecommerce.success}</td>
                          </tr>
                        )
                      )} */}
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.Ecommerce?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.Ecommerce?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.Ecommerce?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.Ecommerce?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.Ecommerce?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  renderGraphs() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={200}
            width={200}
            timeout={50000} //50 secs
          />
        </div>
      );
    }

    return (
      <>
        <div className="row">
          <div className="col-md-12 my-3">
            <Card>
              <Card.Body>
                <Bar
                  options={this.getGraphsOptions()}
                  data={this.state.listingReportedChart}
                />
              </Card.Body>
            </Card>
          </div>
          {/* <div className="col-md-12 my-3">
            <Card>
              <Card.Body>
                <Bar
                  options={this.getGraphsOptions()}
                  data={this.state.sumOfPriceInCategory}
                />
              </Card.Body>
            </Card>
          </div> */}
          <div className="col-md-12 my-3">
            <Card>
              <Card.Body>
                <Bar
                  options={this.getGraphsOptions()}
                  data={this.state.countOfListingsPerSeller}
                />
              </Card.Body>
            </Card>
          </div>
          {/* <div className="col-md-12 my-3">
            <Card>
              <Card.Body>
                <Bar
                  options={this.getGraphsOptions()}
                  data={this.state.countOfListingsPerStatus}
                />
              </Card.Body>
            </Card>
          </div> */}
        </div>
      </>
    );
  }

  renderAppStoreDashboard() {
    if (this.state.dashboardData.AppStore && this.state.platforms.appStore) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/app-store-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/app-store-dashboard">
                      <span style={{ color: "red" }}>APP STORE</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {this.state.dashboardData.AppStore.map(
                        (appStore, appStoreIndex) => (
                          <tr
                            onClick={(e) =>
                              this.changeBrandAndRedirect(
                                e,
                                appStore?.brand,
                                "app-store-dashboard"
                              )
                            }
                            key={appStoreIndex}
                          >
                            <td>{appStoreIndex + 1}</td>
                            <td>{appStore.brand}</td>
                            <td>{appStore.highPriority}</td>
                            <td>{appStore.lowPriority}</td>
                            <td>{appStore.enforcement}</td>
                            <td>{appStore.success}</td>
                          </tr>
                        )
                      )} */}
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.AppStore?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.AppStore?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.AppStore?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.AppStore?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.AppStore?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  renderTrapPurchaseDashboard() {
    if (this.state.dashboardData.TrapPurchase && this.state.platforms.trapPurchase) {
      return (
        <div className="row">
          <div className="col">
            <Link className="nav-link " to="/trap-purchase-dashboard">
              <div className="card card-small mb-4">
                <div className="card-header border-bottom">
                  <h6 className="m-0">
                    <Link className="nav-link " to="/trap-purchase-dashboard">
                      <span style={{ color: "red" }}>TRAP PURCHASE</span>
                    </Link>
                  </h6>
                </div>
                <div className="card-body p-0 pb-3 text-center">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          #
                        </th>
                        <th scope="col" className="border-0">
                          BRAND
                        </th>
                        <th scope="col" className="border-0">
                          HIGH PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          LOW PRIORITY
                        </th>
                        <th scope="col" className="border-0">
                          ENFORCEMENT
                        </th>
                        <th scope="col" className="border-0">
                          SUCCESS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{this.state.dashboardData.TrapPurchase?.sumOfAllBrands || 0}</td>
                        <td>{this.state.dashboardData.TrapPurchase?.sumOfHighPriority || 0}</td>
                        <td>{this.state.dashboardData.TrapPurchase?.sumOfLowPriority || 0}</td>
                        <td>{this.state.dashboardData.TrapPurchase?.sumOfEnforcement || 0}</td>
                        <td>{this.state.dashboardData.TrapPurchase?.sumOfsuccess || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  }

  handleCallback = async (event, picker) => {
    this.props.dispatch(
      changeClientDateFilter({
        startDate: picker.startDate,
        endDate: picker.endDate,
      })
    );
  };

  renderHtml() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={200}
            width={200}
            timeout={50000} //3 secs
          />
        </div>
      );
    }

    return (
      <>
        {/* Domains Stats Starts */}
        {this.renderDomainDashboard()}
        {/* website stats starts  */}
        {this.renderWebsiteDashboard()}
        {/* WEBSITE Stats Ends */}
        {/* SOCIAL MEDIA Stats Starts */}
        {this.renderSocialMediaDashboard()}
        {/* SOCIAL MEDIA Stats Ends */}
        {/* E-COMMERCE Stats Starts */}
        {this.renderEcommerceDashboard()}
        {/* E-COMMERCE Stats Ends             */}
        {/* APP STORE Stats Starts */}
        {this.renderAppStoreDashboard()}
        {/* APP STORE Stats Ends */}
        {/* TRAP PURCHASE Starts */}
        {this.renderTrapPurchaseDashboard()}
        {/* TRAP PURCHASE Ends */}
      </>
    );
  }

  render() {
    return (
      <>
        <div className="row">
          <ClientSideNav />

          <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
            <TopNav />

            <div className="main-content-container container-fluid px-4">
              <div className="page-header row no-gutters py-4">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6 text-sm-left mb-0">
                      <span className="text-uppercase page-subtitle">
                        Dashboard
                      </span>
                    </div>
                    <div className="col-6 text-sm-right mb-0">
                      <span className="uppercase-text">
                        <strong>Reporting Date: </strong>
                      </span>
                      <DateRangePicker
                        onApply={this.handleCallback}
                        initialSettings={this.getDateFilters()}
                        alwaysShowCalendars={true}
                      >
                        <input className="from-control" />
                      </DateRangePicker>
                    </div>
                  </div>
                </div>
              </div>

              <Tabs
                activeKey={this.state.activeTabKey}
                onSelect={(e) => this.handleTabChange(e)}
                className="mb-3"
              >
                <Tab eventKey="home" title="Home">
                  {this.renderHtml()}
                </Tab>
                {this.props.selectedBrand?.platforms?.eCommerce && (
                  <Tab eventKey="ecommerce-graphs" title="Ecommerce">
                    {this.renderGraphs()}
                  </Tab>
                )}
              </Tabs>
            </div>
            <Footer />
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clientDateFilter: state.clientDateFilter,
  selectedBrand: state.selectedBrand,
  selectedCompany: state.selectedCompany,
});

export default connect(mapStateToProps)(
  authorizeClient(requireAuth(ClientDashboard))
);
