import React, {memo} from 'react';
import propTypes from 'prop-types';

class StagingDataDraftDropdownComponentDropdown extends React.Component {
    constructor(props){
        super(props);

        this.initialDraft = {key: "", name: ""}
        
        this.state = {
            selectedDraft: this.initialDraft,
            drafts: []
        }

        this.getDrafts = this.getDrafts.bind(this);
        this.valueChanged = this.valueChanged.bind(this);
    }

    componentDidMount(){
        let {brand, platform, company} = this.props;

        if(brand && platform && company){
            this.getDrafts();
        }
    }

    componentDidUpdate(prevProps, prevState){
        let {brand, platform, company, draftsChangeDetector} = this.props;

        if(brand && platform && company){
            if(prevProps.brand !== brand || prevProps.platform !== platform || prevProps.company != company){
                this.getDrafts();
            }
        }

        if(prevProps.draftsChangeDetector !== draftsChangeDetector){
            this.getDrafts();
        }
    }

    async getDrafts(){
        await window.axios.get(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/fetch-drafts`, {
            params: {
                brand: this.props.brand,
                platform: this.props.platform,
                company: this.props.company
            }
        })
        .then(async (response) => {
            const drafts = response.data?.drafts;

            await this.setState(() => ({
                drafts
            }))
        })
        .catch((error) => {
            console.log(error)
        })
    }

    async valueChanged(value){
        
        await this.setState(() => ({
            selectedDraft: value
        }))

        this.props.onDraftChanged(this.state.selectedDraft);
    }

    render(){
        return <>
            <select className={this.props.className} onChange={(e) => this.valueChanged(JSON.parse(e.target.value))}>
                <option value={JSON.stringify(this.initialDraft)}>Select Draft</option>
                {
                    this.state.drafts.map((draft) => 
                        <option key={draft?._id} value={JSON.stringify({key: draft?._id, name: draft.fileName})}>{draft?.fileName}</option>
                    )
                }
            </select>
        </>
    }
}

StagingDataDraftDropdownComponentDropdown.propTypes = {
    className : propTypes.string,
    brand: propTypes.string,
    company: propTypes.string,
    platform: propTypes.string,
    onDraftChanged: propTypes.func
}

export default memo(StagingDataDraftDropdownComponentDropdown);