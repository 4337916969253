import React from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "sweetalert/dist/sweetalert.css";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import debounce from 'lodash.debounce';
import { Table } from "react-bootstrap";
import { castToDefaultDateFormat, castToTimeFormat } from '../../settings/index'
import Loader from "react-loader-spinner";
import { InputGroup, FormControl } from 'react-bootstrap';
import {
    getInfrigementStatusText,
    fetchInfrigementEcommerceStatus,
} from "./../../infrigementStatusCode";

class LogSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authorised: true,
            platform: "",
            showDataSavedAlert: false,
            selectedOption: null,
            companies: [],
            users: [],
            brands: [],
            emailId: '',
            username: "",
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY'),
            company: "",
            brand: "",
            dataSaveError: {},
            locale: { format: "DD/MM/YYYY" },
            logData: [],
            pagination: {
                page: 1,
                nextPage: 1,
                prevPage: null,
                hasNextPage: true,
                hasPrevPage: false,
                pagingCounter: 1,
                totalDocs: null,
                totalPages: null
            },
            perPage: 10,
            isLoading: false,
            hasMoreDomainData: true,
            selectedKey: "",
            searchTerm: "",
            selectedFromStatus: '',
            selectedToStatus: '',
        };
        this.listofRecords = debounce(this.listofRecords.bind(this), 500);
        window.onscroll = debounce(() => {
            const {
                listofRecords,
                state: {
                    hasMoreDomainData
                }
            } = this;

            if (!hasMoreDomainData) {
                return;
            }

            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                listofRecords();
            }
        }, 100);
    }

    statusFromChange = (event) => {
        this.setState({ selectedFromStatus: event.target.value });
    };

    statusToChange = (event) => {
        this.setState({ selectedToStatus: event.target.value });
    };

    componentDidUpdate(prevProps, prevState) {
        //this.state.listofRecords();
        if (prevState.company !== this.state.company) {
            this.getBrands();
            this.getEmailId();
        }

        if (
            this.state.brand &&
            this.state.brand !== prevState.brand // Check if the brand has changed
        ) {
            let selectedBrand = this.state.brands.filter(
                (brand) => brand.id === this.state.brand
            );

            if (selectedBrand.length) {
                let platform = selectedBrand[0].platform;

                this.setState(() => ({
                    availablePlatforms: platform,
                }));
            } else {
                this.setState(() => ({
                    availablePlatforms: null,
                }));
            }
        }
    }

    componentDidMount() {
        this.getCompanies();
        this.getUsers();
        this.listofRecords();
    }

    async getCompanies() {
        await window.axios
            .get("/company", {
                params: {
                    perPage: 100,
                },
            })
            .then((res) => {
                this.setState({
                    companies: res.data.docs,
                });
            });
    }

    async getUsers() {
        await window.axios
            .get("/users", {
                params: {
                    page: 1,
                    perPage: 100,
                    sortDirection: 1,

                },
            })
            .then((res) => {
                this.setState({
                    users: res.data.docs,
                });
            });
    }

    async getBrands() {
        await window.axios
            .get("/brand", {
                params: {
                    disabledBrands: true,
                    company: this.state.company,
                    page: 1,
                    perPage: 800,
                },
            })
            .then((res) => {
                res.data.docs.push({ brandName: 'All' });
                this.setState({
                    brands: res.data.docs,
                });
            });
    }

    async getEmailId() {
        await window.axios
            .get("/users/getEmailId", {
                params: {
                    page: 1,
                    perPage: 400,
                },
            })
            .then((res) => {
                this.setState({
                    emailId: res.data.data,
                });
            });
    }

    async logRecordSearch(reArrangeRecords = false) {
        let reqData = {
            page: this.state.pagination.page,
            perPage: this.state.perPage,
        }

        reqData.sortBy = this.state.sortBy;
        reqData.sortDirection = this.state.sortDirection;
        if (reArrangeRecords) {
            reqData.page = 1
        }
        reqData.search = this.state.search;

        this.setState(() => ({ isLoading: true }));
        reqData.company = this.state.company;
        reqData.brand = this.state.brand;
        reqData.platform = this.state.platform;
        reqData.startDate = this.state.startDate;
        reqData.endDate = this.state.endDate;
        reqData.username = this.state.username;
        reqData.searchTerm = this.state.searchTerm;
        reqData.selectedFromStatus = this.state.selectedFromStatus;
        reqData.selectedToStatus = this.state.selectedToStatus;
        await window.axios.get('/data-log/searchLogRecord', {
            params: reqData
        }).then(res => {
            this.setState(() => ({
                logData: res.data.docs,
                isLoading: false,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreDomainData: res.data.hasNextPage
            }));
        });
    }

    async listofRecords(reArrangeRecords = false) {

        let reqData = {
            page: this.state.pagination.nextPage,
            perPage: this.state.perPage,
        }

        if (reArrangeRecords) {
            reqData.page = 1
        }

        this.setState(() => ({ isLoading: true }));

        await window.axios.get('/data-log/searchLogRecord', {
            params: reqData
        }).then(res => {
            let LogSearchData = [...this.state.logData].concat(res.data.docs)

            if (reArrangeRecords) {
                LogSearchData = res.data.docs
            }

            this.setState(() => ({
                logData: LogSearchData,
                isLoading: false,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreDomainData: res.data.hasNextPage
            }));
        });
    }

    renderLoader() {
        if (this.state.isLoading) {
            return <div className="text-center">
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={200}
                    timeout={5000} //3 secs
                />
            </div>
        }
    }

    getDateFilters() {
        return {
            startDate: moment(),
            endDate: moment(),
            locale: this.state.locale,
        };
    }

    handleCallback = (date) => {
        const dateArray = date.delegateTarget.value.split(' - ');
        this.setState({ startDate: dateArray[0], endDate: dateArray[1] })
    }

    handleChange = (selectedOption) => {
        if (selectedOption.label.toUpperCase() === 'ALL') {
            this.setState({
                brand: 'ALL',
            });
        } else {
            const selectedBrand = this.state.brands.find(
                (brand) => brand.id === selectedOption.value
            );

            this.setState({
                brand: selectedOption.value,
                brandId: selectedBrand ? selectedBrand.id : null,
            });
        }
    };

    changePlatformSelectBox(e) {
        let selectedKeyValue = "domainKeys";

        let key = e.target.value;

        if (key === "website") {
            selectedKeyValue = "websiteKeys";
        }
        if (key === "social-media") {
            selectedKeyValue = "socialMediaKeys";
        }
        if (key === "e-commerce") {
            selectedKeyValue = "ecommerceKeys";
        }
        if (key === "app-store") {
            selectedKeyValue = "appStoreKeys";
        }

        this.setState({
            platform: e.target.value,
            selectedKey: selectedKeyValue,
        });
    }

    renderHtml() {
        const statusOptions = fetchInfrigementEcommerceStatus();
        if (!this.state.authorised) {
            return <UnauthorisedPage />;
        }

        const options = this.state.brands.map((brand, brandKey) => ({
            value: brand.id,
            label: brand.brandName,
        }));

        return (
            <div className="row">
                {this.props?.login?.user?.role === 'Admin' ? <AdminSideNav /> : <AnalystSideNav />}

                <main
                    id="bulk-data-download-screen"
                    className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
                >
                    <TopNav />

                    <div className="main-content-container container-fluid px-4">
                        <div className="page-header row no-gutters py-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-sm-4 text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">
                                            Log Search
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card card-small mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Company
                                                        </span>
                                                        <select
                                                            onChange={(e) =>
                                                                this.setState({ company: e.target.value })
                                                            }
                                                            className="form-control"
                                                        >
                                                            <option value="">Select from dropdown</option>
                                                            {this.state.companies.map(
                                                                (company, companyIndex) => (
                                                                    <option key={companyIndex} value={company.id}>
                                                                        {company.name}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        {this.state.dataSaveError?.company && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Brand
                                                        </span>
                                                        <Select
                                                            options={options}
                                                            onChange={this.handleChange}
                                                        />

                                                        {this.state.dataSaveError?.brand && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.brand}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Platform
                                                        </span>
                                                        <select
                                                            value={this.state.platform}
                                                            onChange={(e) => this.changePlatformSelectBox(e)}
                                                            className="form-control"
                                                        >
                                                            <option value="">Select from dropdown</option>
                                                            {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.domain) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="domain">Domain</option>
                                                                )}
                                                            {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.website) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="website">Website</option>
                                                                )}
                                                            {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.socialMedia) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="social-media">
                                                                        Social Media
                                                                    </option>
                                                                )}
                                                            {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.eCommerce) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="e-commerce">E-commerce</option>
                                                                )}
                                                            {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.appStore) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="app-store">App Store</option>
                                                                )}

                                                            <option value="tmch">TMCH</option>
                                                            <option value="trap-purchase">Trap Purchase</option>
                                                        </select>
                                                        {this.state.dataSaveError?.platform && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.platform}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Date Range
                                                        </span>
                                                        <DateRangePicker
                                                            onApply={this.handleCallback}
                                                            initialSettings={this.getDateFilters()}
                                                            alwaysShowCalendars={true}
                                                        >
                                                            <input className="from-control" />
                                                        </DateRangePicker>
                                                    </div>
                                                    {this.state.dataSaveError?.reportingDate && (
                                                        <span className="text-danger">
                                                            {this.state.dataSaveError?.reportingDate}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Username
                                                        </span>
                                                        <select
                                                            onChange={(e) =>
                                                                this.setState({ username: e.target.value })
                                                            }
                                                            className="form-control"
                                                        >
                                                            <option value="">Select from dropdown</option>
                                                            {this.state.users.map(
                                                                (user, userIndex) => (
                                                                    <option key={userIndex} value={user.id}>
                                                                        {user.username}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        {this.state.dataSaveError?.company && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Search Term
                                                        </span>
                                                        <InputGroup className="">
                                                            <FormControl
                                                                placeholder=""
                                                                aria-label="Search"
                                                                aria-describedby="basic-addon2"
                                                                value={this.state.searchTerm}
                                                                onChange={(event) => this.setState({ searchTerm: event.target.value })}
                                                                style={{ width: '200px' }}
                                                            />
                                                        </InputGroup>
                                                        {this.state.dataSaveError?.company && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Status From
                                                        </span>
                                                        <select
                                                            value={this.state.selectedFromStatus}
                                                            onChange={this.statusFromChange}
                                                        >
                                                            {statusOptions.map((status) => (
                                                                <option key={status.code} value={status.code}>
                                                                    {status.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.dataSaveError?.company && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Status To
                                                        </span>
                                                        <select
                                                            value={this.state.selectedToStatus}
                                                            onChange={this.statusToChange}
                                                        >
                                                            {statusOptions.map((status) => (
                                                                <option key={status.code} value={status.code}>
                                                                    {status.text}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.dataSaveError?.company && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.company}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <button
                                                            onClick={(e) => this.logRecordSearch(true)}
                                                            className="mt-4 btn btn-ldotr"
                                                        >
                                                            Fetch Log
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card card-small mb-4">

                                    <div className="card-body p-0">
                                        <Table hover width="100%" id="queue-table" className="table mb-0">
                                            <thead className="bg-light uppercase-th">
                                                <tr>
                                                    <th width="3%" scope="col" className="border-0">Sr.</th>
                                                    <th width="10%" scope="col" className="border-0">Company</th>
                                                    <th width="8%" scope="col" className="border-0">Brand</th>
                                                    <th width="10%" scope="col" className="border-0">Platform</th>
                                                    <th width="10%" scope="col" className="border-0">Username</th>
                                                    <th width="14%" scope="col" className="border-0">URL/Domain</th>
                                                    <th width="6%" scope="col" className="border-0">Date & Time</th>
                                                    <th width="8%" scope="col" className="border-0">From Status</th>
                                                    <th width="8%" scope="col" className="border-0">To Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state?.logData.map((log, key) =>
                                                        <>
                                                            <tr
                                                                key={key}
                                                                data-toggle="collapse"
                                                                data-target={'.multi-collapse' + key}
                                                                aria-controls={'multiCollapseExample' + key}
                                                            >
                                                                <td><p className="mx-0 my-0">{key + 1}</p></td>
                                                                <td><p className="mx-0 my-0 truncate">{log.company?.name || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={log.brand?.brandName || ''}>{log.brand?.brandName || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={log.module || ''}>{log.module || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={log.reqUserId?.username || ''}>{log.reqUserId?.username || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={log.searchTerm || ''}>{log.searchTerm || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={log.createdAt || ''}>{log.createdAt || ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={getInfrigementStatusText(log.statusFrom)}>{log.statusFrom && getInfrigementStatusText(log.statusFrom)}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={getInfrigementStatusText(log.statusTo)}>{log.statusTo && getInfrigementStatusText(log.statusTo)}</p></td>
                                                            </tr>
                                                        </>
                                                    )
                                                }

                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                                {(this.state.hasMoreDomainData) && <div className="text-center">
                                    <a href='#' className='text-danger' onClick={(e) => this.listofRecords()}> {this.renderLoader()} Load more...</a>
                                </div>}
                                {(!this.state.hasMoreDomainData) && <div className="text-center">
                                    <label className='text-muted'>No more data to show.</label>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* data saved sweet alert */}
                    <Footer />
                </main>
            </div>
        );
    }

    render() {
        return <>{this.renderHtml()}</>;
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    login: state.login,
    response: state.response,
});

export default withAlert()(
    connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(LogSearch)))
);
