import { getSelectedBrand } from "../storage";

const initialState = getSelectedBrand() ? getSelectedBrand() : {id:null, brandName: 'All'}

export const brandsSelectionReducer = (state = initialState, action) => {
    switch(action.type){
        case 'CHANGE_BRAND_SELECTION': {
            return {
                id: action.brand.id,
                brandName: action.brand.brandName,
                platforms: action.brand.platforms
            }
        }
        default: {
            return state
        }
    }
}