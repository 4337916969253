import React, { Component } from 'react'
import Footer from '../../common/Footer.js'
import ClientSideNav from '../../common/ClientSideNav'
import UnauthorisedPage from '../Unauthorised'
import TopNav from '../../common/TopNav.js'
import * as moment from 'moment'
import debounce from 'lodash.debounce';
import download from "downloadjs";
import { Table } from "react-bootstrap";
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeClient from '../../hoc/authorizeClient'
import NotAccessablePage from '../NotAccessablePage.js'
import { getNewSortOrder } from '../../helpers.js';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import Loader from "react-loader-spinner";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { castToDefaultDateFormat, castToServerDateFormat } from '../../settings/index'
import { changeClientDateFilter } from '../../store/actions/filterActions'
import InfrigementStatusDropdownComponent from './../../components/InfrigementStatusDropdownComponent'

class AppStoreDashboard extends Component {
  constructor(props) {
    super(props);
    this.getTmchData = debounce(this.getTmchData.bind(this), 1000);

    this.state = {
      sorting: {
        sortBy: '',
        sortIconClass: 'sort-icon-nutral',
        sortOrder: ''
      },
      authorised: true,
      domains: [],
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null
      },
      perPage: 10,
      hasMoreDomainData: true,
      isLoading: false,
      locale: { format: 'DD/MM/YYYY' }
    }

    window.onscroll = debounce(() => {
      const {
        getTmchData,
        state: {
          hasMoreDomainData
        }
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        getTmchData();
      }
    }, 100);

  }

  changeSorting(sortBy) {

    this.setState((prevState) => {

      const { sortOrder, classPostFix } = getNewSortOrder(prevState)

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder
        }
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sorting.sortBy !== this.state.sorting.sortBy
      || prevState.sorting.sortOrder !== this.state.sorting.sortOrder) {
      this.getTmchData(true)
    }

    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      this.getTmchData(true)
    }

    if (prevProps.clientTmchFilter !== this.props.clientTmchFilter) {
      this.getTmchData(true)
    }

    if (prevProps.clientDateFilter !== this.props.clientDateFilter) {
      this.getTmchData(true)
    }
  }

  async downloadExcel() {
    const options = {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-disposition': 'attachment; filename=tmch.xlsx"'
      }
    }

    let reqData = {
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      ...this.props.clientTmchFilter
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }

    await window.axios.get('/tmch/download-xls', {
      params: reqData,
      responseType: 'blob',
      timeout: 30000,
    }, options).then(res => {
      download(res.data, 'tmch.xls');
    });
  }

  async getTmchData(reArrangeRecords = false) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    let reqData = {
      sortBy: this.state.sorting.sortBy,
      sortOrder: this.state.sorting.sortOrder,
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      ...this.props.clientTmchFilter
    }

    if (reArrangeRecords) {
      reqData.page = 1
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }
    
    reqData.module = 'tmch';
    this.setState({ isLoading: true })

    await window.axios.get('/tmch', { params: reqData }, options).then(res => {
      let domainData = [...this.state.domains].concat(res.data.docs)

      if (reArrangeRecords) {
        domainData = res.data.docs
      }

      this.setState({
        pagination: {
          page: res.data.page,
          nextPage: res.data.nextPage,
          prevPage: res.data.prevPage,
          hasNextPage: res.data.hasNextPage,
          hasPrevPage: res.data.hasPrevPage,
          pagingCounter: res.data.pagingCounter,
          totalDocs: res.data.totalDocs,
          totalPages: res.data.totalPages,
        }, domains: domainData, hasMoreDomainData: res.data.hasNextPage
      })
    }).catch(() => { }).then(() => {
      this.setState({ isLoading: false })
    });
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: this.state.locale
    }
  }

  handleCallback = async (event, picker) => {

    this.props.dispatch(changeClientDateFilter({
      startDate: picker.startDate,
      endDate: picker.endDate
    }))
  }

  renderLoader() {
    if (this.state.isLoading) {
      return <div className="text-center">
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={10}
          width={200}
          timeout={5000} //3 secs
        />
      </div>
    }
  }

  componentDidMount() {
    this.getTmchData();
  }

  printPaginationInfo() {
    let totalDocs = this.state.pagination.totalDocs ? this.state.pagination.totalDocs : 0
    let visibleRecords = 0;

    if (totalDocs) {
      visibleRecords = (totalDocs > (this.state.perPage * this.state.pagination.page)) ? this.state.perPage * this.state.pagination.page : totalDocs
    }

    return <>
      <br />
      <span className="text-uppercase page-subtitle">Showing <strong>{visibleRecords}</strong> records of <strong>{totalDocs}</strong></span>
    </>
  }

  renderHtml() {

    if (!this.props.selectedBrand?.platforms?.tmch) {
      return <NotAccessablePage />
    }

    return <div className="row">

      <ClientSideNav />

      <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

        <TopNav />

        <div className="main-content-container container-fluid px-4">
          <div className="page-header row no-gutters py-4">
            <div className="col-12">
              <div className="row">
                <div className="col-3 text-sm-left mb-0">
                  <span className="text-uppercase page-subtitle">TMCH Dashboard
                  </span>
                  {this.printPaginationInfo()}
                </div>
                <div className="col-2 text-sm-right mb-0">
                  {this.renderLoader()}
                </div>
                <div className="col-6 text-sm-right mb-0">
                  <span className="uppercase-text"><strong>Reporting Date: </strong></span>
                  <DateRangePicker
                    onApply={this.handleCallback}
                    initialSettings={this.getDateFilters()}
                    alwaysShowCalendars={true}
                  >
                    <input className="from-control" />
                  </DateRangePicker>
                </div>
                <div className="col-1 text-sm-right mb-0">
                  <a title="Download as xls" href="#" className="display-6" onClick={() => this.downloadExcel()}>
                    <i className="material-icons text-primary">file_download</i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="scrollableDiv" style={{ overflow: "hidden" }}>

            <div className="row">
              <div className="col">
                {/* table here */}
                <Table className="table-responsive">
                  <thead className="uppercase-th">
                    <tr>
                      <th scope="col" width="3%" className="border-0">#</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('markName')} className={`border-0 ${this.state.sorting.sortBy === 'markName' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Mark Name</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('markType')} className={`border-0 ${this.state.sorting.sortBy === 'markType' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Mark Type</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('status')} className={`border-0 ${this.state.sorting.sortBy === 'status' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Status</th>
                      <th width="10%" scope="col" onClick={(e) => this.changeSorting('reportingDate')} className={`border-0 ${this.state.sorting.sortBy === 'reportingDate' ? this.state.sorting.sortIconClass + ' sort-icon' : 'sort-icon sort-icon-nutral'}`}>Reporting Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.domains.map((domain, key) =>
                        <>
                          <tr
                            key={key}
                            data-toggle="collapse"
                            data-target={'.multi-collapse' + key}
                            aria-controls={'multiCollapseExample' + key}
                          >
                            <td >{key + 1}</td>
                            <td><p title={domain.appName} className="bind-width">{domain.markName}</p> </td>
                            <td><p title={domain.appStore} className="bind-width">{domain.markType}</p></td>
                            <td><InfrigementStatusDropdownComponent selectedInfrigemnt={domain} selectedInfrigemntIndex={key} infrigementType="tmch" /></td>
                            <td><p title={castToDefaultDateFormat(domain.reportingDate)} className="bind-width">{castToDefaultDateFormat(domain.reportingDate)}</p></td>
                          </tr>
                          <tr className={'collapse multi-collapse' + key} id={'multiCollapseExample' + key}>
                            <td colSpan={9}>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="row">

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Start Date</span>
                                        <input type="text" value={castToDefaultDateFormat(domain.startDate)} disabled className="form-control" aria-label="StartDate" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Expiry Date</span>
                                        <input type="text" value={castToDefaultDateFormat(domain.expiryDate)} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Sunrise Flag</span>
                                        <input type="text" value={domain.sunriseFlag} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Company Name</span>
                                        <input type="text" value={domain?.company?.name} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Brand Name</span>
                                        <input type="text" value={domain?.brand?.brandName} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <span className="text-muted d-block mb-2">Notifications</span>
                                        <input type="text" value={domain.notifications} disabled className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                      </div>
                                    </div>
                                    {
                                      (domain.notificationData.length > 0) && <div className="col-md-12">
                                        <strong>Notifications</strong>
                                      </div>
                                    }

                                    {
                                      domain.notificationData.map((noti, index) =>
                                        <div key={index} className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-6">{noti.domain}</div>
                                            <div className="col-md-6">{castToDefaultDateFormat(noti.date)}</div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <div className="modal" id={'myModal' + key}>
                            <div className="modal-dialog modal-800">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h4 className="modal-title">Image</h4>
                                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                  <img src={domain.imageUrl} width="100%" className="img-responsive" alt="Cinque Terre" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  </tbody>
                </Table>
              </div>
            </div>

          </div>
        </div>

        <Footer />
      </main>
    </div>
  }

  render() {
    return <>
      {this.renderHtml()}
    </>
  }
}

const mapStateToProps = (state) => ({
  clientTmchFilter: state.clientTmchFilter,
  clientDateFilter: state.clientDateFilter
})

export default connect(mapStateToProps)(authorizeClient(requireAuth(AppStoreDashboard)));