import React from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "sweetalert/dist/sweetalert.css";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import debounce from 'lodash.debounce';
import { Table } from "react-bootstrap";
import { castToDefaultDateFormat, castToTimeFormat } from '../../settings/index'
import Loader from "react-loader-spinner";

class DomainDataDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authorised: true,
            showDataSavedAlert: false,
            emailId: '',
            startDate: moment().format('DD/MM/YYYY'),
            endDate: moment().format('DD/MM/YYYY'),
            dataSaveError: {},
            locale: { format: "DD/MM/YYYY" },
            queues: [],
            fileContent: [],
            errorMessage: '',
            pagination: {
                page: 1,
                nextPage: 1,
                prevPage: null,
                hasNextPage: true,
                hasPrevPage: false,
                pagingCounter: 1,
                totalDocs: null,
                totalPages: null
            },
            perPage: 10,
            isLoading: false,
            hasMoreDomainData: true,
            selectedKey: "",
            isEditable: false,
        };
        this.listofRecords = debounce(this.listofRecords.bind(this), 500);
        window.onscroll = debounce(() => {
            const {
                listofRecords,
                state: {
                    hasMoreDomainData
                }
            } = this;

            if (!hasMoreDomainData) {
                return;
            }

            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                listofRecords();
            }
        }, 100);
    }

    componentDidUpdate(prevProps, prevState) {
        //this.state.listofRecords();
        if (prevState.company !== this.state.company) {
            this.getBrands();
            this.getEmailId();
        }
    }

    componentDidMount() {
        this.listofRecords();
        this.getEmailId();
    }

    async getEmailId() {
        await window.axios
            .get("/users/getEmailId", {
                params: {
                    page: 1,
                    perPage: 400,
                },
            })
            .then((res) => {
                this.setState({
                    emailId: res.data.data,
                });
            });
    }

    async postQueueConfirmation() {
        if (this.state.fileContent.length < 0) {
            return;
        }
        const options = {
            headers: { "Content-Type": "application/json" },
        };

        await window.axios
            .post("/domain-data-download/queue-domains-result",
                {
                    domains: this.state.fileContent,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    email: this.state.emailId,
                },
                options
            )
            .then((res) => {
                this.listofRecords(true);
                Swal.fire("Saved!", "", "success");
            })
            .catch(function (error) {
                alert(error.response.data.message);
            });
    }

    uploadFile = async (event) => {
        const file = event.target.files[0];

        if (file && file.type === 'text/plain') {
            const reader = new FileReader();

            reader.onload = (event) => {
                const content = event.target.result;
                const lines = content.split('\n');
                this.setState(() => ({
                    fileContent: lines,
                    errorMessage: ''
                }));
            };

            reader.readAsText(file);
        } else {
            this.setState({ fileContent: [], errorMessage: 'Please upload a valid .txt file.' });
        }
    }

    async listofRecords(reArrangeRecords = false) {

        let reqData = {
            page: this.state.pagination.nextPage,
            perPage: this.state.perPage,
        }

        if (reArrangeRecords) {
            reqData.page = 1
        }

        this.setState(() => ({ isLoading: true }));

        await window.axios.get('/domain-data-download/getRecords', {
            params: reqData
        }).then(res => {
            let queueData = [...this.state.queues].concat(res.data.docs)

            if (reArrangeRecords) {
                queueData = res.data.docs
            }

            this.setState(() => ({
                queues: queueData,
                isLoading: false,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreDomainData: res.data.hasNextPage
            }));
        });
    }

    renderLoader() {
        if (this.state.isLoading) {
            return <div className="text-center">
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={200}
                    timeout={5000} //3 secs
                />
            </div>
        }
    }

    getDateFilters() {
        return {
            startDate: moment(),
            endDate: moment(),
            locale: this.state.locale,
        };
    }

    handleCallback = (date) => {
        const dateArray = date.delegateTarget.value.split(' - ');
        this.setState({ startDate: dateArray[0], endDate: dateArray[1] })
    }

    handleChange = (selectedOption) => {
        if (selectedOption.label.toUpperCase() === 'ALL') {
            this.setState({
                brand: 'ALL',
            });
        } else {
            const selectedBrand = this.state.brands.find(
                (brand) => brand.id === selectedOption.value
            );

            this.setState({
                brand: selectedOption.value,
                brandId: selectedBrand ? selectedBrand.id : null,
            });
        }
    };


    renderHtml() {
        if (!this.state.authorised) {
            return <UnauthorisedPage />;
        }

        return (
            <div className="row">
                {this.props?.login?.user?.role === 'Admin' ? <AdminSideNav /> : <AnalystSideNav />}

                <main
                    id="bulk-data-download-screen"
                    className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
                >
                    <TopNav />

                    <div className="main-content-container container-fluid px-4">
                        <div className="page-header row no-gutters py-4">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-sm-4 text-sm-left mb-0">
                                        <span className="text-uppercase page-subtitle">
                                            DOMAIN DATA DOWNLOAD
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="card card-small mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Date Range
                                                        </span>
                                                        <DateRangePicker
                                                            onApply={this.handleCallback}
                                                            initialSettings={this.getDateFilters()}
                                                            alwaysShowCalendars={true}
                                                        >
                                                            <input className="from-control" style={{ height: '38px' }} />
                                                        </DateRangePicker>
                                                    </div>
                                                    {this.state.dataSaveError?.reportingDate && (
                                                        <span className="text-danger">
                                                            {this.state.dataSaveError?.reportingDate}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Email Id
                                                        </span>
                                                        <Select
                                                            value={{ value: this.state.emailId, label: this.state.emailId }}
                                                            isDisabled={true}
                                                        />
                                                        {this.state.dataSaveError?.emailId && (
                                                            <span className="text-danger">
                                                                {this.state.dataSaveError?.brand}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            Upload
                                                        </span>
                                                        <input type="file" accept=".txt" onChange={this.uploadFile} />
                                                        {this.state.errorMessage && <div style={{ color: 'red' }}>{this.state.errorMessage}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <span className="text-muted d-block mb-2">
                                                            File content
                                                        </span>
                                                        <textarea
                                                            value={this.state.fileContent.join('\n')}
                                                            placeholder="Upload a file in .txt format"
                                                            rows="10"
                                                            cols="80"
                                                            onChange={(e) => {
                                                                this.setState({ fileContent: e.target.value.split('\n') });
                                                            }}
                                                        />
                                                    </div>
                                                    {this.state.dataSaveError?.reportingDate && (
                                                        <span className="text-danger">
                                                            {this.state.dataSaveError?.reportingDate}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button
                                                            onClick={(e) => this.postQueueConfirmation()}
                                                            className="mt-4 btn btn-ldotr"
                                                        >
                                                            Fetch Data
                                                        </button>
                                                    </div>
                                                    {this.state.dataSaveError?.reportingDate && (
                                                        <span className="text-danger">
                                                            {this.state.dataSaveError?.reportingDate}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="card card-small mb-4">

                                    <div className="card-body p-0">
                                        <Table hover width="100%" id="queue-table" className="table mb-0">
                                            <thead className="bg-light uppercase-th">
                                                <tr>
                                                    <th width="3%" scope="col" className="border-0">Sr.</th>
                                                    <th width="10%" scope="col" className="border-0">Date & Time added</th>
                                                    <th width="14%" scope="col" className="border-0">DateRange</th>
                                                    <th width="14%" scope="col" className="border-0">Email</th>
                                                    <th width="8%" scope="col" className="border-0">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state?.queues.map((queue, key) =>
                                                        <>
                                                            <tr
                                                                key={key}
                                                                data-toggle="collapse"
                                                                data-target={'.multi-collapse' + key}
                                                                aria-controls={'multiCollapseExample' + key}
                                                            >
                                                                <td><p className="mx-0 my-0">{key + 1}</p></td>
                                                                <td><p className="mx-0 my-0">{queue?.createdAt ? `${castToDefaultDateFormat(queue?.createdAt)} ${castToTimeFormat(queue?.createdAt)}` : ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" >{queue?.startDate ? `${castToDefaultDateFormat(queue?.startDate)} - ${castToDefaultDateFormat(queue?.endDate)}` : ''}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={queue.status}>{queue.email}</p></td>
                                                                <td><p className="mx-0 my-0 truncate" title={queue.status}>{queue.status}</p></td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                                {(this.state.hasMoreDomainData) && <div className="text-center">
                                    <a href='#' className='text-danger' onClick={(e) => this.listofRecords()}> {this.renderLoader()} Load more...</a>
                                </div>}
                                {(!this.state.hasMoreDomainData) && <div className="text-center">
                                    <label className='text-muted'>No more data to show.</label>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {/* data saved sweet alert */}
                    <Footer />
                </main>
            </div>
        );
    }

    render() {
        return <>{this.renderHtml()}</>;
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    login: state.login,
    response: state.response,
});

export default withAlert()(
    connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(DomainDataDownload)))
);
