import React from 'react'
import Footer from './../common/Footer.js'
import TopNav from './../common/TopNav.js'
import AnalystSideNav from './../common/AnalystSideNav.js'
import ClientSideNav from './../common/ClientSideNav.js'
import {connect} from 'react-redux'
import { Table} from 'react-bootstrap';
import debounce from 'lodash.debounce';
import requireAuth from './../hoc/requireAuth';
import {castToTimeFormat, castToDefaultDateFormat} from './../settings/index';

class MyNotifications extends React.Component {
    constructor(props){
        super(props)

        this.getNotifications = debounce(this.getNotifications.bind(this),1000);

        this.state = {
            notifications: [],
            pagination: {
                page: 1,
                nextPage:1,
                prevPage: null,
                hasNextPage: true,
                hasPrevPage: false,
                pagingCounter: 1,
                totalDocs: null,
                totalPages: null
            },
            perPage: 10,
            hasMoreNotificationData: true,
            isLoading: false
        }

        window.onscroll = debounce(() => {
            const {
                getNotifications,
              state: {
                hasMoreNotificationData
              }
            } = this;
      
            if(!hasMoreNotificationData){
              return;
            }
      
            if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
                getNotifications();
            }
          }, 100);
    }

    componentDidMount(){
        this.getNotifications();
    }

    async getNotifications(reArrangeRecords = false){

        let reqData = {
            page: this.state.pagination.nextPage,
            perPage: this.state.perPage,
        }

        if(reArrangeRecords){
            reqData.page = 1
        }

        this.setState({isLoading: true})

        await window.axios.get('/notifications',  {params: reqData})
        .then(res => {

            let notificationData = [...this.state.notifications].concat(res.data.docs)

            if(reArrangeRecords){
                notificationData = res.data.docs
            }

            this.setState(() => ({
                notifications: notificationData,
                pagination: {
                    page: res.data.page,
                    nextPage:res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreNotificationData: res.data.hasNextPage,
                isLoading: false
            }));
        });
    }

    printSideNav(){
        if(this.props.login.user?.role === 'Client'){
            return <ClientSideNav/>;
        }

        return <AnalystSideNav/>;
    }


    printNotification(){
        if(!this.state.notifications.length){
            return <div className="notification__content">
                <span className="notification__category">No Notification.</span>
            </div>
        }

        return <Table width="100%" className="table mb-0">
            <tbody>
                {
                    this.state.notifications.map((notification) =>
                        <>
                            <tr>
                                <td>
                                    <p><i className="material-icons">notifications</i> <span dangerouslySetInnerHTML={{__html: notification.notificationText}}></span></p>
                                    <span className="notification-time"><i className="material-icons">access_time</i>{castToDefaultDateFormat(notification.createdDate)} {castToTimeFormat(notification.createdDate)}</span>
                                </td>
                            </tr>
                        </>
                    )
                }
            </tbody>
        </Table>
    }

    renderHtml(){

        return <div className="row">
        
        {this.printSideNav()}
        
        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          
          <TopNav />
          
          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-4 text-sm-left mb-0">
                            <span className="text-uppercase page-subtitle">Notifications</span>
                        </div>
                    </div>
                    
                </div>
             
            </div>

            <div className="row">
                <div className="col">
                    <div className="card card-small mb-4">
                        <div className="card-body p-0 pb-3">
                            {this.printNotification()}
                        </div>
                    </div>
                </div>
            </div>
          </div>    
          <Footer />
        </main>
      </div>
    }

    render(){
        return <>
            {this.renderHtml()}
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default connect(mapStateToProps)(requireAuth(MyNotifications));