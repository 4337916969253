import React, { Component } from "react";
import Footer from "../common/Footer.js";
import ClientSideNav from "../common/ClientSideNav";
import TopNav from "../common/TopNav.js";
import * as moment from "moment";
import debounce from "lodash.debounce";
import { Table } from "react-bootstrap";
import download from "downloadjs";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import requireAuth from "../hoc/requireAuth";
import authorizeClient from "../hoc/authorizeClient";
import NotAccessablePage from "./NotAccessablePage.js";
import { getNewSortOrder } from "../helpers.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
// import { DateRangePicker } from 'react-date-range';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  castToDefaultDateFormat,
  castToServerDateFormat,
} from "./../settings/index";
import { changeClientDateFilter } from "../store/actions/filterActions";
import InfrigementStatusDropdownComponent from "./../components/InfrigementStatusDropdownComponent";

class DomainDashboard extends Component {
  constructor(props) {
    super(props);
    this.getDomains = debounce(this.getDomains.bind(this), 1000);
    this.changeSorting = this.changeSorting.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      sorting: {
        sortBy: "",
        sortIconClass: "sort-icon-nutral",
        sortOrder: "",
      },
      authorised: true,
      domains: [],
      dropdownCount: false,
      uniqueDomain: [],

      selectedBrand: null,
      visibleRecords: "",
      dropdownCount: false,
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      hasMoreDomainData: true,
      isLoading: false,
      locale: { format: "DD/MM/YYYY" },
    };

    window.onscroll = debounce(async () => {
      const {
        getDomains,
        state: {
          hasMoreDomainData,
          dropdownCount
        }
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      // Calculate the scroll position
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;

      // Check if scrolled to the bottom
      if (scrollPosition === document.documentElement.offsetHeight) {
        if (dropdownCount) {
          // Set dropdownCount to false to fetch all data
          this.setState({ dropdownCount: false });
        } else {
          // Paginate the data
          await getDomains(false, true);
        }
      }
    }, 100);
  }

  async downloadExcel() {
    const options = {
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "Content-disposition": 'attachment; filename=domain.xlsx"',
      },
    };

    let reqData = {
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      module:'domain',
      ...this.props?.clientDomainFilter,
    };

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id;
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }

    await window.axios
      .get(
        "/domain/download-xls",
        {
          params: reqData,
          responseType: "blob",
          timeout: 30000,
        },
        options
      )
      .then((res) => {
        download(res.data, "domain.xls");
      });
  }

  changeSorting(sortBy) {
    this.setState((prevState) => {
      const { sortOrder, classPostFix } = getNewSortOrder(prevState);

      return {
        sorting: {
          sortBy,
          sortIconClass: `sort-icon-${classPostFix}`,
          sortOrder,
        },
      };
    });
  }

  async getDomains(reArrangeRecords = false, isPerPage = false) {
    const options = {
      headers: { "Content-Type": "application/json" },
    };

    let reqData = {
      dropdownCount: this.state.dropdownCount,
      sortBy: this.state.sorting.sortBy,
      sortOrder: this.state.sorting.sortOrder,
      // page: this.state.pagination.nextPage,
      page: this.state.pagination.page,
      perPage: isPerPage ? this.state.visibleRecords + 20 : this.state.perPage,
      startDate: castToServerDateFormat(this.props.clientDateFilter.startDate),
      endDate: castToServerDateFormat(this.props.clientDateFilter.endDate),
      ...this.props?.clientDomainFilter,
    };

    if (reArrangeRecords) {
      reqData.page = 1;
    }

    if (this.props.selectedBrand?.id) {
      reqData.brand = this.props.selectedBrand?.id;
    }

    if (this.props.selectedCompany?.id) {
      reqData.clientCompanyId = this.props.selectedCompany?.id;
    }

    if (this.props.location?.state?.notification) {
      reqData.notification = this.props.location?.state?.notification;
    }

    reqData.module = 'domain'

    this.setState({ isLoading: true });

    await window.axios
      .get("/domain", { params: reqData }, options)
      .then((res) => {
        let domainData = res.data.docs;

        // if (reqData.dropdownCount) {
        domainData = domainData;
        // } else {
        //   domainData = [...this.state.domains].concat(domainData);
        // }

        if (reArrangeRecords) {
          domainData = res.data.docs;
        }

        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination, // Spread the existing pagination state
            // page: res.data.page, // Commenting this out will retain the existing value
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          domains: domainData,
          hasMoreDomainData: res.data.hasNextPage, isLoading: false
        }));
      })
      .catch(() => { })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.sorting.sortBy !== this.state.sorting.sortBy ||
      prevState.sorting.sortOrder !== this.state.sorting.sortOrder
    ) {
      this.getDomains(true);
    }

    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      this.getDomains(true);
    }

    if (prevProps.clientDomainFilter !== this.props.clientDomainFilter) {
      this.getDomains(true);
    }

    if (prevProps.clientDateFilter !== this.props.clientDateFilter) {
      this.getDomains(true);
    }

    if (
      prevProps.location?.state?.notification !==
      this.props.location?.state?.notification
    ) {
      this.getDomains(true);
    }

    if (prevState.domains.length !== this.state.domains.length) {
      this.setState({ visibleRecords: this.state.domains.length }, () => { });
    }
  }

  componentDidMount() {
    this.getDomains(null, false);
    this.setState({ visibleRecords: this.state.domains.length }, () => { });
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: this.state.locale,
    };
  }

  handleCallback = async (event, picker) => {
    this.props.dispatch(
      changeClientDateFilter({
        startDate: picker.startDate,
        endDate: picker.endDate,
      })
    );
  };

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000} //3 secs
          />
        </div>
      );
    }
  }

  handleSelect(countValue) {
    this.setState((prevState) => ({
      dropdownCount: countValue,
      pagination: {
        ...prevState.pagination,
        page: 1,
      },
      // perPage: countValue,
    }));
    this.getDomains();
  }

  printPaginationInfo() {
    let totalDocs = this.state.pagination.totalDocs
      ? this.state.pagination.totalDocs
      : 0;
    let visibleRecords = 0;

    if (totalDocs) {
      visibleRecords =
        totalDocs > this.state.perPage * this.state.pagination.page
          ? this.state.perPage * this.state.pagination.page
          : totalDocs;
    }

    // return (
    //   <>
    //     <br />
    //     <span className="text-uppercase page-subtitle">
    //       Showing <strong>{visibleRecords}</strong> records of{" "}
    //       <strong>{totalDocs}</strong>
    //     </span>
    //   </>
    // );

    if (totalDocs) {
      visibleRecords =
        totalDocs > this.state.perPage * this.state.pagination.page
          ? this.state.perPage * this.state.pagination.page
          : totalDocs;
    }

    return (
      <>
        <div className="d-flex align-items-center">
          <span className="text-uppercase page-subtitle">
            Showing <strong>{this.state.visibleRecords}</strong> records of{" "}
            <strong>{totalDocs}</strong>
          </span>
          <div className="dropdown ml-3">
            <button
              className="btn btn-secondary dropdown-toggle custom-dropdown-button"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                backgroundColor: "white",
                width: "130px",
                color: "black", // Add the color property for text visibility
                height: "20px", // Adjust the height as per your requirements
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                lineHeight: "1",
              }}
            >
              {/* {this.state.dropdownRecordsPlug
                ? this.state.dropdownRecords.length
                : visibleRecords} */}
              {this.state.visibleRecords} Records
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(10)}
              >
                Show 10 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(20)}
              >
                Show 20 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(50)}
              >
                Show 50 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(100)}
              >
                Show 100 records
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => this.handleSelect(20000)}
              >
                Show All records
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderHtml() {
    if (!this.props.selectedBrand?.platforms?.domain) {
      return <NotAccessablePage />;
    }

    return (
      <div className="row">
        <ClientSideNav />

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-3 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      Domain Dashboard
                    </span>
                    {this.printPaginationInfo()}
                  </div>
                  <div className="col-2 text-sm-right mb-0">
                    {this.renderLoader()}
                  </div>
                  <div className="col-6 text-sm-right mb-0">
                    <span className="uppercase-text">
                      <strong>Reporting Date: </strong>
                    </span>
                    <DateRangePicker
                      onApply={this.handleCallback}
                      initialSettings={this.getDateFilters()}
                      alwaysShowCalendars={true}
                    >
                      <input className="from-control" />
                    </DateRangePicker>
                  </div>
                  <div className="col-1 text-sm-right mb-0">
                    <a
                      title="Download as xls"
                      href="#"
                      className="display-6"
                      onClick={() => this.downloadExcel()}
                    >
                      <i className="material-icons text-primary">
                        file_download
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div id="scrollableDiv" style={{ overflow: "hidden" }}>
              <div className="row">
                <div className="col">
                  <div className="card card-small mb-4">
                    <div className="card-body p-0 pb-3">
                      <Table className="fixedElement">
                        <thead className="uppercase-th">
                          <tr>
                            <th scope="col" width="10%" className="border-0">
                              {" "}
                              #
                            </th>
                            <th scope="col" width="10%" className="border-0">
                              {" "}
                              IMAGE
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("domain")}
                              className={`border-0 ${this.state.sorting.sortBy === "domain"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              DOMAIN
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("tld")}
                              className={`border-0 ${this.state.sorting.sortBy === "tld"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              TLD
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) =>
                                this.changeSorting("reportingDate")
                              }
                              className={`border-0 ${this.state.sorting.sortBy === "reportingDate"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              REPORTING DATE
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("priority")}
                              className={`border-0 ${this.state.sorting.sortBy === "priority"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              PRIORITY
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("status")}
                              className={`border-0 ${this.state.sorting.sortBy === "status"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              STATUS
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      {/* table here */}
                      <Table>
                        <thead className="uppercase-th">
                          <tr>
                            <th scope="col" width="10%" className="border-0">
                              {" "}
                              #
                            </th>
                            <th scope="col" width="10%" className="border-0">
                              {" "}
                              IMAGE
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("domain")}
                              className={`border-0 ${this.state.sorting.sortBy === "domain"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              DOMAIN
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("tld")}
                              className={`border-0 ${this.state.sorting.sortBy === "tld"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              TLD
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) =>
                                this.changeSorting("reportingDate")
                              }
                              className={`border-0 ${this.state.sorting.sortBy === "reportingDate"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              REPORTING DATE
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("priority")}
                              className={`border-0 ${this.state.sorting.sortBy === "priority"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              PRIORITY
                            </th>
                            <th
                              scope="col"
                              width="10%"
                              onClick={(e) => this.changeSorting("status")}
                              className={`border-0 ${this.state.sorting.sortBy === "status"
                                  ? this.state.sorting.sortIconClass +
                                  " sort-icon"
                                  : "sort-icon sort-icon-nutral"
                                }`}
                            >
                              STATUS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.domains.map((domain, key) => (
                            <>
                              <tr
                                key={key}
                                data-toggle="collapse"
                                data-target={".multi-collapse" + key}
                                aria-controls={"multiCollapseExample" + key}
                              >
                                <td>{key + 1}</td>
                                <td>
                                  <img
                                    className="user-avatar"
                                    width="50"
                                    src={domain.imageUrl}
                                    alt="User Avatar"
                                  />
                                </td>
                                <td>
                                  <a
                                    base
                                    className="bind-width"
                                    title={domain.domain}
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`//${domain.domain}`}
                                  >
                                    {domain.domain}
                                  </a>{" "}
                                </td>
                                <td>
                                  <p title={domain.tld} className="bind-width">
                                    {domain.tld}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    title={castToDefaultDateFormat(
                                      domain.reportingDate
                                    )}
                                    className="bind-width"
                                  >
                                    {castToDefaultDateFormat(
                                      domain.reportingDate
                                    )}
                                  </p>
                                </td>
                                <td>{domain.priority}</td>
                                <td>
                                  <InfrigementStatusDropdownComponent
                                    selectedInfrigemnt={domain}
                                    selectedInfrigemntIndex={key}
                                    infrigementType="domain"
                                  />
                                </td>
                              </tr>
                              <tr
                                className={"collapse multi-collapse" + key}
                                id={"multiCollapseExample" + key}
                              >
                                <td colSpan={7}>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <img
                                        data-toggle="modal"
                                        width="200"
                                        data-target={"#myModal" + key}
                                        src={domain.imageUrl}
                                        className="mr-2"
                                        alt="Cinque Terre"
                                      />
                                    </div>
                                    <div className="col-md-9">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Brand
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.brand.brandName}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Last Modify Date
                                            </span>
                                            <input
                                              type="text"
                                              value={castToDefaultDateFormat(
                                                domain.lastMofifiedDate
                                              )}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Expiry Date
                                            </span>
                                            <input
                                              type="text"
                                              value={castToDefaultDateFormat(
                                                domain.expiryDate
                                              )}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Registration Date
                                            </span>
                                            <input
                                              type="text"
                                              value={castToDefaultDateFormat(
                                                domain.registrationDate
                                              )}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Registrar
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.registrar}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <h5>Registrant</h5>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              IP
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.ipAddress}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Hosting
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.hosting}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Name
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.name}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Org
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.org}
                                              disabled
                                              className="form-control"
                                              placeholder=""
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              State
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.state}
                                              disabled
                                              className="form-control"
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Country
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.country}
                                              disabled
                                              className="form-control"
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <span className="text-muted d-block mb-2">
                                              Email Add
                                            </span>
                                            <input
                                              type="text"
                                              value={domain.emailAddress}
                                              disabled
                                              className="form-control"
                                              aria-label="Username"
                                              aria-describedby="basic-addon1"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <div className="modal" id={"myModal" + key}>
                                <div className="modal-dialog modal-800">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h4 className="modal-title">Image</h4>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                      >
                                        &times;
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={domain.imageUrl}
                                        width="100%"
                                        className="img-responsive"
                                        alt="Cinque Terre"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  clientDomainFilter: state.clientDomainFilter,
  clientDateFilter: state.clientDateFilter,
});

export default connect(mapStateToProps)(
  authorizeClient(requireAuth(DomainDashboard))
);
