import React, { Component } from 'react'
import Footer from '../../common/Footer.js'
import AdminSideNav from '../../common/AdminSideNav.js'
import TopNav from '../../common/TopNav.js'
import { Button, Modal, Form, Table, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import Select from 'react-select'
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeAdmin from '../../hoc/authorizeAdmin'
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import 'sweetalert2/src/sweetalert2.scss'
import { castToServerDateFormat } from '../../settings/index';
import Loader from "react-loader-spinner";
import { withAlert } from 'react-alert';
import './../../itemsAlignment.css';
import PaginationComponentV1 from '../../common/PaginationComponentV1';

class ListBrand extends Component {
    constructor(props) {
        super(props)

        this.state = {
            availableCategoryFilters: [],
            availableCompanies: [],
            filteredCategory: [],
            companyFilter: null,
            categoryFilter: null,
            statusFilter: null,
            filteredCompany: [],
            selectedBrand: null,
            status: true,
            showModal: false,
            showAddPlatformModal: false,
            authorised: true,
            brands: [],
            errorMessage: null,
            brandName: '',
            searchTerm: '',
            category: '',
            selectedCompany: null,
            companies: [],
            platform: {},
            pagination: {
                totalDocs: null,
                totalPages: null,
                page: 1,
                pagingCounter: 1,
                hasPrevPage: false,
                hasNextPage: true,
                prevPage: null,
                nextPage: 1
            },
            perPage: 20,
            sortBy: '_id',       // column to sort by
            sortDirection: -1, // sort direction (asc or desc)
            isLoading: false,
            hasMoreDomainData: true,
            search: '',
            selectedPlatform: '',
            selectedStartDate: '',
            selectedEndDate: '',
            selectedReportingPeriod: '',
            availablePlatforms: [
                { label: "domain", value: "domain" },
                { label: "website", value: "website" },
                { label: "socialMedia", value: "socialMedia" },
                { label: "eCommerce", value: "eCommerce" },
                { label: "appStore", value: "appStore" },
                { label: "tmch", value: "tmch" }
            ]
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenAddPlatformModal = this.handleOpenAddPlatformModal.bind(this);
        this.handleCloseAddPlatformModal = this.handleCloseAddPlatformModal.bind(this);
        this.handleAddNewPlatform = this.handleAddNewPlatform.bind(this);
        this.saveBrand = this.saveBrand.bind(this);
        this.listAllBrands = this.listAllBrands.bind(this)
        this.handleLimit = this.handleLimit.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.inputSearchBox = this.inputSearchBox.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
        this.myPageChange = this.myPageChange.bind(this);
    }

    myPageChange(newPage) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: newPage
            }
        });
    }

    renderSaveOrUpdateButton() {
        if (this.state.selectedBrand) {
            return <Button onClick={() => this.saveBrand(false)} variant="primary">Update</Button>
        } else {
            return <Button onClick={() => this.saveBrand(true)} variant="primary">Save</Button>
        }
    }

    handleSearchButton() {
        this.listAllBrands(true);
    };

    inputSearchBox(search) {
        this.setState({ search });
    };

    sortTable = (column) => {
        let { sortBy, sortDirection } = this.state;

        if (sortBy === column) {
            sortDirection = sortDirection === 1 ? -1 : 1;
        } else {
            sortBy = column;
            sortDirection = 1;
        }

        // Update state
        this.setState({ sortBy, sortDirection });
    };

    handleLimit(e) {
        this.setState({ perPage: e })
        this.setState(prevState => ({
            pagination: {
                ...prevState.pagination,
                page: 1
            }
        }));

    }

    renderLoader() {
        if (this.state.isLoading) {
            return <div className="text-center">
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={200}
                    timeout={5000} //3 secs
                />
            </div>
        }
    }

    async saveBrand(update) {
        let reqParam = {
            company: this.state.selectedCompany?.value,
            brandName: this.state.brandName,
            searchTerm: this.state.searchTerm,
            platform: this.state.platform,
            category: this.state.category,
            status: this.state.status
        }

        let endPoint = '/brand';
        if (this.state.selectedBrand) {
            reqParam.brandId = this.state.selectedBrand;
            endPoint += `/edit`;
        }

        await this.setState(() => {
            return {
                errorMessage: null
            }
        })

        await window.axios.post(`${endPoint}`, reqParam)
            .then((response) => {
                this.props.alert.show(response.data.message)
                this.handleCloseModal()
                this.listAllBrands(update)

            })
            .catch((error) => {
                let statusCode = error.response.status
                let errors = error.response.data.errors
                let errorMessage = error.response.data.message

                if (statusCode === 401 || statusCode === 400 || statusCode === 500) {
                    this.props.alert.show(`Oops ${errorMessage}`, { type: 'error' })

                    return
                }

                if (statusCode === 422) {
                    let errorMessageObj = {}

                    for (let errorData of errors) {
                        let errorKeyName = Object.keys(errorData)[0]
                        errorMessageObj[errorKeyName] = errorData[errorKeyName]
                    }

                    this.setState(() => {
                        return { errorMessage: errorMessageObj }
                    })

                    this.props.alert.show(`Oops ${errorMessage}`, { type: 'error' })

                    return
                }
                this.props.alert.show(error.response.data.message, { type: 'error' })
            })

    }



    async removePlatform(platformName) {
        await window.axios.post(`/brand/delete-platform/${this.state.selectedBrand}/${platformName}`)
            .then(async response => {
                let platform = this.state.platform;
                delete platform[platformName];

                await this.setState(() => ({
                    platform
                }))
            })
            .catch(error => {
                this.props.alert.show(error.response.data.message, { type: 'error' })
            })
    }

    async handleAddNewPlatform() {

        let selectedPlatform = this.state.selectedPlatform?.value

        if (!selectedPlatform) {
            alert('please select platform')
            return;
        }

        let platformData = {
            startDate: this.state.selectedStartDate,
            endDate: this.state.selectedEndDate,
            reportingPeriod: this.state.selectedReportingPeriod
        }

        if (this.state.platform[selectedPlatform]) {
            alert('platform added already')
            return;
        }

        let platform = this.state.platform

        platform[selectedPlatform] = platformData

        await this.setState(() => ({ platform: platform }))
    }


    renderPlatforms() {
        return <>
            <div style={{ marginTop: 10 }} className="row">
                <div style={{ fontWeight: 500 }} className="col-2">Platform</div>
                <div style={{ fontWeight: 500 }} className="col-2">Start Date</div>
                <div style={{ fontWeight: 500 }} className="col-2">End Date</div>
                <div style={{ fontWeight: 500 }} className="col-2">Reporting Period</div>
            </div>
            {this.state.platform ? Object.keys(this.state.platform).map((key) =>
                <>
                    <div style={{ marginTop: 10 }} className="row">
                        <div className="col-2">
                            <p>{key}</p>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].startDate}</label>
                            </Form.Group>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].endDate}</label>
                            </Form.Group>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>{this.state.platform[key].reportingPeriod}</label>
                            </Form.Group>
                        </div>
                        <div className="col-2">
                            <Form.Group className="mb-3" >
                                <label><td className="col-2"> <a className="text-danger" onClick={(e) => this.removePlatform(key)} href="#"><i className="fa fa-trash"></i></a>
                                </td></label>
                            </Form.Group>
                        </div>
                    </div>
                </>
            ) : ''}
        </>
    }

    handleOpenModal() {
        this.setState((prevState) => {
            return {
                showModal: true
            }
        });
    }

    async handleCloseAddPlatformModal() {
        this.setState(() => ({ showAddPlatformModal: false }))
    }

    async handleOpenAddPlatformModal() {
        this.setState(() => ({ showAddPlatformModal: true }))
    }

    async removeBrand(brand) {
        await window.axios.delete(`/brand/delete/${brand?.id}`)
            .then((response) => {
                this.listAllBrands(true)
                this.props.alert.show(response.data.message)
            })
            .catch((error) => {
                this.props.alert.show(error.response.data, { type: 'error' })
            })
    }

    async showEditPopup(brand) {
        await this.setState((prevState) => {
            return {
                selectedBrand: brand.id,
                status: brand.status ? true : false,
                showModal: true,
                brandName: brand.brandName,
                searchTerm: brand.searchTerm,
                category: brand.category,
                platform: brand.platform ? brand.platform : {},
                selectedCompany: brand.company ? { label: brand.company.name, value: brand.company._id } : null
            }
        }
        )
    }

    handleCloseModal() {
        this.setState((prevState) => {
            return {
                selectedBrand: null,
                showModal: false,
                brandName: '',
                searchTerm: '',
                category: '',
                platform: {},
                selectedCompany: null,
                status: false
            }
        });
    }

    async listAllBrands(reArrangeRecords = false) {
        let reqData = {
            page: this.state.pagination.page,
            perPage: this.state.perPage,
        }

        reqData.sortBy = this.state.sortBy;
        reqData.sortDirection = this.state.sortDirection;
        if (reArrangeRecords) {
            reqData.page = 1
        }
        reqData.search = this.state.search;

        this.setState(() => ({ isLoading: true }));

        await window.axios.get('/brand/v1/allRecords', {
            params: reqData
        }).then(res => {
            this.setState(() => ({
                brands: res.data.docs,
                isLoading: false,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreDomainData: res.data.hasNextPage
            }));
        });
    }

    async changeInputBox(e, column) {

        let state = {}
        state[column] = e.target.value
        await this.setState(() => {
            return state
        })
    }

    componentDidMount() {
        this.listAllBrands()
        this.listAllCompanies()
        this.fetchAllUniqueCategory();
        this.getCompanies();
    }

    componentDidUpdate(prevProps, prevState) {
        // This runs when the component updates
        // You can check if specific state or props changed and perform side effects accordingly
        if (this.state.pagination.page !== prevState.pagination.page || this.state.perPage !== prevState.perPage || this.state.sortBy !== prevState.sortBy || this.state.sortDirection !== prevState.sortDirection) {
            this.listAllBrands();
        }
    }

    async getCompanies() {
        await window.axios.get('/company', {
            params: {
                perPage: 100
            }
        }).then(res => {
            this.setState({
                availableCompanies: res.data.docs
            })
        });
    }

    async listAllCompanies() {

        await window.axios.get('/company', {
            params: {
                perPage: 100
            }
        }).then(res => {

            let companies = []

            res?.data?.docs.map((company) => (
                companies.push({
                    label: company.name,
                    value: company._id
                })
            ))

            this.setState({ companies: companies });
        });
    }

    showCompanySelectBox() {
        return <div className="col-6">
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Company</Form.Label>
                <Select name="company" value={this.state.selectedCompany} onChange={(e) => this.changeCompany(e)} options={this.state.companies} />
                <span className="form-text-error-text">{this.state.errorMessage?.company}</span>
            </Form.Group>
        </div>
    }

    async changeCompany(e) {
        await this.setState(() => {
            return {
                selectedCompany: e
            }
        })
    }

    async changePlatform(e) {
        await this.setState(() => ({ selectedPlatform: e }))
    }

    printPlatform(key = '', brand) {

        const platform = brand.platform ? brand.platform[key] : null;

        if (!platform) {
            return null;
        }

        return <div className="row">
            <div className="col-md-12">
                <h6 className="capitalize-text">{key}</h6>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">Start Date</span>
                    <input type="text" value={castToServerDateFormat(platform.startDate)} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">End Date</span>
                    <input type="text" value={castToServerDateFormat(platform.endDate)} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <span className="text-muted d-block mb-2">Reporting Period</span>
                    <input type="text" value={platform.reportingPeriod} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                </div>
            </div>
        </div>;
    }

    handleStatusChange = async (e) => {
        await this.setState(() => ({
            status: e.target.checked
        }))
    }

    renderBrands() {
        return this.state.brands.map((brand, brandKey) =>
            <>
                <tr
                    key={brandKey}
                    data-toggle="collapse"
                    data-target={'.multi-collapse' + brandKey}
                    aria-controls={'multiCollapseExample' + brandKey}
                >
                    <td><p className="mx-0 my-0">{this.state.pagination.pagingCounter + brandKey}</p></td>
                    <td><p className="mx-0 my-0">{brand.brandName}</p></td>
                    <td><p className="mx-0 my-0">{brand.searchTerm}</p></td>
                    <td><p className="mx-0 my-0 truncate" title={brand.company.legalName}>{brand.company.name}</p></td>
                    <td><p className="mx-0 my-0 truncate" title={brand?.category}>{brand?.category}</p></td>
                    <td><span className={brand.status ? 'text-success' : 'text-danger'}>{brand.status ? 'Enabled' : 'Disabled'}</span></td>
                    <td>
                        <a className="text-danger" onClick={(e) => this.removeBrand(brand)} href="#"><i className="fa fa-trash"></i></a>
                        <a className="text-primary mx-1" onClick={(e) => this.showEditPopup(brand)} href="#"><i className="fa fa-edit"></i></a>
                    </td>
                </tr>
                <tr className={'collapse multi-collapse' + brandKey} id={'multiCollapseExample' + brandKey}>
                    {(brand.platform !== null && brand.platform !== undefined) && <td colSpan="4">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5>Platform</h5>
                                    </div>
                                </div>
                                {this.printPlatform('domain', brand)}
                                {this.printPlatform('website', brand)}
                                {this.printPlatform('socialMedia', brand)}
                                {this.printPlatform('eCommerce', brand)}
                                {this.printPlatform('appStore', brand)}
                                {this.printPlatform('tmch', brand)}
                            </div>
                        </div>
                    </td>}
                </tr>
            </>
        )
    }

    async handleCompanyFilterChange(e) {
        await this.setState(() => ({
            filteredCompany: e.target.value
        }));
    }


    async handleCategoryFilterChange(e) {
        await this.setState(() => ({
            categoryFilter: e.target.value
        }));
    }



    async handleStatusFilterChange(e) {
        await this.setState(() => ({
            statusFilter: e.target.value
        }));
    }

    async fetchAllUniqueCategory() {
        await window.axios.get('/brand/fetch-unique-categories').then(async (response) => {
            await this.setState(() => ({
                availableCategoryFilters: response.data
            }
            ))
        }).catch(() => {
            console.log('error')
        })
    }

    renderHtml() {
        return <div className="row">

            <AdminSideNav />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="d-flex align-items-center sticky-div-brand justify-content-between mb-2 mt-2">
                        <div className="d-flex gap-4 align-items-center">
                            <InputGroup className="">
                                <FormControl
                                    placeholder=""
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    value={this.state.search}
                                    onChange={(e) => { this.inputSearchBox(e.target.value) }}
                                    style={{ width: '200px' }}
                                />
                            </InputGroup>
                            <Button className='btn-success' onClick={this.handleSearchButton}>Search</Button>
                            <Button onClick={this.handleOpenModal}>Add Brand</Button>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <PaginationComponentV1
                                totalRecords={this.state.pagination.totalDocs}
                                itemsPerPage={this.state.perPage}
                                currentPage={this.state.pagination.page}
                                onPageChange={this.myPageChange}
                                displayPagesCount={8} />

                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <Dropdown style={{ width: '80px' }} onSelect={this.handleLimit}>
                                <Dropdown.Toggle variant="danger" id="limit">
                                    {this.state.perPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="10" active={this.state.perPage == 10}>10</Dropdown.Item>
                                    <Dropdown.Item eventKey="20" active={this.state.perPage == 20}>20</Dropdown.Item>
                                    <Dropdown.Item eventKey="50" active={this.state.perPage == 50}>50</Dropdown.Item>
                                    <Dropdown.Item eventKey="100" active={this.state.perPage == 100}>100</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <span>
                                {this.state.pagination.totalDocs} records
                            </span>
                        </div>
                    </div>



                    <div className="row">
                        <div className="col">
                            <div className="card card-small mb-4">

                                <div className="card-body p-0">
                                    <Table width="100%" id="brand-table" className="table mb-0">
                                        <thead className="bg-light uppercase-th">
                                            <tr key="row_header">
                                                <th width="3%" scope="col" className="border-0" onClick={() => this.sortTable('_id')}>Sr. {this.state.sortBy === '_id' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('brandName')}>Brand Name {this.state.sortBy === 'brandName' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('searchTerm')}>Search Term {this.state.sortBy === 'searchTerm' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" >Company</th>
                                                {/* <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('company.name')}>Company {this.state.sortBy === 'company.name' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th> */}
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('category')}>Category {this.state.sortBy === 'category' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('status')}>Status {this.state.sortBy === 'status' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderBrands()}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    }

    render() {
        return <>
            {this.renderHtml()}
            {/* add new platform modal start */}
            <Modal
                className="add-brand-platform"
                size="lg"
                id="modal-1"
                show={this.state.showAddPlatformModal}
                onHide={this.handleCloseAddPlatformModal}
                backdrop="static"
                backdropClassName="add-brand-platform"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d32f2f' }}>Add new platform</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Platform</Form.Label>
                                    <Select name="company" onChange={(e) => this.changePlatform(e)} options={this.state.availablePlatforms} />
                                    <span className="form-text-error-text">{this.state.errorMessage?.company}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control name="searchTerm" onChange={(e) => this.changeInputBox(e, 'selectedStartDate')} value={this.state.legalName} type="date" placeholder="Search Term" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.searchTerm}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control name="searchTerm" onChange={(e) => this.changeInputBox(e, 'selectedEndDate')} value={this.state.legalName} type="date" placeholder="Search Term" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.company}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Reporting Period</Form.Label>
                                    <Form.Control name="searchTerm" onChange={(e) => this.changeInputBox(e, 'selectedReportingPeriod')} value={this.state.legalName} type="text" placeholder="Search Term" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.searchTerm}</span>
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseAddPlatformModal}>
                        Close
                    </Button>
                    <Button onClick={this.handleAddNewPlatform} variant="primary">Add New Platform</Button>
                </Modal.Footer>
            </Modal>
            {/* add new platform modal ends */}
            <Modal
                size="xl"
                show={this.state.showModal}
                onHide={this.handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d32f2f' }}>Brand Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Brand Name</Form.Label>
                                    <Form.Control name="brandName" onChange={(e) => this.changeInputBox(e, 'brandName')} value={this.state.brandName} type="text" placeholder="Enter Brand Name" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.brandName}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control name="category" onChange={(e) => this.changeInputBox(e, 'category')} value={this.state.category} type="text" placeholder="Enter Category Name" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.category}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Search Term</Form.Label>
                                    <Form.Control name="searchTerm" onChange={(e) => this.changeInputBox(e, 'searchTerm')} value={this.state.searchTerm} type="text" placeholder="Search Term" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.searchTerm}</span>
                                </Form.Group>
                            </div>
                            {this.showCompanySelectBox()}

                            <div className='col-6'>

                                <div className="form-check form-switch">
                                    <input style={{ pointerEvents: "auto", cursor: '' }} onClick={(e) => this.handleStatusChange(e)} checked={this.state.status ? true : false} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Status</label>
                                </div>
                            </div>
                        </div>



                        <div className="row">
                            <div className="col-6 text-left mb-0">
                                <span style={{ color: '#ff6f00' }} className="text-uppercase page-subtitle">Platforms <span className="form-text-error-text">{this.state.errorMessage?.platform ? `(${this.state.errorMessage?.platform})` : ''}</span></span>
                            </div>
                            <div className="col-6 text-right mb-0">
                                <a style={{ color: '#ff6f00' }} href="javascript:;" onClick={this.handleOpenAddPlatformModal}>Add Platform</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-left mb-0">
                                {this.renderPlatforms()}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal}>
                        Close
                    </Button>
                    {this.renderSaveOrUpdateButton()}
                </Modal.Footer>
            </Modal>
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default withAlert()(connect(mapStateToProps)(authorizeAdmin(requireAuth(ListBrand))));