import React from "react";
import Footer from "../../common/Footer.js";
import AdminSideNav from "../../common/AdminSideNav.js";
import AnalystSideNav from "../../common/AnalystSideNav.js";
import UnauthorisedPage from "../Unauthorised";
import TopNav from "../../common/TopNav.js";
import readXlsxFile from "read-excel-file";
import { connect } from "react-redux";
import Select from "react-select";
import requireAuth from "../../hoc/requireAuth";
import authorizeAnalystOrAdmin from "../../hoc/authorizeAnalystOrAdmin";
import DateRangePicker from "react-bootstrap-daterangepicker";
import * as moment from "moment";
import "sweetalert/dist/sweetalert.css";
import { withAlert } from "react-alert";
import "../../assets/css/data-upload.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import debounce from "lodash.debounce";
import { Table } from "react-bootstrap";
import {
  castToDefaultDateFormat,
  castToTimeFormat,
} from "../../settings/index";
import Loader from "react-loader-spinner";

class Scrapper1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      platform: "",
      showDataSavedAlert: false,
      selectedOption: null,
      companies: [],
      brands: [],
      emailId: "",
      startDate: moment().format("DD/MM/YYYY"),
      endDate: moment().format("DD/MM/YYYY"),
      company: "",
      brand: "",
      filename: "",
      files: [],
      selectedOption: "",
      formSubmitted: false,
      fileError: false,
      selectedFile: null,
      receivedData: [],
      dataSaveError: {},
      locale: { format: "DD/MM/YYYY" },
      queues: [],
      pagination: {
        page: 1,
        nextPage: 2,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      perPage: 10,
      isLoading: false,
      hasMoreDomainData: true,
      selectedKey: "",
    };
    this.fileInputRef = React.createRef();
    this.listofRecords = debounce(this.listofRecords.bind(this), 500);
    window.onscroll = debounce(() => {
      const {
        listofRecords,
        state: { hasMoreDomainData },
      } = this;

      if (!hasMoreDomainData) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        listofRecords();
      }
    }, 100);
  }

  changeInputEvent = () => {
    const input = document.getElementById("inputfile");
    input.addEventListener("change", () => {
      this.handleSingleImageUpload(input.files[0]);

      //});
    });
  };

  handleSingleImageUpload = async (files) => {
    let formData = new FormData();
    formData.append("files", files);

    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "true",
      },
    };

    await window.axios
      .post("/upload-images-to-s3", formData, options)
      .then((res) => {
        let awsResponse = res.data.Data[0];
        this.setState({ excelData: awsResponse.Location });
      });
  };

  updateImageInputEvent(id, rowKey, domainKey) {
    const input = document.getElementById(id);

    if (!input) {
      return;
    }

    this.handleSingleImageUpload(input.files, rowKey, domainKey);
  }

  saveScrapper1Data = async (event) => {
    event.preventDefault();
    if (this.state.platform === "") {
      this.setState({ formSubmitted: true });
     
    } else {
      const { filename, platform, emailId, excelData } = this.state;
      let url = `/scraped/scrapper1/postdata`;
     
      let params = {
        filename: filename,
        platform: platform,
        email: emailId,
        location: excelData,
      };

      window.axios
        .post(url, params)
        .then((response) => {
          this.props.alert.show(response.data.message);
          
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.setState({
              errorMessage: error.response.data?.errors,
            });
          }
        });
      this.setState({
        platform: "",
        formSubmitted: false,
        filename: "",
      });
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
        this.listofRecords(true);
      }

    
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // this.state.listofRecords();
    //this.getEmailId();
    if (prevState.company !== this.state.company) {
      this.getBrands();
      this.getEmailId();
    }

    if (
      this.state.brand &&
      this.state.brand !== prevState.brand // Check if the brand has changed
    ) {
      let selectedBrand = this.state.brands.filter(
        (brand) => brand.id === this.state.brand
      );

      if (selectedBrand.length) {
        let platform = selectedBrand[0].platform;

        this.setState(() => ({
          availablePlatforms: platform,
        }));
      } else {
        this.setState(() => ({
          availablePlatforms: null,
        }));
      }
    }
  }

  componentDidMount() {
    this.changeInputEvent();
    this.listofRecords(true);
    this.getEmailId();
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800,
        },
      })
      .then((res) => {
        res.data.docs.push({ brandName: "All" });
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  async getEmailId() {
    await window.axios
      .get("/users/getEmailId", {
        params: {
          page: 1,
          perPage: 400,
        },
      })
      .then((res) => {
        this.setState({
          emailId: res.data.data,
        });
      });
  }  async postQueueConfirmation() {
    if (
      this.state.company === "" ||
      this.state.brand === "" ||
      this.state.platform === ""
    ) {
      return;
    }
    const username = this.props.login.user.username;
    const options = {
      headers: { "Content-Type": "application/json" },
    };
    let brandName,
      companyName = "";
    let noOfDocument = 0;

    await window.axios
      .get(
        "/queue/confirmFetchRecords",
        {
          params: {
            company: this.state.company,

            startDate: this.state.startDate,
            endDate: this.state.endDate,
            platform: this.state.platform,
            email: this.state.emailId,
            username,
          },
        },
        options
      )
      .then((res) => {
        brandName = res.data.brandName || "";
        companyName = res.data.companyName || "";
        noOfDocument = res.data.noOfDocument || 0;
        Swal.fire({
          title: `This query will fetch ${res.data.noOfDocument} records`,
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: noOfDocument > 0,
          confirmButtonText: "Accept",
          denyButtonText: `Cancel`,
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            await window.axios
              .post("/", {
                // company: companyName,
                brand: brandName,
                dateRange: `${this.state.startDate} - ${this.state.endDate}`,
                platform: this.state.platform,
                // brandId: this.state.brand,
                // companyId: this.state.company,
                email: this.state.emailId,
                // username,
                numOfRecords: noOfDocument,
              })
              .then((res) => {
                this.listofRecords(true);
                Swal.fire("Saved!", "", "success");
              });
          }
        });
      })
      .catch(function (error) {
        alert(error.response.data.message);
      });
  }

  async listofRecords(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
    };

    if (reArrangeRecords) {
      reqData.page = 1;
    }

    this.setState(() => ({ isLoading: true }));
    let url = `/scraped/scrapper1/getdata`;
    await window.axios
      .get(url, {
        params: reqData,
      })
      .then((res) => {
        let queueData = [...this.state.queues].concat(res.data.docs);
         
        if (reArrangeRecords) {
          queueData = res.data.docs;
        }

        this.setState(() => ({
          queues: queueData,
          isLoading: false,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreDomainData: res.data.hasNextPage,
        }));
      });
  }

  renderLoader() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={10}
            width={200}
            timeout={5000} //3 secs
          />
        </div>
      );
    }
  }

  getDateFilters() {
    return {
      startDate: moment(),
      endDate: moment(),
      locale: this.state.locale,
    };
  }

  handleCallback = (date) => {
    const dateArray = date.delegateTarget.value.split(" - ");
    this.setState({ startDate: dateArray[0], endDate: dateArray[1] });
  };

  handleChange = (selectedOption) => {
    if (selectedOption.label.toUpperCase() === "ALL") {
      this.setState({
        brand: "ALL",
      });
    } else {
      const selectedBrand = this.state.brands.find(
        (brand) => brand.id === selectedOption.value
      );

      this.setState({
        brand: selectedOption.value,
        brandId: selectedBrand ? selectedBrand.id : null,
      });
    }
  };

  changePlatformSelectBox(e) {
    let selectedKeyValue = "domainKeys";

    let key = e.target.value;

    if (key === "website") {
      selectedKeyValue = "websiteKeys";
    }
    if (key === "social-media") {
      selectedKeyValue = "socialMediaKeys";
    }
    if (key === "e-commerce") {
      selectedKeyValue = "ecommerceKeys";
    }
    if (key === "app-store") {
      selectedKeyValue = "appStoreKeys";
    }

    this.setState({
      platform: e.target.value,
      selectedKey: selectedKeyValue,
    });
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }
    const { platform, formSubmitted } = this.state;
    const options = this.state.brands.map((brand, brandKey) => ({
      value: brand.id,
      label: brand.brandName,
    }));

    return (
      <div className="row">
        {this.props?.login?.user?.role === "Admin" ? (
          <AdminSideNav />
        ) : (
          <AnalystSideNav />
        )}

        <main
          id="bulk-data-download-screen"
          className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3"
        >
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-4 text-sm-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      SCRAPPER-1
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card card-small mb-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Filename
                            </span>
                            <input
                              onChange={(e) =>
                                this.setState({
                                  filename: e.target.value,
                                })
                              }
                              value={this.state.filename}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Platform
                            </span>
                            <select
                              value={this.state.platform}
                              onChange={(e) => this.changePlatformSelectBox(e)}
                              className="form-control"
                              id="options"
                            >
                              <option value="">Select from dropdown</option>
                              {/* {((this.state.availablePlatforms &&
                                                                this.state.availablePlatforms.domain) ||
                                                                !this.state.availablePlatforms) && (
                                                                    <option value="domain">Domain</option>
                                                                )} */}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.website) ||
                                !this.state.availablePlatforms) && (
                                <option value="website">Website</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.socialMedia) ||
                                !this.state.availablePlatforms) && (
                                <option value="social-media">
                                  Social Media
                                </option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.eCommerce) ||
                                !this.state.availablePlatforms) && (
                                <option value="e-commerce">E-commerce</option>
                              )}
                              {((this.state.availablePlatforms &&
                                this.state.availablePlatforms.appStore) ||
                                !this.state.availablePlatforms) && (
                                <option value="app-store">App Store</option>
                              )}
                            </select>
                            {formSubmitted && platform === "" && (
                              <span style={{ color: "red" }}>
                                Please select an option
                              </span>
                            )}
                            {this.state.dataSaveError?.platform && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.platform}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Email Id
                            </span>
                            <Select
                              value={{
                                value: this.state.emailId,
                                label: this.state.emailId,
                              }}
                              isDisabled={true}
                            />
                            {this.state.dataSaveError?.emailId && (
                              <span className="text-danger">
                                {this.state.dataSaveError?.brand}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <span className="text-muted d-block mb-2">
                              Upload
                            </span>
                            <input
                              id="inputfile"
                              name="files"
                              multiple
                              type="file"
                              ref={this.fileInputRef}
                              //  accept=".xlsx, .xls"
                            />
                          </div>
                          {this.state.dataSaveError?.data && (
                            <span className="text-danger">
                              {this.state.dataSaveError?.data}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3 mt-auto">
                          <div className="form-group">
                            {/* <span className="text-muted d-block mb-2">#</span> */}
                            <button
                              type="button"
                              onClick={(e) => this.saveScrapper1Data(e)}
                              className="btn md-3 btn-success"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="card card-small mb-4">
                  <div className="card-body p-0">
                    <Table
                      hover
                      width="100%"
                      id="queue-table"
                      className="table mb-0"
                    >
                      <thead className="bg-light uppercase-th">
                        <tr>
                          <th width="3%" scope="col" className="border-0">
                            Sr.
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Date & Time added
                          </th>
                          <th width="8%" scope="col" className="border-0">
                            Username
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            fileName
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Platform
                          </th>

                          <th width="6%" scope="col" className="border-0">
                            No of Records
                          </th>
                          <th width="8%" scope="col" className="border-0">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state?.queues.map((queue, key) => (
                          <>
                            <tr
                              key={key}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + key}
                              aria-controls={"multiCollapseExample" + key}
                            >
                              <td>
                                <p className="mx-0 my-0">{key + 1}</p>
                              </td>
                              <td>
                                <p className="mx-0 my-0">
                                  {queue?.createdAt
                                    ? `${castToDefaultDateFormat(
                                        queue?.createdAt
                                      )} ${castToTimeFormat(queue?.createdAt)}`
                                    : ""}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.receiverEmail}
                                >
                                  {queue.receiverEmail}
                                </p>
                              </td>

                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.brandName}
                                >
                                  {queue.brandName}
                                </p>
                              </td>

                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.platform}
                                >
                                  {queue.platform}
                                </p>
                              </td>

                              <td>
                                <p
                                  className="mx-5  truncate"
                                  title={queue.numOfRecords}
                                >
                                  {queue.numOfRecords}
                                </p>
                              </td>
                              <td>
                                <p
                                  className="mx-0 my-0 truncate"
                                  title={queue.status}
                                >
                                  {queue.status}
                                </p>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {this.state.hasMoreDomainData && (
                  <div className="text-center">
                    <a
                      href="#"
                      className="text-danger"
                      onClick={(e) => this.listofRecords()}
                    >
                      {" "}
                      {this.renderLoader()} Load more...
                    </a>
                  </div>
                )}
                {!this.state.hasMoreDomainData && (
                  <div className="text-center">
                    <label className="text-muted">No more data to show.</label>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* data saved sweet alert */}
          <Footer />
        </main>
      </div>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  login: state.login,
  response: state.response,
});

export default withAlert()(
  connect(mapStateToProps)(authorizeAnalystOrAdmin(requireAuth(Scrapper1)))
);
