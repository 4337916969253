import store from './store/index';

export const availablePlatforms = () => {

  const storeData = store.getState()

  let platforms = {}

  let brands = storeData?.login?.user?.brandDetails

  if (!brands || !brands.length) {
    return platforms;
  }

  for (let brandData of brands) {

    if (!brandData.platform || Object.getPrototypeOf(brandData.platform) != Object.prototype) {
      continue;
    }

    if ('domain' in brandData.platform) {
      platforms.domain = true
    }
    if ('website' in brandData.platform) {
      platforms.website = true
    }
    if ('socialMedia' in brandData.platform) {
      platforms.socialMedia = true
    }
    if ('eCommerce' in brandData.platform) {
      platforms.eCommerce = true
    }
    if ('appStore' in brandData.platform) {
      platforms.appStore = true
    }
    if ('tmch' in brandData.platform) {
      platforms.tmch = true
    }
  }

  return platforms;
}

export const getNewSortOrder = (prevState) => {
  let sortOrder = ''
  let classPostFix = 'nutral'

  if (prevState.sorting.sortOrder === '') {
    sortOrder = 'asc'
    classPostFix = 'asc'
  }

  if (prevState.sorting.sortOrder === 'asc') {
    sortOrder = 'desc'
    classPostFix = 'desc'
  }

  if (prevState.sorting.sortOrder === 'desc') {
    sortOrder = ''
    classPostFix = 'nutral'
  }

  return {
    sortOrder, classPostFix
  }
}

//Function to check password strength
export const validatePassword = (password, cpassword) => {
  // Check password length
  if (password.length < 8) {
    return 2;
  }

  // Check if password and confirm password match
  if (password !== cpassword) {
    return 3;
  }

  // Check for at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    return 4;
  }

  // Check for at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    return 5;
  }

  // Check for at least one digit
  if (!/\d/.test(password)) {
    return 6;
  }

  // Check for at least one special character
  if (!/[\W_]/.test(password)) {
    return 7;
  }

  // All checks passed
  return 1;
}

export const availablePlatformsAtClientDashboard = () => {

  const storeData = store.getState()
  let platforms = {}

  let brands = storeData?.login?.user?.brandDetails;
  let companyId = storeData.selectedCompany.id || storeData?.login?.user?.companyDetails[0]._id;

  if (!brands || !brands.length) {
    return platforms;
  }

  for (let brandData of brands) {

    if (!brandData.platform || Object.getPrototypeOf(brandData.platform) != Object.prototype) {
      continue;
    }

    if ('domain' in brandData.platform && brandData.company === companyId) {
      platforms.domain = true
    }
    if ('website' in brandData.platform && brandData.company === companyId) {
      platforms.website = true
    }
    if ('socialMedia' in brandData.platform && brandData.company === companyId) {
      platforms.socialMedia = true
    }
    if ('eCommerce' in brandData.platform && brandData.company === companyId) {
      platforms.eCommerce = true
    }
    if ('appStore' in brandData.platform && brandData.company === companyId) {
      platforms.appStore = true
    }
    if ('tmch' in brandData.platform && brandData.company === companyId) {
      platforms.tmch = true
    }
  }

  return platforms;
}