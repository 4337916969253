import React from "react";
import { connect } from "react-redux";
import UnauthorisedPage from '../pages/Unauthorised';


const authorizeAdmin = (ChildComponent) => {

    class AuthorizeAdminHOC extends React.Component {

        constructor(props) {
            super(props);
        }

        render() {
            if (this.props?.login?.user?.role === 'Client' || this.props?.login?.user?.role === 'Analyst') {
                return <UnauthorisedPage />
            }

            return (
                <ChildComponent {...this.props} />
            )
        }
    }

    const mapStateToProps = (state) => ({ login: state.login, response: state.response, user: state.user })

    return connect(mapStateToProps)(AuthorizeAdminHOC);
}

export default authorizeAdmin;