import React from 'react'
import UnauthorisedPage from '../Unauthorised'
import AnalystSideNav from '../../common/AnalystSideNav.js'
import TopNav from '../../common/TopNav.js'
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeAdmin from '../../hoc/authorizeAdmin'
import { castToServerDateFormat } from '../../settings/index'
import debounce from 'lodash.debounce';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import '../../assets/css/domain-search-page.css'
import '../../assets/css/table.css';


class SearchedDomainRecordsPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            authorised: true,
            companies: [],
            brands: [],
            company: '',
            brand: '',
            keywords: '',
            schedules: [],
            selectedCompany: null,
            selectedBrand: null,
            selectedDate: null,
            viewType: 'grid'
        }

        this.fetchSearchedDomains = debounce(this.fetchSearchedDomains.bind(this), 1000);
    }

    componentDidMount() {
        this.getCompanies();
        this.fetchSearchedDomains();
    }

    switchViewType(type) {
        if (type === 'list' || type === 'grid') {
            this.setState(() => ({
                viewType: type
            }))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCompany !== this.state.selectedCompany) {
            if (this.state.selectedCompany === '') {
                return;
            }

            this.getBrands();
        }
    }

    async getCompanies() {
        await window.axios.get('/company', {}).then(res => {
            this.setState({
                companies: res.data.docs
            })
        });
    }

    async getBrands() {
        await window.axios.get('/brand', {
            params: {
                company: this.state.selectedCompany,
                page: 1,
                perPage: 800
            }
        }).then(res => {
            this.setState({
                brands: res.data.docs
            })
        });
    }

    changeCompanySelectBox(e) {
        this.setState(() => ({
            selectedCompany: e.target.value,
            selectedBrand: '',
            brands: []
        }));
    }

    changeBrandSelectBox(e) {
        this.setState(() => ({
            selectedBrand: e.target.value
        }));
    }

    async fetchSearchedDomains() {
        const { selectedCompany: company, selectedBrand: brand, selectedDate: date } = this.state;
        await window.axios.get('/domain-search-schedule/fetch-searched-domains', {
            params: {
                company, brand, date
            }
        }).then(res => {
            this.setState(({
                schedules: res.data.docs
            }))
        });
    }

    renderDomainList() {
        if (this.state.viewType === 'list') {
            return <div className="card card-small">
                <div className="card-body card-body-table">
                    <div className='table-container'>
                        <table className='table-custom table table-hover' width="100%" >
                            <thead>
                                <tr>
                                    <th width="10%">Sn.</th>
                                    <th>Company</th>
                                    <th>Brand</th>
                                    <th>Domain</th>
                                    <th width="20%">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.schedules.map((schedule, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{schedule?.company?.legalName}</td>
                                        <td>{schedule?.brand?.brandName}</td>
                                        <td><a target="_blank" href={`http://${schedule?.domain}`}>{schedule?.domain}</a></td>
                                        <td>{castToServerDateFormat(schedule?.createdAt)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }

        if (this.state.viewType === 'grid') {
            return <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3 my-1">
                {this.state.schedules.map((schedule, index) =>
                    <div className="col">
                        <div className="card shadow-sm">
                            {this.renderImage(schedule)}
                            <div className="card-body">
                                <p className="card-text">
                                    <a className='font-12' target="_blank" href={`http://${schedule?.domain}`}>{schedule?.domain}</a><br />
                                    <span className="text-muted font-12" >{schedule?.company?.legalName}</span><br />
                                    <span className="text-muted font-12" >{schedule?.brand?.brandName}</span>
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="btn-group">
                                        <p className='domain-actions'>
                                            <button title='Make high priority infringement.' onClick={(e) => this.takeAnAction('high', schedule?.id, index)} className='btn text-success'><i class="fa fa-angle-double-up" aria-hidden="true"></i></button>
                                            <button title='Make lwo priority infringement.' onClick={(e) => this.takeAnAction('low', schedule?.id, index)} className='btn text-warning'><i class="fa fa-angle-double-down" aria-hidden="true"></i></button>
                                            <button title='Ignore this record.' onClick={(e) => this.takeAnAction('ignore', schedule?.id, index)} className='btn text-danger'><i className='fa fa-ban'></i></button>
                                        </p>
                                    </div>
                                    <small className="text-muted">{castToServerDateFormat(schedule?.createdAt)}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        }
    }

    async takeAnAction(status, recordId, index) {
        await window.axios.post('/domain-search-schedule/take-action', {
            action: status,
            recordId
        }).then(res => {
            let data = this.state.schedules;

            if (index > -1) { // only splice array when item is found
                data.splice(index, 1); // 2nd parameter means remove one item only
            }

            this.setState(() => ({
                schedules: data
            }))
        });
    }

    renderImage(record) {
        if (record?.imageUrl) {
            return <img className="bd-placeholder-img card-img-top" src={record?.imageUrl} />
        } else {
            return <img className='bd-placeholder-img card-img-top' src="https://via.placeholder.com/255x128.png?text=No+image+available" />
        }

    }

    renderHtml() {

        if (!this.state.authorised) {
            return <UnauthorisedPage />
        }

        return <div className="row">
            <AnalystSideNav />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="page-header row no-gutters py-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 text-sm-left mb-0">
                                    <span className="text-uppercase page-subtitle">Searched Domains</span>
                                </div>
                                <div className='col-6'>
                                    <div className='toggle-view-type text-right'>
                                        <i onClick={(e) => this.switchViewType('list')} className={`fa fa-list mx-1 ${(this.state.viewType === 'list') ? 'selected' : ''}`} aria-hidden="true"></i>
                                        <i onClick={(e) => this.switchViewType('grid')} className={`fa fa-th-large mx-1 ${(this.state.viewType === 'grid') ? 'selected' : ''}`} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-small my-1'>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label className='font-weight-bold'>Company</label>
                                        <select onChange={(e) => this.changeCompanySelectBox(e)} className='form-control'>
                                            <option value="">Select Company</option>
                                            {this.state.companies.map((company, key) =>
                                                <option value={company.id} key={key}>{company.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label className='font-weight-bold'>Brand</label>
                                        <select onChange={(e) => this.changeBrandSelectBox(e)} className='form-control'>
                                            <option value="">Select Brand</option>
                                            {this.state.brands.map((brand, key) =>
                                                <option key={key} value={brand.id}>{brand.brandName}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label className='font-weight-bold'>Date</label>
                                        <input onChange={(e) => this.setState(({ selectedDate: e.target.value }))} className='form-control' type='date' />
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='form-group my-4 py-2'>
                                        <label className='font-weight-bold'>&nbsp;&nbsp;</label>
                                        <button onClick={() => this.fetchSearchedDomains()} className='btn btn-success'>Search <i className='fa fa-search'></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            {this.renderDomainList()}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    }

    render() {
        return <>
            {this.renderHtml()}
        </>;
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default connect(mapStateToProps)(authorizeAdmin(requireAuth(SearchedDomainRecordsPage)));