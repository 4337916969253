import { getLoggedInUserDetails } from "../storage"


const initialLoginState = getLoggedInUserDetails() ? getLoggedInUserDetails() : {user : null, isAuthenticated : false, token: null, is2FaVerifiedUser: false, isVerified:false}

export const loginReducer = (state = initialLoginState, action) => {
    switch(action.type){
        case 'LOGIN': {
            return {
                user: action.user,
                isAuthenticated: action.isAuthenticated,
                is2FaVerifiedUser : action.user?.twoFactorAuth || false,
                token: action.token
            }
        }

        case 'LOGOUT': {
            return {
                user: null,
                isAuthenticated: false,
                is2FaVerifiedUser:false,
                isVerified:false,
                token: null
            }
        }

        case 'VERIFY': {
            return {
                ...state,
                isVerified:action.isVerified,
                token:action.token,
            }
        }

        default: {
            return state
        }
    }
}