import storage from 'local-storage-fallback'
import { changeBrand } from './changeBrandAction'

export const logoutUser = (token) => {
    return (dispatch) => {
        window.axios.post('/users/revoke-token', token)
            .then((response) => {
                storage.removeItem('user')
                storage.removeItem('isAuthenticated')
                storage.removeItem('is2FaVerifiedUser')
                storage.removeItem('isVerified')
                storage.removeItem('token')
                storage.removeItem('selectedBrand')
                storage.removeItem('selectedCompany')
                storage.removeItem('clientWebsiteFilter')
                storage.removeItem('clientDomainFilter')
                storage.removeItem('clientSocialMediaFilter')
                storage.removeItem('clientEcommerceFilter')
                storage.removeItem('clientAppStoreFilter')

                dispatch({
                    type: 'CHANGE_BRAND_SELECTION',
                    brand: {
                        id: null,
                        brandName: 'All',
                        platforms: null
                    }
                })
                dispatch({
                    type: 'CHANGE_COMPANY_SELECTION',
                    companyData: {
                        id: null,
                        companyName: 'All'
                    }
                })


                dispatch({
                    type: 'LOGOUT',
                })

                // dispatch success message
                dispatch({
                    type: 'SUCCESS_STUFF',
                    payload: response
                })
            })
            .catch((error) => {
                storage.removeItem('user')
                storage.removeItem('isAuthenticated')
                storage.removeItem('token')
                storage.removeItem('is2FaVerifiedUser')
                storage.removeItem('isVerified')
                storage.removeItem('selectedBrand')
                storage.removeItem('selectedCompany')
                storage.removeItem('clientWebsiteFilter')
                storage.removeItem('clientDomainFilter')
                storage.removeItem('clientSocialMediaFilter')
                storage.removeItem('clientEcommerceFilter')
                storage.removeItem('clientAppStoreFilter')

                dispatch({
                    type: 'CHANGE_BRAND_SELECTION',
                    brand: {
                        id: null,
                        brandName: 'All',
                        platforms: null
                    }
                })

                dispatch({
                    type: 'CHANGE_COMPANY_SELECTION',
                    companyData: {
                        id: null,
                        companyName: 'All'
                    }
                })

                dispatch({
                    type: 'LOGOUT',
                })
            })
    }
}

export const verifyToken = (token) => {
    const secretKey = process.env.REACT_APP_SECRET_KEY
    return (dispatch) => {
        window.axios.post('/users/verify/token', {
            token
        },{
            headers:{
                'X-Key':secretKey
            }
        })
            .then((response) => {

                const data = response.data

                storage.setItem('user', JSON.stringify(data))
                storage.setItem('isAuthenticated', true)
                storage.setItem('token', JSON.stringify(data?.jwtToken))
                storage.setItem('is2FaVerifiedUser', JSON.stringify(data?.twoFactorAuth))

                // dipatch save login user info
                dispatch({
                    type: 'LOGIN',
                    user: data,
                    isAuthenticated: true,
                    token: data?.jwtToken,
                    is2FaVerifiedUser: data?.twoFactorAuth
                })
            })
            .catch((error) => {
                storage.removeItem('user')
                storage.removeItem('isAuthenticated')
                storage.removeItem('token')
                storage.removeItem('is2FaVerifiedUser')
                // dipatch save login user info
                dispatch({
                    type: 'LOGIN',
                    user: null,
                    isAuthenticated: false,
                    token: null,
                    is2FaVerifiedUser: false
                })

                // dispatch error message
                dispatch({
                    type: 'ERROR_STUFF',
                    payload: error
                })
            })
    }
}

export const saveLoginUser = (user) => {
    return (dispatch) => {

        window.axios.post('/users/authenticate', {
            username: user?.email,
            password: user?.password
        })
            .then((response) => {

                const data = response.data

                storage.setItem('user', JSON.stringify(data))
                storage.setItem('isAuthenticated', true)
                storage.setItem('token', JSON.stringify(data?.jwtToken))
                storage.setItem('is2FaVerifiedUser', JSON.stringify(data?.twoFactorAuth))

                // dipatch save login user info
                dispatch({
                    type: 'LOGIN',
                    user: data,
                    isAuthenticated: true,
                    token: data?.jwtToken,
                    is2FaVerifiedUser: data?.twoFactorAuth
                })
            })
            .catch((error) => {
                storage.removeItem('user')
                storage.removeItem('isAuthenticated')
                storage.removeItem('token')
                storage.removeItem('is2FaVerifiedUser')
                // dipatch save login user info
                dispatch({
                    type: 'LOGIN',
                    user: null,
                    isAuthenticated: false,
                    token: null,
                    is2FaVerifiedUser: false
                })

                // dispatch error message
                dispatch({
                    type: 'ERROR_STUFF',
                    payload: error
                })
            })
    }
}

export const checkIs2FaVerified = (user) => {
    return (dispatch) => {
        window.axios.post('/users/v2/authenticate', {
            id: user.id,
            twoFactorAuthCode: user.twoFactorAuthCode || '0'
        })
            .then((response) => {
                const { isVerified = false, jwtToken = '' } = response.data;
                storage.setItem('isVerified', isVerified);
                storage.setItem('token', JSON.stringify(jwtToken));
                
                // dipatch the is2FaVerified
                dispatch({
                    type: 'VERIFY',
                    isVerified: isVerified,
                    token: jwtToken
                })

                // dispatch success message
                dispatch({
                    type: 'SUCCESS_STUFF',
                    payload: response
                })
            })
            .catch((error) => {
                storage.removeItem('isVerified');
                // dispatch error message
                dispatch({
                    type: 'ERROR_STUFF',
                    payload: error
                })
            })
    }
}

export const markNotification = (notification) => {
    return (dispatch) => {
        window.axios.post('/users/v1/markNotification', {
            userId: notification.userId,
            seen: notification.seen
        })
            .then((response) => {
                const user = JSON.parse(localStorage.getItem('user'));
                user.seen = true;
                storage.setItem('user', JSON.stringify(user))
                
                // dispatch success message
                dispatch({
                    type: 'SUCCESS_STUFF',
                    payload: response
                })
            })
            .catch((error) => {
                storage.removeItem('isVerified');
                // dispatch error message
                dispatch({
                    type: 'ERROR_STUFF',
                    payload: error
                })
            })
    }
}