import { formatString } from "../common/utils.js";
import { connect } from "react-redux";
import { castToServerDateFormat } from "./../settings/index";
import { availablePlatformsAtClientDashboard } from "../helpers";
import { changeClientDateFilter } from "./../store/actions/filterActions";
import { changeBrand } from "../store/actions/changeBrandAction";
import { Component } from "react";
import Footer from "../common/Footer.js";
import ClientSideNav from "../common/ClientSideNav";
import TopNav from "../common/TopNav.js";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import requireAuth from "../hoc/requireAuth";
import authorizeClient from "../hoc/authorizeClient";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DashboardCard from "../components/charts/DashboardCard.jsx";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DomainDashboard from "../components/clientDashboard/domain.jsx";
import WebsiteDashboard from "../components/clientDashboard/website.jsx";
import SocialMediaDashboard from "../components/clientDashboard/socialMedia.jsx";
import ECommerceDashboard from "../components/clientDashboard/eCommerce.jsx";
import AppStoreDashboard from "../components/clientDashboard/appStore.jsx";

const baseData = {
  eCommerce: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
  domain: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
  socialMedia: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
  website: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
  appStore: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
  tmch: {
    totalDocs: 0,
    pie: [],
    bar: [],
  },
};

const initialDomain = {
  totalDocs: 0,
  currentMonth: 0,
  tld: [],
  reporting: [],
};
const initialECommerce = {
  totalDocs: 0,
  currentMonth: 0,
  brands: [],
  site: [],
  seller: [],
  reporting: [],
};
const initialSocialMedia = {
  totalDocs: 0,
  currentMonth: 0,
  socialMediaPie: [],
  reporting: [],
};
const initialAppStore = {
  totalDocs: 0,
  currentMonth: 0,
  reporting: [],
};
const initialWebsite = {
  totalDocs: 0,
  currentMonth: 0,
  reporting: [],
};

class ClientDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authorised: true,
      dashboardData: {},
      selectedBrand: null,
      isLoading: true,
      platforms: {},
      chartData: JSON.parse(JSON.stringify(baseData)),
      activeTabKey: "home",
      domain: initialDomain,
      eCommerce: initialECommerce,
      socialMedia: initialSocialMedia,
      appStore: initialAppStore,
      website: initialWebsite,
    };
  }

  async componentDidMount() {
    await this.getHomeGraphs();
    const platforms = availablePlatformsAtClientDashboard();
    this.setState(() => ({ platforms: platforms }));
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedBrand !== this.props.selectedBrand) {
      await this.getHomeGraphs();
    }

    if (prevProps.clientDateFilter !== this.props.clientDateFilter) {
      await this.getHomeGraphs();
    }
  }

  getDateFilters() {
    return {
      startDate: moment(this.props.clientDateFilter.startDate),
      endDate: moment(this.props.clientDateFilter.endDate),
      locale: { format: "DD-MM-YYYY" },
    };
  }

  async getHomeGraphs() {
    this.setState({ isLoading: true });
    try {
      const { clientDateFilter, selectedBrand } = this.props;

      const reqData = {
        startDate: castToServerDateFormat(clientDateFilter.startDate),
        endDate: castToServerDateFormat(clientDateFilter.endDate),
        ...(selectedBrand?.id && { brand: selectedBrand.id }),
      };

      const fetchChartData = async (url) => {
        try {
          const { data } = await window.axios.get(url, {
            params: reqData,
            headers: { "Content-Type": "application/json" },
          });
          return data;
        } catch (error) {
          console.error(`Error fetching data from ${url}:`, error);
          throw error;
        }
      };

      const [homeData, eCommerce] = await Promise.all([
        fetchChartData("/dashboard_v2/client/chart/home"),
        fetchChartData("/dashboard_v2/client/chart/eCommerce"),
      ]);

      const [domain, socialMedia, appStore, website] = await Promise.all([
        fetchChartData("/dashboard_v2/client/chart/domain"),
        fetchChartData("/dashboard_v2/client/chart/social-media"),
        fetchChartData("/dashboard_v2/client/chart/app-store"),
        fetchChartData("/dashboard_v2/client/chart/website"),
      ]);

      this.setState({
        chartData: homeData,
        domain,
        eCommerce,
        socialMedia,
        appStore,
        website,
      });
    } catch (error) {
      console.error("Fa1led to load graphs:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  changeBrandAndRedirect(e, brand, redirectPath) {
    e.preventDefault();
    let availableBrands = [];

    if (this.props.login?.user && this.props.login?.user?.brandDetails) {
      availableBrands = this.props.login?.user?.brandDetails;
    }

    if (availableBrands && availableBrands.length) {
      let selectedBrand = availableBrands.filter(
        (availableBrand) => availableBrand.brandName === brand
      );

      if (selectedBrand.length) {
        selectedBrand = selectedBrand[0];

        this.props.dispatch(
          changeBrand({
            brandName: selectedBrand?.brandName,
            id: selectedBrand?.id,
          })
        );
      }
    }

    return this.props.history.push(`${redirectPath}`);
  }

  async handleTabChange(key) {
    this.setState(() => ({
      activeTabKey: key,
    }));
  }

  renderDomainDashboard(Component, accordion) {
    if (this.state.platforms.domain) {
      const data = this.state.chartData.domain;

      return (
        <div className="row">
          <DashboardCard
            useAccordion={accordion}
            title="Domain"
            data={data}
            link="/domain-dashboard"
          >
            {Component}
          </DashboardCard>
        </div>
      );
    }
  }

  renderWebsiteDashboard(Component, accordion) {
    if (this.state.platforms.website) {
      const data = this.state.chartData.website;

      return (
        <div className="row">
          <DashboardCard
            useAccordion={accordion}
            title="Website"
            data={data}
            link="/website-dashboard"
          >
            {Component}
          </DashboardCard>
        </div>
      );
    }
  }

  renderSocialMediaDashboard(Component, accordion) {
    if (this.state.platforms.socialMedia) {
      const data = this.state.chartData.socialMedia;

      return (
        <div className="row">
          <DashboardCard
            useAccordion={accordion}
            title="Social Media"
            data={data}
            link="/social-media-dashboard"
          >
            {Component}
          </DashboardCard>
        </div>
      );
    }
  }

  renderEcommerceDashboard(Component, accordion) {
    if (this.state.platforms.eCommerce) {
      const data = this.state.chartData.eCommerce;

      return (
        <div className="row">
          <DashboardCard
            useAccordion={accordion}
            title="Ecommerce"
            data={data}
            link="/ecommerce-dashboard"
          >
            {Component}
          </DashboardCard>
        </div>
      );
    }
  }

  renderAppStoreDashboard(Component, accordion) {
    if (this.state.platforms.appStore) {
      const data = this.state.chartData.appStore;

      return (
        <div className="row">
          <DashboardCard
            useAccordion={accordion}
            title="App Store"
            data={data}
            link="/app-store-dashboard"
          >
            {Component}
          </DashboardCard>
        </div>
      );
    }
  }

  renderTrapPurchaseDashboard() {
    if (this.state.platforms.trapPurchase) {
      const data = this.state.chartData.tmch;

      return (
        <div className="row">
          <DashboardCard
            title="Trap Purchase"
            data={data}
            link="/trap-purchase-dashboard"
          />
        </div>
      );
    }
  }

  handleCallback = async (event, picker) => {
    this.props.dispatch(
      changeClientDateFilter({
        startDate: picker.startDate,
        endDate: picker.endDate,
      })
    );
  };

  renderHtml() {
    if (this.state.isLoading) {
      return (
        <div className="text-center">
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={200}
            width={200}
            timeout={50000} //3 secs
          />
        </div>
      );
    }

    return (
      <>
        {/* Domains Stats Starts */}
        {this.renderDomainDashboard()}
        {/* website stats starts  */}
        {this.renderWebsiteDashboard()}
        {/* WEBSITE Stats Ends */}
        {/* SOCIAL MEDIA Stats Starts */}
        {this.renderSocialMediaDashboard()}
        {/* SOCIAL MEDIA Stats Ends */}
        {/* E-COMMERCE Stats Starts */}
        {this.renderEcommerceDashboard()}
        {/* E-COMMERCE Stats Ends             */}
        {/* APP STORE Stats Starts */}
        {this.renderAppStoreDashboard()}
        {/* APP STORE Stats Ends */}
        {/* TRAP PURCHASE Starts */}
        {this.renderTrapPurchaseDashboard()}
        {/* TRAP PURCHASE Ends */}
      </>
    );
  }

  render() {
    const { activeTabKey, platforms } = this.state;

    return (
      <>
        <div className="row">
          <ClientSideNav />

          <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
            <TopNav />

            <div className="main-content-container container-fluid px-4">
              <div className="page-header row no-gutters py-4">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6 text-sm-left mb-0">
                      <span className="text-uppercase page-subtitle">
                        Dashboard
                      </span>
                    </div>
                    <div className="col-6 text-sm-right mb-0">
                      <span className="uppercase-text">
                        <strong>Reporting Date: </strong>
                      </span>
                      <DateRangePicker
                        onApply={this.handleCallback}
                        initialSettings={this.getDateFilters()}
                        alwaysShowCalendars={true}
                      >
                        <input className="from-control" />
                      </DateRangePicker>
                    </div>
                  </div>
                </div>
              </div>

              <Tabs
                activeKey={activeTabKey}
                onSelect={(key) => this.handleTabChange(key)}
                className="mb-3"
              >
                <Tab eventKey="home" title="Home">
                  {this.renderHtml()}
                </Tab>

                {platforms &&
                  Object.entries(platforms).map(([key, value]) => {
                    if (!value) return null;

                    const renderDashboard = () => {
                      switch (key) {
                        case "domain":
                          return this.renderDomainDashboard(
                            <DomainDashboard chartData={this.state.domain} />,
                            false
                          );
                        case "website":
                          return this.renderWebsiteDashboard(
                            <WebsiteDashboard chartData={this.state.website} />,
                            false
                          );
                        case "socialMedia":
                          return this.renderSocialMediaDashboard(
                            <SocialMediaDashboard
                              chartData={this.state.socialMedia}
                            />,
                            false
                          );
                        case "eCommerce":
                          return this.renderEcommerceDashboard(
                            <ECommerceDashboard
                              chartData={this.state.eCommerce}
                            />,
                            false
                          );
                        case "appStore":
                          return this.renderAppStoreDashboard(
                            <AppStoreDashboard
                              chartData={this.state.appStore}
                            />,
                            false
                          );
                        default:
                          return null;
                      }
                    };

                    if (key === "tmch") {
                      return null;
                    }
                    return (
                      <Tab key={key} eventKey={key} title={formatString(key)}>
                        <div>{renderDashboard()}</div>
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
            <Footer />
          </main>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  clientDateFilter: state.clientDateFilter,
  selectedBrand: state.selectedBrand,
  selectedCompany: state.selectedCompany,
});

export default connect(mapStateToProps)(
  authorizeClient(requireAuth(ClientDashboard))
);
