import React from "react";
import AnalystSideNav from './../common/AnalystSideNav';
import AdminSideNav from './../common/AdminSideNav';
import TopNav from "../common/TopNav";
import authorizeAnalyst from './authorizeAnalyst';
import requireAuth from './requireAuth';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import propTypes from 'prop-types'

const analystTemplate = (ChildComponent) => {

    class AnalystTemplateHOC extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                selectedKey: "",
                domainKeys: [
                    { key: 'sn', label: "SN" },
                    { key: 'image', label: "IMAGE" },
                    { key: 'domain', label: "DOMAIN" },
                    { key: 'tld', label: "TLD" },
                    { key: 'priority', label: "PRIORITY" },
                    { key: 'status', label: "STATUS" },
                    { key: 'registrar', label: "REGISTRAR" },
                    { key: 'ipAddress', label: "IP ADDRESS" },
                    { key: 'hosting', label: "HOSTING" },
                    { key: 'lastMofifiedDate', label: "LAST MODIFICATION DATE" },
                    { key: 'expiryDate', label: "EXPIRY DATE" },
                    { key: 'registrationDate', label: "REGISTRATION DATE" },
                    { key: 'note', label: "NOTE" },
                    { key: 'name', label: "NAME" },
                    { key: 'org', label: "ORG" },
                    { key: 'address', label: "ADDRESS" },
                    { key: 'state', label: "STATE" },
                    { key: 'country', label: "COUNTRY" },
                    { key: 'emailAddress', label: "EMAIL ADDRESS" },
                    { key: 'contactNumber', label: "CONTACT NUMBER" }
                ],
                websiteKeys: [
                    { key: 'sn', label: "SN" },
                    { key: 'image', label: "IMAGE" },
                    { key: 'url', label: "URL" },
                    { key: 'domain', label: "DOMAIN" },
                    { key: 'dateAdded', label: "DATE ADDED" },
                    { key: 'priority', label: "PRIORITY" },
                    { key: 'status', label: "STATUS" }
                ],
                socialMediaKeys: [
                    { key: 'sn', label: "SN" },
                    { key: 'image', label: "IMAGE" },
                    { key: 'platform', label: "PLATFORM" },
                    { key: 'heading', label: "HEADING" },
                    { key: 'link', label: "LINK" },
                    { key: 'dateAdded', label: "DATE ADDED" },
                    { key: 'priority', label: "PRIORITY" },
                    { key: 'status', label: "STATUS" }
                ],
                ecommerceKeys: [
                    { key: 'sn', label: "SN" },
                    { key: 'image', label: "IMAGE" },
                    { key: 'site', label: "SITE" },
                    { key: 'dateAdded', label: "DATE ADDED" },
                    { key: 'url', label: "URL" },
                    { key: 'title', label: "TITLE" },
                    { key: 'price', label: "PRICE" },
                    { key: 'reason', label: "REASON" },
                    { key: 'priority', label: "PRIORITY" },
                    { key: 'status', label: "STATUS" },
                    { key: 'username', label: "USERNAME" },
                    { key: 'storeId', label: "STORE ID" },
                    { key: 'address', label: "ADDRESS" },
                    { key: 'city', label: "CITY" },
                    { key: 'country', label: "COUNTRY" },
                    { key: 'sellerRating', label: "Seller Rating" }
                ],
                appStoreKeys: [
                    { key: 'sn', label: "SN" },
                    { key: 'image', label: "IMAGE" },
                    { key: 'appName', label: "APP NAME" },
                    { key: 'appStore', label: "APP STORE" },
                    { key: 'dateAdded', label: "DATE ADDED" },
                    { key: 'url', label: "URL" },
                    { key: 'developerName', label: "DEVELOPER NAME" },
                    { key: 'latestPublishDate', label: "LATEST PUBLISH DATE" },
                    { key: 'status', label: "STATUS" },
                    { key: 'priority', label: "PRIORITY" },
                    { key: 'price', label: "PRICE" }
                ],
            };

            this.updateSelectedPlatformKey = this.updateSelectedPlatformKey.bind(this);
        }

        async updateSelectedPlatformKey(platform) {
            let selectedKeyValue = "";
            switch (platform) {
                case "domain": {
                    selectedKeyValue = 'domainKeys'; break;
                }
                case "website": {
                    selectedKeyValue = 'websiteKeys'; break;
                }
                case "social-media": {
                    selectedKeyValue = 'socialMediaKeys'; break;
                }
                case "e-commerce": {
                    selectedKeyValue = 'ecommerceKeys'; break;
                }
                case "app-store": {
                    selectedKeyValue = 'appStoreKeys'; break;
                }
                default: {
                    selectedKeyValue = ""; break;
                }
            }

            await this.setState(() => ({
                selectedKey: selectedKeyValue
            }))
        }

        render() {
            return (
                <>
                    <div className="row">
                        {this.props?.login?.user?.role.toUpperCase() === 'ADMIN' ? <AdminSideNav /> : <AnalystSideNav />}
                        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                            <TopNav />
                            <div className="main-content-container container-fluid px-4">
                                <ChildComponent
                                    updateSelectedPlatformKey={(platform) => this.updateSelectedPlatformKey(platform)}
                                    {...this.props}
                                    {...this.state}
                                />
                            </div>
                        </main>
                    </div>
                </>
            )
        }
    }

    AnalystTemplateHOC.propTypes = {
        updateSelectedPlatformKey: propTypes.func
    }

    const mapStateToProps = (state) => ({ login: state.login, response: state.response });

    return withAlert()(connect(mapStateToProps)(authorizeAnalyst(requireAuth(AnalystTemplateHOC))));
}

export default analystTemplate;
