import React from 'react';
import propTypes from 'prop-types';
import InfrigementStatusDropdown from './../../../components/InfrigementStatusDropdown';

const StagingDataFormComponent = (props) => {
    
    function prepareInput(platformDataKey, recordIndex){
        let output = <></>
        switch(platformDataKey?.key){
            case 'sn': {
                output = <>{recordIndex+1}</>;
                break;
            }

            case 'image': {
                output = <>
                    <input id={'input'+recordIndex} name={platformDataKey?.key} onChange={(e) => props.handleInputChange(e.target.id, platformDataKey?.key, recordIndex )} type="file" />
                </>;

                break;
            }

            case 'status':{
                output = <InfrigementStatusDropdown onStatusChange={(e) => props.updateRecordValue(platformDataKey?.key, e.target.value, recordIndex)} />
                break;
            }

            default: {
                output = <>
                    <input onChange={(e) => props.updateRecordValue(platformDataKey?.key, e.target.value, recordIndex)} value={props.records[recordIndex][platformDataKey?.key]} type="text" />
                </>;
                break;
            }
        }

        return output;
    }

    return (
        <>
            {(props.selectedPlatform?.key) && <div className="card card-small mb-4">
                <div className="card-body">
                    <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="text-uppercase font-size-12">Actions</th>
                                                {(props[props.selectedKey]) && props[props.selectedKey].map((platformDataKey, index) => (
                                                    <th className="text-uppercase font-size-12" key={index}>{platformDataKey?.label}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.records.map((record, recordIndex) => (
                                                <tr className="hover-highlighted" key={recordIndex}>
                                                    <td>
                                                        <a onClick={(e) => props.handleRemoveRow(recordIndex)} className="text-danger mx-1" href="#"><i className="fa fa-trash"></i></a>
                                                        <a onClick={(e) => props.draftSingleRow(recordIndex)} href="#" className="text-warning mx-1"><i className="fa fa-check-circle" aria-hidden="true"></i></a>
                                                    </td>
                                                    {(props[props.selectedKey]) && props[props.selectedKey].map((platformDataKey, index) => (
                                                        <td key={index}>
                                                            {prepareInput(platformDataKey, recordIndex)}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}                                       
                                        </tbody>
                                    </table>
                                </div>
                                
                            </div>
                        </div>
                </div>
            </div>}

            {(!props.selectedPlatform?.key) && <div className="no-records-found"></div>}
        </>
    )
}


StagingDataFormComponent.propTypes = {
    records: propTypes.array,
    selectedPlatform: propTypes.object,
    selectedKey: propTypes.string
}

export default  StagingDataFormComponent