import React from "react";
import analystTemplate from "../../../hoc/analystTemplate";
import ReviewDataPresentation from "./ReviewDataPresentation";

class ReviewDataContainerComponent extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectedPlatform: null,
            selectedCompany: null,
            selectedBrand: null,
            records: []
        }

        this.updateSelectedPlatform = this.updateSelectedPlatform.bind(this);
        this.updateSelectedCompany = this.updateSelectedCompany.bind(this);
        this.updateSelectedBrand = this.updateSelectedBrand.bind(this);
    }

    async updateSelectedBrand(brand){
        await this.setState(() => ({
            selectedBrand: brand
        }))
    }

    async updateSelectedCompany(company){
        await this.setState(() => ({
            selectedCompany: company
        }))
    }

    async updateSelectedPlatform(platform){
        await this.setState(() => ({
            selectedPlatform: platform
        }))
            
        let key = this.state.selectedPlatform?.key;

        this.props.updateSelectedPlatformKey(key);
    }

    render(){
        return <>
            <ReviewDataPresentation 
                updateSelectedBrand = {(brand) => this.updateSelectedBrand(brand)}
                updateSelectedCompany = {(company) => this.updateSelectedCompany(company)}
                updateSelectedPlatform = {(platform) => this.updateSelectedPlatform(platform)}
                {...this.state} 
                {...this.props} 
            />
        </>
    }
}


export default analystTemplate(ReviewDataContainerComponent);