import React from "react";
import UnauthorisedPage from "../Unauthorised";
import AdminSideNav from "../../common/AdminSideNav.js";
import TopNav from "../../common/TopNav.js";
import { Button, Modal, Form } from "react-bootstrap";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import TmchFormComponent from "./forms/TmchFormComponent";
import TmchNotificationFormComponent from "./forms/TmchNotificationFormComponent";
import { connect } from "react-redux";
import requireAuth from "../../hoc/requireAuth";
import authorizeAdmin from "../../hoc/authorizeAdmin";
import * as moment from "moment";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import { castToServerDateFormat } from "../../settings/index";
import { getInfrigementStatusText } from "../../../src/infrigementStatusCode";

class ListTmch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotificationModal: false,
      dateFormat: "Y-MM-DD",
      authorised: true,
      showModal: false,
      tmchData: [],
      pagination: {
        page: 1,
        nextPage: 1,
        prevPage: null,
        hasNextPage: true,
        hasPrevPage: false,
        pagingCounter: 1,
        totalDocs: null,
        totalPages: null,
      },
      currentTmchId: null,
      currentTmchIndex: null,
      currentTmchData: null,
      perPage: 10,
      hasMoreTmchData: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.openNotificationModal = this.openNotificationModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseNotificationModal =
      this.handleCloseNotificationModal.bind(this);
    this.handleSaveTmchData = this.handleSaveTmchData.bind(this);
    this.handleUpdateTmchData = this.handleUpdateTmchData.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDeleteTmch = this.handleDeleteTmch.bind(this);
    this.handleSaveTmchNotificationData =
      this.handleSaveTmchNotificationData.bind(this);
  }

  componentDidMount() {
    this.fetchTmchData();
  }

  async fetchTmchData(reArrangeRecords = false) {
    let reqData = {
      page: this.state.pagination.nextPage,
      perPage: this.state.perPage,
    };

    if (reArrangeRecords) {
      reqData.page = 1;
    }

    await window.axios
      .get("/tmch", {
        params: reqData,
      })
      .then((res) => {
        let tmchData = [...this.state.tmchData].concat(res.data.docs);

        if (reArrangeRecords) {
          tmchData = res.data.docs;
        }

        this.setState(() => ({
          tmchData: tmchData,
          pagination: {
            page: res.data.page,
            nextPage: res.data.nextPage,
            prevPage: res.data.prevPage,
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            pagingCounter: res.data.pagingCounter,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          },
          hasMoreTmchData: res.data.hasNextPage,
        }));
      });
  }

  handleOpenModal() {
    this.setState((prevState) => {
      return {
        showModal: true,
      };
    });
  }

  openNotificationModal(tmchId, tmchIndex) {
    this.setState((prevState) => {
      return {
        showNotificationModal: true,
        currentTmchIndex: tmchIndex,
        currentTmchId: tmchId,
      };
    });
  }

  handleCloseNotificationModal() {
    this.setState((prevState) => {
      return {
        showNotificationModal: false,
        currentTmchData: null,
        currentTmchIndex: null,
        currentTmchId: null,
      };
    });
  }

  handleCloseModal() {
    this.setState((prevState) => {
      return {
        showModal: false,
        currentTmchData: null,
        currentTmchIndex: null,
        currentTmchId: null,
      };
    });
  }

  async handleDeleteTmch(data, index) {
    await window.axios
      .delete(`/tmch/${data._id}`)
      .then((response) => {
        let tmchData = this.state.tmchData.filter((data, Tmchindex) => {
          return index !== Tmchindex;
        });

        this.setState(() => ({
          tmchData,
        }));

        Swal.fire("Yeah..", response.data.message, "success");
      })
      .catch((error) => {
        const errorData = error.response.data;
        Swal.fire("Oops...", errorData.message, "error");
      });
  }

  handleSaveTmchNotificationData(data, recordIndex) {
    let tmchData = this.state.tmchData;

    tmchData[recordIndex].notificationData = data.notificationData;

    this.setState(() => ({
      tmchData,
      showNotificationModal: false,
      currentTmchData: null,
      currentTmchIndex: null,
      currentTmchId: null,
    }));
  }

  handleSaveTmchData(data) {
    let tmchData = this.state.tmchData;
    tmchData.unshift(data);
    this.setState(() => ({
      tmchData,
    }));
    this.handleCloseModal();
  }

  handleUpdateTmchData(data, recordIndex) {
    let tmchData = this.state.tmchData;

    tmchData[recordIndex] = { ...data };

    this.setState(() => ({
      tmchData,
      showModal: false,
      currentTmchData: null,
      currentTmchIndex: null,
      currentTmchId: null,
    }));
  }

  handleEdit(data, index) {
    this.setState(() => ({
      showModal: true,
      currentTmchData: data,
      currentTmchIndex: index,
      currentTmchId: data._id,
    }));
  }

  renderNotifications(notifications) {
    if (!notifications.length) {
      return <></>;
    }

    return (
      <>
        <div className="col-md-12">
          <h3>Notifications</h3>
        </div>
        <div className="col-md-12">
          <Table className="table-dark">
            <thead>
              <tr>
                <th>Domain</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {notifications.map((notification) => (
                <tr>
                  <td>{notification.domain}</td>
                  <td>
                    {moment(notification.date).format(this.state.dateFormat)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  renderHtml() {
    if (!this.state.authorised) {
      return <UnauthorisedPage />;
    }

    return (
      <div className="row">
        <AdminSideNav />

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <TopNav />

          <div className="main-content-container container-fluid px-4">
            <div className="page-header row no-gutters py-4">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 text-left mb-0">
                    <span className="text-uppercase page-subtitle">
                      TMCH Data
                    </span>
                  </div>
                  <div className="col-6 text-right mb-0">
                    <Button onClick={this.handleOpenModal}>
                      Add TMCH Data
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card card-small mb-4">
                  <div className="card-body p-0 pb-3">
                    <Table width="100%" className="table">
                      <thead className="bg-light uppercase-th">
                        <tr>
                          <th width="3%" scope="col" className="border-0">
                            Sr.
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Mark Name
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Mark Type
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Status
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Company
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Brand
                          </th>
                          <th width="10%" scope="col" className="border-0">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.tmchData.map((tmch, tmchIndex) => (
                          <>
                            <tr
                              key={tmchIndex}
                              data-toggle="collapse"
                              data-target={".multi-collapse" + tmchIndex}
                              aria-controls={"multiCollapseExample" + tmchIndex}
                            >
                              <td>{tmchIndex + 1}</td>
                              <td className="capitalize-text">
                                {tmch.markName}
                              </td>
                              <td className="capitalize-text">
                                {tmch.markType}
                              </td>
                              <td className="capitalize-text">
                                {getInfrigementStatusText(tmch.status)}
                              </td>
                              <td className="capitalize-text">
                                {tmch.company?.name}
                              </td>
                              <td className="capitalize-text">
                                {tmch.brand?.brandName}
                              </td>
                              <td>
                                <Button
                                  style={{ padding: 5, marginRight: 5 }}
                                  onClick={() =>
                                    this.handleEdit(tmch, tmchIndex)
                                  }
                                >
                                  <i className="material-icons">edit</i>
                                </Button>
                                <Button
                                  style={{ padding: 5, marginRight: 5 }}
                                  onClick={() =>
                                    this.openNotificationModal(
                                      tmch._id,
                                      tmchIndex
                                    )
                                  }
                                  variant="secondary"
                                >
                                  <i className="material-icons">
                                    notifications
                                  </i>
                                </Button>
                                <Button
                                  style={{ padding: 5, marginRight: 5 }}
                                  onClick={() =>
                                    this.handleDeleteTmch(tmch, tmchIndex)
                                  }
                                  variant="danger"
                                >
                                  <i className="material-icons">delete</i>
                                </Button>
                              </td>
                            </tr>
                            <tr
                              className={"collapse multi-collapse" + tmchIndex}
                              id={"multiCollapseExample" + tmchIndex}
                            >
                              <td colSpan={7}>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <span className="text-muted d-block mb-2">
                                        Reporting Date
                                      </span>
                                      <input
                                        type="text"
                                        value={castToServerDateFormat(
                                          tmch.reportingDate
                                        )}
                                        disabled
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <span className="text-muted d-block mb-2">
                                        Sunrise Flag
                                      </span>
                                      <input
                                        type="text"
                                        value={tmch.sunriseFlag}
                                        disabled
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <span className="text-muted d-block mb-2">
                                        Notifications
                                      </span>
                                      <input
                                        type="text"
                                        value={tmch.notifications}
                                        disabled
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span className="text-muted d-block mb-2">
                                        Start Date
                                      </span>
                                      <input
                                        type="text"
                                        value={castToServerDateFormat(
                                          tmch.startDate
                                        )}
                                        disabled
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <span className="text-muted d-block mb-2">
                                        Expiry Date
                                      </span>
                                      <input
                                        type="text"
                                        value={castToServerDateFormat(
                                          tmch.expiryDate
                                        )}
                                        disabled
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  {this.renderNotifications(
                                    tmch.notificationData
                                  )}
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderHtml()}
        {/* Add/Edit Tmch Modal */}
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#d32f2f" }}>TMCH Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TmchFormComponent
              recordIndex={this.state.currentTmchIndex}
              recordId={this.state.currentTmchId}
              data={this.state.currentTmchData}
              onSaveTmchData={this.handleSaveTmchData}
              onUpdateTmchData={this.handleUpdateTmchData}
            />
          </Modal.Body>
        </Modal>

        {/* add/edit tmch notification modal */}

        <Modal
          size="sm"
          show={this.state.showNotificationModal}
          onHide={this.handleCloseNotificationModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#d32f2f" }}>
              Notification Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TmchNotificationFormComponent
              recordIndex={this.state.currentTmchIndex}
              recordId={this.state.currentTmchId}
              onSaveTmchNotificationData={this.handleSaveTmchNotificationData}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  login: state.login,
  response: state.response,
});

export default connect(mapStateToProps)(
  authorizeAdmin(requireAuth(ListTmch))
);
