import React from 'react';
import {
    getInfrigementStatusText,
    AWAITING_APPROVAL,
    APPROVED,
    NO_ACTION,
    PURCHASE,
    CLIENT_REVIEW
} from './../infrigementStatusCode'
import { withAlert } from 'react-alert'

class InfrigementStatusDropdownComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            statusArray: [AWAITING_APPROVAL.code, APPROVED.code, NO_ACTION.code, CLIENT_REVIEW.code]
        }

        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.changeInfrigementStatus = this.changeInfrigementStatus.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.infrigementType !== this.props.infrigementType) {
            if (this.props.infrigementType === 'e-commerce') {
                let statusArray = this.state.statusArray;

                statusArray.push(PURCHASE.code)

                this.setState(() => ({
                    statusArray
                }));
            }
        }
    }

    handleStatusChange(e, infrigement, index, infrigementType) {
        this.changeInfrigementStatus(infrigement, index, e.target.value, infrigementType)
    }

    async changeInfrigementStatus(infrigement, infrigementIndex, status, infrigementType) {
        let module,url;
        if (infrigementType == 'domain') {
            module = 'domain';
            url = infrigement.domain || '';
        } else if (infrigementType == 'website') {
            module = 'website';
            url = infrigement.url || '';
        } else if (infrigementType == 'social-media') {
            module = 'social-media';
            url = infrigement.link || '';
        } else if (infrigementType == 'e-commerce') {
            module = 'e-commerce';
            url = infrigement.url || '';
        } else if (infrigementType == 'app-store') {
            module = 'app-store';
            url = infrigement.url || '';
        } else if (infrigementType == 'tmch') {
            module = 'tmch';
            url = infrigement.url || '';
        } else {
            module = 'unknown';
            url = infrigement.url || '';
        }
        await window.axios.post(`/save-bulk-data/update-infrigement-status/${infrigement.id}`, {
            platform: infrigementType,
            status: status,
            statusfrom: infrigement.status,
            company: infrigement.brand?.company || "",
            brand: infrigement.brand?.id || "All",
            url,
            module,
        })
            .then((res) => {
                this.props.alert.show(res.data.message)
            }).catch((error) => {
                this.props.alert.show(error.response.data.message, { type: 'error' })
            })
    }

    renderContent() {

        if (!this.props?.selectedInfrigemnt || (this.props.selectedInfrigemntIndex === undefined || this.props.selectedInfrigemntIndex === null) || !this.props.infrigementType) {
            return <></>
        }

        if (this.state.statusArray.includes(this.props?.selectedInfrigemnt?.status)) {
            return <>
                <select title="Change Status" onChange={e => this.handleStatusChange(e, this.props?.selectedInfrigemnt, this.props.selectedInfrigemntIndex, this.props.infrigementType)}>
                    <option selected={this.props?.selectedInfrigemnt.status === AWAITING_APPROVAL.code ? 'selected' : ''} disabled value="">{AWAITING_APPROVAL.text}</option>
                    <option selected={this.props?.selectedInfrigemnt.status === APPROVED.code ? 'selected' : ''} value={APPROVED.code}>{APPROVED.text}</option>
                    <option selected={this.props?.selectedInfrigemnt.status === NO_ACTION.code ? 'selected' : ''} value={NO_ACTION.code}>{NO_ACTION.text}</option>
                    <option selected={this.props?.selectedInfrigemnt.status === CLIENT_REVIEW.code ? 'selected' : ''} value={CLIENT_REVIEW.code}>{CLIENT_REVIEW.text}</option>
                    {(this.props.infrigementType === 'e-commerce') && <option selected={this.props?.selectedInfrigemnt.status === PURCHASE.code ? 'selected' : ''} value={PURCHASE.code}>{PURCHASE.text}</option>}
                </select>
            </>
        }

        return <p className="bind-width">{getInfrigementStatusText(this.props?.selectedInfrigemnt?.status)}</p>
    }

    render() {
        return <>
            {this.renderContent()}
        </>
    }
}

export default withAlert()(InfrigementStatusDropdownComponent);