import React from "react";
import { Button, Form } from "react-bootstrap";
import * as moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

/**
 * Events : onSaveTmchData, onUpdateTmchData
 * Props: data, recordId (when updating the record), recordIndex (when updating the record)
 */

class TmchFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      brands: [],
      dateFormat: "Y-MM-DD",
      company: "",
      brand: "",
      markName: "",
      reportingDate: "",
      markType: "",
      status: "",
      startDate: "",
      expiryDate: "",
      sunriseFlag: "",
      notifications: "",
      notificationData: [],
      recordId: this.props.recordId,
      errorMessage: {},
    };

    this.handleSaveTmch = this.handleSaveTmch.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company) {
      this.getBrands();
    }
  }

  async getCompanies() {
    await window.axios
      .get("/company", {
        params: {
          perPage: 100,
        },
      })
      .then((res) => {
        this.setState({
          companies: res.data.docs,
        });
      });
  }

  async getBrands() {
    await window.axios
      .get("/brand", {
        params: {
          disabledBrands: true,
          company: this.state.company,
          page: 1,
          perPage: 800,
        },
      })
      .then((res) => {
        this.setState({
          brands: res.data.docs,
        });
      });
  }

  componentDidMount() {
    if (this.props.recordId && this.props.data) {
      this.setState(() => ({
        company: this.props?.data?.company.id,
        brand: this.props?.data?.brand.id,
        markName: this.props?.data?.markName,
        reportingDate: this.props?.data?.reportingDate
          ? moment(this.props?.data?.reportingDate).format(
              this.state.dateFormat
            )
          : "",
        markType: this.props?.data?.markType,
        status: this.props?.data?.status,
        startDate: this.props?.data?.startDate
          ? moment(this.props?.data?.startDate).format(this.state.dateFormat)
          : "",
        expiryDate: this.props?.data?.expiryDate
          ? moment(this.props?.data?.expiryDate).format(this.state.dateFormat)
          : "",
        sunriseFlag: this.props?.data?.sunriseFlag,
        notifications: this.props?.data?.notifications,
      }));
    }

    this.getCompanies();
  }

  async handleSaveTmch() {
    this.setState(() => ({
      errorMessage: null,
    }));

    let endpoint = `/tmch/save`;
    let requestMethod = "post";

    if (this.props.recordId) {
      endpoint = `/tmch/${this.props.recordId}`;
      requestMethod = "put";
    }

    await window.axios[requestMethod](endpoint, {
      company: this.state.company,
      brand: this.state.brand,
      markName: this.state.markName,
      reportingDate: this.state.reportingDate,
      markType: this.state.markType,
      status: this.state.status,
      startDate: this.state.startDate,
      expiryDate: this.state.expiryDate,
      sunriseFlag: this.state.sunriseFlag,
      notifications: this.state.notifications,
    })
      .then((response) => {
        if (this.props.recordId) {
          this.props.onUpdateTmchData(
            {
              ...response.data.data,
            },
            this.props.recordIndex
          );
        } else {
          this.props.onSaveTmchData(response.data.data);
        }

        Swal.fire("Yeah..", response.data.message, "success");
      })
      .catch((error) => {
        const statusCode = error.response.status;
        const errorData = error.response.data;

        if (statusCode === 422) {
          this.setState(() => ({
            errorMessage: errorData.errors,
          }));
        }

        Swal.fire("Oops...", errorData.message, "error");
      });
  }

  async changeInputBox(e, column) {
    let state = {};
    state[column] = e.target.value;
    await this.setState(() => {
      return state;
    });
  }

  render() {
    return (
      <>
        <Form>
          <div className="row">
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Company</Form.Label>
                <select
                  onChange={(e) => this.setState({ company: e.target.value })}
                  className="form-control"
                >
                  <option>Select from dropdown</option>
                  {this.state.companies.map((company, companyIndex) => (
                    <option
                      key={companyIndex}
                      selected={
                        this.state.company === company.id ? "selected" : ""
                      }
                      value={company.id}
                    >
                      {company.name}
                    </option>
                  ))}
                </select>
                <span className="form-text-error-text">
                  {this.state.errorMessage?.company}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Brand</Form.Label>
                <select
                  onChange={(e) => this.setState({ brand: e.target.value })}
                  className="form-control"
                >
                  <option>Select from dropdown</option>
                  {this.state.brands.map((brand, brandKey) => (
                    <option
                      key={brandKey}
                      selected={this.state.brand === brand.id ? "selected" : ""}
                      value={brand.id}
                    >
                      {brand.brandName}
                    </option>
                  ))}
                </select>
                <span className="form-text-error-text">
                  {this.state.errorMessage?.brand}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Mark Name</Form.Label>
                <Form.Control
                  name="markName"
                  onChange={(e) => this.changeInputBox(e, "markName")}
                  value={this.state.markName}
                  type="text"
                  placeholder="Enter Mark Name"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.markName}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Reporting Date</Form.Label>
                <Form.Control
                  name="reportingDate"
                  onChange={(e) => this.changeInputBox(e, "reportingDate")}
                  value={this.state.reportingDate}
                  type="date"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.reportingDate}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Mark Type</Form.Label>
                <Form.Control
                  name="markType"
                  onChange={(e) => this.changeInputBox(e, "markType")}
                  value={this.state.markType}
                  type="text"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.markType}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  name="status"
                  onChange={(e) => this.changeInputBox(e, "status")}
                  value={this.state.status}
                  type="text"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.status}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  name="startDate"
                  onChange={(e) => this.changeInputBox(e, "startDate")}
                  value={this.state.startDate}
                  type="date"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.startDate}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control
                  name="expiryDate"
                  onChange={(e) => this.changeInputBox(e, "expiryDate")}
                  value={this.state.expiryDate}
                  type="date"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.expiryDate}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Sunrise Flag</Form.Label>
                <Form.Control
                  name="sunriseFlag"
                  onChange={(e) => this.changeInputBox(e, "sunriseFlag")}
                  value={this.state.sunriseFlag}
                  type="texg"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.sunriseFlag}
                </span>
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Notifications</Form.Label>
                <Form.Control
                  name="notifications"
                  onChange={(e) => this.changeInputBox(e, "notifications")}
                  value={this.state.notifications}
                  type="text"
                />
                <span className="form-text-error-text">
                  {this.state.errorMessage?.notifications}
                </span>
              </Form.Group>
            </div>
            <div className="col-12">
              <Button onClick={this.handleSaveTmch}>Save</Button>
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default TmchFormComponent;
