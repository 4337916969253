import React, { useEffect } from 'react';
import Footer from '../common/Footer.js'
import SideNav from '../common/SideNav.js'
import TopNav from '../common/TopNav.js'
import { useSelector } from 'react-redux';

const Dashboard = (props) => {

  const isAuthenticated = useSelector(store => store.login.isAuthenticated)
  const user = useSelector(store => store.login.user)

  useEffect(() => {
    if (!isAuthenticated) {
      return props.history.push('/login')
    }
  }, [isAuthenticated, props.history])

  useEffect(() => {
    const userRole = user?.role

    if (userRole === 'Client') {
      props.history.push('client-dashboard')
    }

    if (userRole === 'Admin') {
      props.history.push('analyst-dashboard')
    }

    if (userRole === 'Analyst') {
      props.history.push('data-upload')
    }

  }, [user, props.history])

  return <div className="row">

    <SideNav />

    <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

      <TopNav />

      <div className="main-content-container container-fluid px-4">
        <div className="page-header row no-gutters py-4">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                <span className="text-uppercase page-subtitle">Dashboard</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group row">
                  <label htmlFor="example-date-input" className="col-4 col-form-label">Reporting Date</label>
                  <div className="col-8">
                    <input className="form-control" placeholder="Enter Date" type="date" id="example-date-input" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">TMCH</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">NOTIFICATIONS</th>
                      <th scope="col" className="border-0">MARK EXPIRY DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Liv 52 Syp</td>
                      <td>5</td>
                      <td>25 March 2021</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Liv 52 DS</td>
                      <td>10</td>
                      <td>25 March 2021</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>25</td>
                      <td>25 April 2021</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">Domain</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">HIGH PRIORITY</th>
                      <th scope="col" className="border-0">LOW PRIORITY</th>
                      <th scope="col" className="border-0">ENFORCEMENT</th>
                      <th scope="col" className="border-0">SUCCESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>2</td>
                      <td>1</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">SOCIAL MEDIA</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">HIGH PRIORITY</th>
                      <th scope="col" className="border-0">LOW PRIORITY</th>
                      <th scope="col" className="border-0">ENFORCEMENT</th>
                      <th scope="col" className="border-0">SUCCESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>30</td>
                      <td>40</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">WEBSITE</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">HIGH PRIORITY</th>
                      <th scope="col" className="border-0">LOW PRIORITY</th>
                      <th scope="col" className="border-0">ENFORCEMENT</th>
                      <th scope="col" className="border-0">SUCCESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>30</td>
                      <td>40</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">E-COMMERCE</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">HIGH PRIORITY</th>
                      <th scope="col" className="border-0">LOW PRIORITY</th>
                      <th scope="col" className="border-0">ENFORCEMENT</th>
                      <th scope="col" className="border-0">SUCCESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>30</td>
                      <td>40</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card card-small mb-4">
              <div className="card-header border-bottom">
                <h6 className="m-0">APP STORE</h6>
              </div>
              <div className="card-body p-0 pb-3 text-center">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">#</th>
                      <th scope="col" className="border-0">BRAND</th>
                      <th scope="col" className="border-0">HIGH PRIORITY</th>
                      <th scope="col" className="border-0">LOW PRIORITY</th>
                      <th scope="col" className="border-0">ENFORCEMENT</th>
                      <th scope="col" className="border-0">SUCCESS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Snapdeal</td>
                      <td>30</td>
                      <td>40</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  </div>
}

export default Dashboard;