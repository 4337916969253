import storage from 'local-storage-fallback'

// change website filter action
export const changeClientWebsiteFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientWebsiteFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_WEBSITE_FILTER',
            filter
        })
    }
}

// change domain filter action
export const changeClientDomainFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientDomainFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_DOMAIN_FILTER',
            filter
        })
    }
}

// change social media filter action
export const changeSocialMediaDomainFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientSocialMediaFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_SOCIAL_MEDIA_FILTER',
            filter
        })
    }
}

// change ecommerce filter action
export const changeEcommerceDomainFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientEcommerceFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_ECOMMERCE_FILTER',
            filter
        })
    }
}

// change app store filter action
export const changeAppStoreDomainFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientAppStoreFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_APP_STORE_FILTER',
            filter
        })
    }
}

// change tmch filter action
export const changeTmchFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientTmchFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_TMCH_FILTER',
            filter
        })
    }
}

// change trap purchase filter action
export const changeTrapPurchaseDomainFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientTrapPurchaseFilter', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_TRAP_PURCHASE_FILTER',
            filter
        })
    }
}

// change app store filter action
export const changeClientDateFilter = (filter) => {
    return (dispatch) => {
        storage.setItem('clientDateFilterReducer', JSON.stringify(filter))

        dispatch({
            type: 'CHANGE_CLIENT_DATE_FILTER',
            filter
        })
    }
}