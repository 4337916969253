import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import { loginReducer } from "./reducers/loginReducer";
import { responseReducer } from "./reducers/responseReducer";
import { brandsSelectionReducer } from "./reducers/brandsSelectionReducer";
import { companySelectionReducer } from "./reducers/companySelectionReducer";
import { clientDateFilterReducer } from './reducers/clientDateFilterReducer'
import {
    clientWebsiteFilterReducer,
    clientDomainFilterReducer,
    clientSocialMediaFilterReducer,
    clientEcommerceFilterReducer,
    clientAppStoreFilterReducer,
    clientTmchFilterReducer,
    clientTrapPurchaseFilterReducer,
} from "./reducers/clientFilterReducer";
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    login: loginReducer,
    response: responseReducer,
    selectedBrand: brandsSelectionReducer,
    selectedCompany: companySelectionReducer,
    clientWebsiteFilter: clientWebsiteFilterReducer,
    clientDomainFilter: clientDomainFilterReducer,
    clientSocialMediaFilter: clientSocialMediaFilterReducer,
    clientEcommerceFilter: clientEcommerceFilterReducer,
    clientAppStoreFilter: clientAppStoreFilterReducer,
    clientTrapPurchaseFilter: clientTrapPurchaseFilterReducer,
    clientDateFilter: clientDateFilterReducer,
    clientTmchFilter: clientTmchFilterReducer,
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store;