import React from 'react'
import ClientSideNav from '../common/ClientSideNav'
import TopNav from '../common/TopNav'

const NotAccessablePage = (props) => {
    return <div className="row">
        <ClientSideNav />

        <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
        <TopNav />

        <div className="main-content-container container-fluid px-4">
            You do not have access to this module for selected Brand.
        </div>
        </main>
    </div>
}

export default NotAccessablePage;