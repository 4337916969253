import React from 'react';
import analystTemplate from './../../../hoc/analystTemplate';
import StagingDataPresentation from './StagingDataPresentation';
class StagingDataContainer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            selectedPlatform: null,
            selectedCompany: null,
            selectedBrand: null,
            records: [],
            selectedDraft: null,
            draftsChangeDetector: ""
        }

        this.updateSelectedPlatform = this.updateSelectedPlatform.bind(this);
        this.updateSelectedCompany = this.updateSelectedCompany.bind(this);
        this.updateSelectedBrand = this.updateSelectedBrand.bind(this);
        this.addNewStagingRecords = this.addNewStagingRecords.bind(this);
        this.updateRecordValue = this.updateRecordValue.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemoveRow = this.handleRemoveRow.bind(this);
        this.draftSingleRow = this.draftSingleRow.bind(this);
        this.updateSelectedDraft = this.updateSelectedDraft.bind(this);
        this.fetchDraftRecords = this.fetchDraftRecords.bind(this);
        this.removeSpecificInfrigement = this.removeSpecificInfrigement.bind(this);
        this.submitDraft = this.submitDraft.bind(this);
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevState.selectedDraft?.key !== this.state.selectedDraft?.key){
            this.fetchDraftRecords()
        }
    }

    async fetchDraftRecords(){
        await this.setState(() => ({
            records: []
        }));

        if(!this.state.selectedDraft?.key){
            return;
        }

        await window.axios.get(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/fetch-draft-data/${this.state.selectedDraft?.key}`)
        .then(async (response) => {
            let draftItems = response.data.draftItems;
            let records = []

            for(let draftItem of draftItems){
                let record = {
                    ...draftItem?.data, _id: draftItem._id
                }

                records.push(record);
            }

            await this.setState(() => ({
                records
            }))
        })
        .catch((err) => {
            console.log(err)
        });
    }

    async updateSelectedPlatform(platform){
        await this.setState(() => ({
            selectedPlatform: platform
        }))
            
        let key = this.state.selectedPlatform?.key;

        this.props.updateSelectedPlatformKey(key);
    }

    async updateSelectedDraft(draft){
        await this.setState(() => ({
            selectedDraft: draft
        }))
    }

    async updateSelectedCompany(company){
        await this.setState(() => ({
            selectedCompany: company
        }))
    }

    async updateSelectedBrand(brand){
        await this.setState(() => ({
            selectedBrand: brand
        }))
    }

    updateRecordValue = async (key, value, recordIndex) =>{
        let records = [...this.state.records];

        records[recordIndex] = {...records[recordIndex], [key]: value};

        await this.setState(() => ({
            records
        }))
    }

    handleInputChange = async (id, key, recordIndex) => {
        const input = document.getElementById(id)

        if(!input){
            return;
        }

        let file = input.files[0]

        if(!file){
            return;
        }

        let formData = new FormData();
    
        formData.append("files", file);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin':'true'
            }
        };

        await window.axios.post('/upload-images-to-s3', 
        formData, options)
        .then(async (res) => {
            let upoadedFile = res.data.Data[0];
            let data = [...this.state.records];
            data[recordIndex] = {
                ...data[recordIndex], 
                [key]: upoadedFile.uploadedImage,
                imageUrl : upoadedFile.Location,
                s3BucketName : upoadedFile.Bucket,
                s3FullImagePath : upoadedFile.Key,
                updated : true
            };

            await this.setState(() => ({
                records: data
            }));
        });
    }
 
    async addNewStagingRecords(){
        // await window.axios.get(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/test`);
        let selectedKey = this.props[this.props.selectedKey];

        let newRecord = {};
        let records = [...this.state.records];

        for(let key of selectedKey){
            newRecord[key.key] = ""
        }

        // add 10 records when click add new records.
        for(let i = 0; i < 5; i++ ){
            records.push(newRecord);
        }

        await this.setState(() => ({
            records
        }))
    }

    async removeSpecificInfrigement(infrigementId){
        return await window.axios.delete(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/remove-specific-infrigement/${infrigementId}`);
    }

    async handleRemoveRow(recordIndex){
        let records = [...this.state.records];

        if(records[recordIndex]?._id){
            await this.removeSpecificInfrigement(records[recordIndex]?._id).then(response => {
                records.splice(recordIndex, 1);
            }).catch((error) => {
                console.log(error);
            })
        }else{
            records.splice(recordIndex, 1);
        }
        
        await this.setState(() => ({
            records
        }))
    }

    async draftSingleRow(recordIndex){
        if(this.state.selectedDraft === null){
            await this.saveDraft();
        }

        if(typeof this.state.selectedDraft === 'object'){
            let fileId = this.state.selectedDraft?.key;

            let records = [...this.state.records];

            let url = `${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/`;
            
            if(records[recordIndex]._id){
                url += `update-single-infrigement/${records[recordIndex]._id}`;
            }else{
                url += 'save-single-infrigement';
            }

            await window.axios.post(`${url}`, {
                data:{...this.state.records[recordIndex]}, 
                fileId,
                brand: this.state.selectedBrand?.id,
                company: this.state.selectedBrand?.id,
                platform: this.state.selectedPlatform?.key 
            }).then(async (response) => {
                let _id = response.data._id;

                records[recordIndex] = {...records[recordIndex], _id, fileId};

                await this.setState(() => ({
                    records
                }))
            }).catch((error)=> {
                console.log(error)
            })
        }   
    }

    async saveDraft(){
        await window.axios.post(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/create-a-new-draft`, {
            brand: this.state.selectedBrand?.id,
            company: this.state.selectedBrand?.id,
            platform: this.state.selectedPlatform?.key 
        }).then(async (response) => {
            await this.setState(() => ({
                selectedDraft:{key: response.data?._id, name: response.data?.fileName}
            }))
        }).catch((error) => {
            console.log(error)
        });
    }

    async submitDraft(){
        let fileId = this.state.selectedDraft?.key;

        if(!fileId){
            return;
        }
        
        await window.axios.post(`${process.env.REACT_APP_STAGING_DATA_SERVICE_URL}/api/v1/submit-draft/${fileId}`, {
            brand: this.state.selectedBrand?.id,
            company: this.state.selectedBrand?.id,
            platform: this.state.selectedPlatform?.key 
        }).
        then(async (response) => {
            await this.setState(() => ({
                draftsChangeDetector: Math.random().toString(16).slice(2),
                selectedDraft: null
            }))

            this.props.alert.show("Draft submitted successfully.");
        }).catch((err) => {
            console.log(err);
        })
    }

    render(){
        return (
            <>
                <StagingDataPresentation 
                    submitDraft = {() => this.submitDraft()}
                    updateSelectedDraft = {(draft) => this.updateSelectedDraft(draft)}
                    draftSingleRow = {(recordIndex) => this.draftSingleRow(recordIndex)}
                    handleRemoveRow = {(recordIndex) => this.handleRemoveRow(recordIndex)}
                    handleInputChange = {(id, key, recordIndex) => this.handleInputChange(id, key, recordIndex)}
                    updateRecordValue = {(key, value, recordIndex) => this.updateRecordValue(key, value, recordIndex)}
                    updateSelectedBrand = {(brand) => this.updateSelectedBrand(brand)}
                    updateSelectedCompany = {(company) => this.updateSelectedCompany(company)}
                    updateSelectedPlatform = {(platform) => this.updateSelectedPlatform(platform)}
                    addNewStagingRecords = {() => this.addNewStagingRecords()}
                    {...this.state} 
                    {...this.props} 
                />
            </>
        );
    }
}

export default analystTemplate(StagingDataContainer);