import React from "react";
import analystTemplate from "./../../../hoc/analystTemplate";
import { Link } from "react-router-dom";

class AnalystDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {
        companies: 0,
        users: 0,
        brands: 0,
      },
    };
  }

  componentDidMount() {
    this.getDashboard();
  }

  async getDashboard() {
    await window.axios
      .get("/dashboard/analyst")
      .then((res) => {
        this.setState({ dashboardData: res.data });
      })
      .catch((error) => {
        //   this.setState({isLoading: false})
      });
  }

  renderHtml() {
    return (
      <>
        <div className="page-header row no-gutters py-4">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-sm-4 text-sm-left mb-2">
                <span className="text-uppercase page-subtitle">
                  Analyst Dashboard
                </span>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row">
              <div className="col-4">
                <div className="card text-white bg-primary o-hidden h-100">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <i className="fa fa-fw fa-user"></i>
                    </div>
                    <div className="mr-5">
                      {this.state?.dashboardData?.users} Users
                    </div>
                  </div>
                  <Link
                    to="/users"
                    className="card-footer text-white clearfix small z-1"
                    href="#"
                  >
                    <span className="float-left">View Details</span>
                    <span className="float-right">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="card text-white bg-warning o-hidden h-100">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <i className="fa fa-fw fa-building"></i>
                    </div>
                    <div className="mr-5">
                      {this.state?.dashboardData?.companies} companies
                    </div>
                  </div>
                  <Link
                    to="/companies"
                    className="card-footer text-white clearfix small z-1"
                    href="#"
                  >
                    <span className="float-left">View Details</span>
                    <span className="float-right">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-4">
                <div className="card text-white bg-success o-hidden h-100">
                  <div className="card-body">
                    <div className="card-body-icon">
                      <i className="fa fa-fw fa-list-ol"></i>
                    </div>
                    <div className="mr-5">
                      {this.state?.dashboardData?.brands} brands
                    </div>
                  </div>
                  <Link
                    to="/brands"
                    className="card-footer text-white clearfix small z-1"
                    href="#"
                  >
                    <span className="float-left">View Details</span>
                    <span className="float-right">
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return <>{this.renderHtml()}</>;
  }
}

export default analystTemplate(AnalystDashboard);
