import React, {Component} from 'react'
import { Link } from 'react-router-dom';

class SideNav extends Component {
    render (){
        return <aside className="main-sidebar col-12 col-md-3 col-lg-2 px-0">
        <div className="main-navbar">
          <nav className="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
            <Link className="navbar-brand w-100 mr-0" href="#" to="/" style={{lineHeight: "25px"}}>
              <div className="d-table m-auto">
                <img id="main-logo" className="d-inline-block align-top mr-1" style={{maxWidth: "50px"}} src="https://static.wixstatic.com/media/dbc393_7e1659bad6044278b30ff8d6d4667516~mv2_d_2963_1605_s_2.jpg/v1/fill/w_111,h_60,al_c,q_80,usm_0.66_1.00_0.01/LdotR_Logo.webp" alt="LDotR" />

              </div>
            </Link>
            <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
              <i className="material-icons">&#xE5C4;</i>
            </a>
          </nav>
        </div>
      
        
        <div className="nav-wrapper">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="nav-link " to="/analyst-dashboard">
                <span>ANALYST DASHBOARD</span>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="tmch-dashboard.html">
                <span>TMCH</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="domain-dashboard.html">
                <span>DOMAIN</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="social-media-dashboard.html">
                <span>SOCIAL MEDIA</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="website-dashboard.html">
                <span>WEBSITE</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="ecommerce-dashboard.html">
                <span>E-COMEERCE</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link " href="app-store-dashboard.html">
                <span>APP STORE</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    }
}


export default SideNav;