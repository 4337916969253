import React from "react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    if (!payload[0]?.payload?.name) {
      return "";
    }
    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid #ccc",
          padding: "5px",
          borderRadius: "4px",
          fontSize: "12px",
        }}
      >
        <strong>{payload[0]?.payload?.name || ""}</strong>: {payload[0].value}
      </div>
    );
  }
  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div>
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          backgroundColor: payload[0]?.payload?.fill || "transparent",
          borderRadius: "50%",
          marginRight: "5px",
        }}
      ></span>
      <strong>{payload[0]?.payload?.name && "Enforcement Percentage"}</strong>
    </div>
  );
};

const RadialChart = ({
  modal,
  title,
  openModel,
  currentMonth,
  totalInfringements,
  subTitle,
}) => {
  const data = [
    {
      name: "Enforcements",
      value: currentMonth ?? 0,
      fill: "#F09990",
    },
    {
      name: "",
      value: 100,
      fill: "#CBCBCB",
    },
  ];

  return (
    <div
      className="position-relative w-100 h-100 d-flex flex-column"
      style={{
        padding: `${modal ? "1px" : "16px"}`,
        width: "100%",
        height: "inherit",
      }}
    >
      {title && (
        <div className="text-center mb-2 position-relative">
          <div>
            <span className="uppercase-text">
              <strong>{title}</strong>
            </span>

            {subTitle && (
              <>
                <br />
                <span
                  class=" text-uppercase"
                  style={{ fontSize: "13px", color: "#CBCBCE" }}
                >
                  <strong>{subTitle}</strong>
                </span>
              </>
            )}
          </div>

          {openModel && (
            <button
              className="position-absolute btn btn-outline-light btn-sm non-print"
              style={{ top: "-8px", right: 0 }}
              onClick={(e) => {
                e.preventDefault();
                openModel();
              }}
            >
              <img
                src="/icons/popup.svg"
                alt="bar-popup-icon"
                width={22}
                height={22}
              />
            </button>
          )}
        </div>
      )}

      <div className="flex-grow-1 position-relative">
        <div style={{ marginTop: modal ? "20px" : "50px" }}>
          <ResponsiveContainer width="100%" height={modal ? 580 : 320}>
            <RadialBarChart
              startAngle={180}
              endAngle={0}
              innerRadius="60%"
              outerRadius="90%"
              data={data}
            >
              <RadialBar dataKey="value" cornerRadius={5} fill="#90A4AE" />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                wrapperStyle={{
                  top: 0,
                  maxWidth: "100%",
                  whiteSpace: "normal",
                  textAlign: "center",
                }}
                iconSize={10}
                align="center"
                iconType="circle"
                verticalAlign="top"
                content={<CustomLegend />}
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>

        {/* Centered Content */}
        <div
          className="position-absolute top-50 start-50 translate-middle text-center"
          style={{
            zIndex: 10,
            width: "100%",
          }}
        >
          <div
            className={`fill-foreground ${
              modal ? "text-5xl" : "text-3xl"
            } font-bold`}
            style={{
              fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            {currentMonth ?? 0}%
          </div>
          <div className={`fill-muted-foreground ${modal ? "text-lg" : ""}`}>
            Enforcements
          </div>
          {totalInfringements !== undefined && (
            <div
              style={{
                fontSize: "0.875rem",
                color: "#888",
                marginTop: "0.25rem",
              }}
            >
              Total: {totalInfringements}
            </div>
          )}
        </div>
      </div>

      {modal && (
        <div
          className="d-inline-flex position-absolute non-print"
          style={{ bottom: 0, right: "17px" }}
        >
          <button
            className="btn btn-outline-light btn-sm mr-3"
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            <img
              src="/icons/print.svg"
              alt="bar-print-icon"
              width={25}
              height={25}
            />
          </button>
          {/* <button
            disabled
            className="btn btn-outline-light btn-sm"
            onClick={(e) => e.preventDefault()}
          >
            <img
              src="/icons/download.svg"
              alt="bar-download-icon"
              width={25}
              height={25}
            />
          </button> */}
        </div>
      )}
    </div>
  );
};

export default RadialChart;
