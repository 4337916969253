import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import 'bootstrap/dist/css/bootstrap.min.css';


function PaginationComponentV1({ totalRecords, itemsPerPage, currentPage, onPageChange, displayPagesCount }) {
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const renderPaginationItems = () => {
        let startPage = Math.max(1, currentPage - Math.floor(displayPagesCount / 2));
        let endPage = Math.min(totalPages, startPage + displayPagesCount - 1);

        if (endPage - startPage + 1 < displayPagesCount) {
            startPage = Math.max(1, endPage - displayPagesCount + 1);
        }

        const pageRange = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

        let items = [];

        items.push(
            <Pagination.First key="first" onClick={() => onPageChange(1)} />,
            <Pagination.Prev key="prev" onClick={() => onPageChange(Math.max(1, currentPage - displayPagesCount))} />
        );

        if (startPage > 2) {
            items.push(<Pagination.Ellipsis key="startEllipsis" />);
        }

        items = items.concat(pageRange.map(page => (
            <Pagination.Item
                key={page}
                active={currentPage === page}
                onClick={() => onPageChange(page)}
            >
                {page}
            </Pagination.Item>
        )));

        if (endPage < totalPages - 1) {
            items.push(<Pagination.Ellipsis key="endEllipsis" />);
        }

        items.push(
            <Pagination.Next key="next" onClick={() => onPageChange(Math.min(totalPages, startPage + displayPagesCount))} />,  // Adjusted this line
            <Pagination.Last key="last" onClick={() => onPageChange(totalPages)} />
        );

        return items;
    };

    return (
        <Pagination>{renderPaginationItems()}</Pagination>
    );
}

export default PaginationComponentV1;
