import React from 'react';
import propTypes from 'prop-types'

class BrandDropdownComponent extends React.Component {
    constructor(props){
        super(props);

        this.initialBrand = {id: "", label: ""};

        this.state = {
            selectedBrand: this.initialBrand,
            brands: []
        }

        this.getBrands = this.getBrands.bind(this);
        this.changeBrand = this.changeBrand.bind(this);
    }

    componentDidMount(){
        this.getBrands();
    }

    componentDidUpdate(prevProps, prevState){
        // if company changes, brand list will be refetched
        if(this.props.selectedCompany !== prevProps.selectedCompany){
            this.getBrands();
            this.changeBrand(this.initialBrand);
        }
    }

    async getBrands(){

        if(!this.props.selectedCompany && !this.props.fetchWithoutCompany){
            return;
        }

        let params = {
            company: this.props.selectedCompany,
            page:1,
            perPage:100
        }

        if(this.props.fetchOnlyEnabledBrands){
            params.status = this.props.fetchOnlyEnabledBrands;
        }

        await window.axios.get('/brand', { params }).then(res => {
            this.setState({
                brands: res.data.docs
            })
        });
    }

    async changeBrand(brand){
        await this.setState(() => ({
            selectedBrand: brand
        }))

        this.props.onBrandChanged(this.state.selectedBrand);
    }

    render(){
        return (
            <>
                <select className={this.props.className} onChange={(e) => this.changeBrand(JSON.parse(e.target.value))}>
                    <option value={JSON.stringify(this.initialBrand)}>Select Brand</option>
                    {this.state.brands.map((brand, index) => (
                        <option key={index} value={JSON.stringify({id: brand.id, label: brand.brandName})}>{brand.brandName}</option>
                    ))}
                </select>
            </>
        )
    }
}

BrandDropdownComponent.defaultProps = {
    fetchWithoutCompany: false,
    fetchOnlyEnabledBrands: true,
};

BrandDropdownComponent.propTypes = {
    selectedCompany: propTypes.string,
    onBrandChanged: propTypes.func,
    className: propTypes.string,
    fetchWithoutCompany: propTypes.bool,
    fetchOnlyEnabledBrands: propTypes.bool
}

export default BrandDropdownComponent;