import React from "react"
import { connect } from "react-redux"
import { logoutUser} from '../store/actions/authActions';

const requireAuth = (ChildComponent) => {

    class AuthHoc extends React.Component {

        constructor(props){
            super(props)

            this.state = {
                authorised: true,
            }
        }

        componentDidMount(){
            this.checkValidToken()
            
            window.socket.on('tokenCheckResponse', (isAuthenticated) => {
                if(!isAuthenticated){
                    this.props.dispatch(logoutUser())
                    setTimeout(() => {
                        alert('Session timed out. Please login again.')
                    }, 1000); 
                }
            })

            // setInterval(()=>{
            //     if(this.props.login.isAuthenticated){
            //         window.socket.emit('checkIfTokenIsValid', {
            //             token: this.props.login?.token
            //         })
            //     }
            // }, 1000 * 2)

            this.checkAuth()
        }

        async checkValidToken(){
            await window.axios.post(`users/verify-token/${this.props.login?.token}`)
            .then((response) => {
                if(!response.data.status){
                    alert('Session timed out. Please login again.')
                    this.props.dispatch(logoutUser())
                }
            })
            .catch((err) =>{
                alert('Session timed out. Please login again.')
                this.props.dispatch(logoutUser())
            })
        } 

        componentDidUpdate(){
            this.checkAuth()
        }

        checkAuth(){
            if(!this.props.login.isAuthenticated){
                return this.props.history.push('/login');
            }
            //check if it's is2FaVerifiedUser then check it is verified or not
            if (this.props.login.is2FaVerifiedUser && !this.props.login.isVerified) {
                return this.props.history.push('/login');
            }
        }

        render(){
            return (
                <ChildComponent authorised={this.state.authorised} {...this.props} />
            )
        }
    }

    const mapStateToProps = (state) => ({ login: state.login, response: state.response, user: state.user })

    return connect(mapStateToProps)(AuthHoc);
}

export default requireAuth;