import React, {useState, useEffect} from 'react'
import '../styles/login.css'
import { useHistory } from "react-router-dom"

const Unauthorised = (props) => {
    let history = useHistory()
    return <div className="row">
        <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0">
            <div className="main-content-container container-fluid">
            <p className="form-signin">You are trying to access Unauthorised Page <a onClick={(e) => history.goBack() } href="javascript:;">Go Back</a></p>
            </div>    
        </main>
    </div>
}

export default Unauthorised;