import React from 'react';
import propTypes from 'prop-types';

class CompanyDropdownComponent extends React.Component {
    constructor(props){
        super(props);

        this.initialCompany = {id: "", label: ""}
        this.state = {
            companies: [],
            selectedCompany: null
        }

        this.getCompanies = this.getCompanies.bind(this);
        this.changeCompany = this.changeCompany.bind(this);
    }

    componentDidMount(){
        this.getCompanies();
    }

    async getCompanies(){
        await window.axios.get('/company', {
            params: {
                perPage: 100
            }
        }).then(res => {
            this.setState({
                companies: res.data.docs
            })
        });
    }

    async changeCompany(value){
        await this.setState(() => ({
            selectedCompany: value
        }))

        this.props.onCompanyChanged(this.state.selectedCompany);
    }

    render(){
        return (
            <>
                <select className={this.props.className} onChange={(e) => this.changeCompany(JSON.parse(e.target.value))}>
                    <option value={JSON.stringify(this.initialCompany)}>Select Company</option>
                    {this.state.companies.map((company, index) => (
                        <option key={index} value={JSON.stringify({id:company.id, label: company.name})}>{company.name}</option>
                    ))}
                </select>
            </>
        )
    }
}

CompanyDropdownComponent.propTypes = {
    onCompanyChanged: propTypes.func,
    className: propTypes.string
}

export default CompanyDropdownComponent;