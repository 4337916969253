import { getSelectedCompany } from "../storage";

const initialState = getSelectedCompany() ? getSelectedCompany() : { id: null, companyName: 'All' }

export const companySelectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_COMPANY_SELECTION': {
            return {
                id: action.companyData.id,
                companyName: action.companyData.companyName,
            }
        }
        default: {
            return state
        }
    }
}