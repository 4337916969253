import storage from 'local-storage-fallback'

// getch user details from localstorage
export const getLoggedInUserDetails = () => {
    try {
        let user = storage.getItem('user');
        let token = storage.getItem('token');
        let isAuthenticated = storage.getItem('isAuthenticated');
        let is2FaVerifiedUser = storage.getItem('is2FaVerifiedUser');
        let isVerified = storage.getItem('isVerified');

        if (user !== null) {
            user = JSON.parse(user)
        }

        if (isAuthenticated !== null) {
            isAuthenticated = JSON.parse(isAuthenticated)
        }

        if (token !== null) {
            token = JSON.parse(token)
        }

        if (is2FaVerifiedUser !== null) {
            is2FaVerifiedUser = JSON.parse(is2FaVerifiedUser)
        }

        if (isVerified !== null) {
            isVerified = JSON.parse(isVerified)
        }

        return {
            user,
            token,
            isAuthenticated,
            is2FaVerifiedUser,
            isVerified
        };
    } catch (err) {
        return undefined;
    }
}

// get selected brand from storage

export const getSelectedBrand = () => {
    try {
        let selectedBrand = storage.getItem('selectedBrand');

        if (selectedBrand !== null) {
            selectedBrand = JSON.parse(selectedBrand)
        }

        return selectedBrand;
    } catch (e) {
        return undefined;
    }
}


export const getSelectedCompany = () => {
    try {
        let selectedCompany = storage.getItem('selectedCompany');

        if (selectedCompany !== null) {
            selectedCompany = JSON.parse(selectedCompany)
        }

        return selectedCompany;
    } catch (e) {
        return undefined;
    }
}

// get client website filter
export const getClientWebsiteFilter = () => {
    try {
        let filter = storage.getItem('clientWebsiteFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client website filter
export const getClientDomainFilter = () => {
    try {
        let filter = storage.getItem('clientDomainFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client social media filter
export const getClientSocialMediaFilter = () => {
    try {
        let filter = storage.getItem('clientSocialMediaFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client ecommerce filter
export const getClientEcommerceMediaFilter = () => {
    try {
        let filter = storage.getItem('clientEcommerceFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client app store filter
export const getClientAppStoreMediaFilter = () => {
    try {
        let filter = storage.getItem('clientAppStoreFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client tmch filter
export const getClientTmchMediaFilter = () => {
    try {
        let filter = storage.getItem('clientTmchFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

export const getClientTrapPurchaseMediaFilter = () => {
    try {
        let filter = storage.getItem('clientTrapPurchaseFilter');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}

// get client app store filter
export const getClientDateFilter = () => {
    try {
        let filter = storage.getItem('clientDateFilterReducer');

        if (filter !== null) {
            filter = JSON.parse(filter)
        }

        return filter;
    } catch (e) {
        return undefined;
    }
}