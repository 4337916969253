import * as moment from 'moment'

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'hh:mm:ss a'

export const castToTimeFormat = (date) => {
    return moment(date).isValid() ? moment(date).format(TIME_FORMAT) : ''
}

export const castToDefaultDateFormat = (date) => {
    date = moment(date)
    return date.isValid() ? moment(date).format(DEFAULT_DATE_FORMAT): ''
}

export const castToServerDateFormat = (date) => {
    date = moment(date)
    return date.isValid() ? moment(date).format(SERVER_DATE_FORMAT) : ''
}

