import React, { Component } from 'react'
import Footer from '../../common/Footer.js'
import AdminSideNav from '../../common/AdminSideNav.js'
import UnauthorisedPage from '../Unauthorised'
import TopNav from '../../common/TopNav.js'
import Select from 'react-select';
import { Button, Modal, Form, Table, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import requireAuth from '../../hoc/requireAuth';
import authorizeAdmin from '../../hoc/authorizeAdmin';
import PaginationComponentV1 from '../../common/PaginationComponentV1';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import Loader from "react-loader-spinner";
import { withAlert } from 'react-alert';
import './../../itemsAlignment.css';

class ListCompany extends Component {

    constructor(props) {
        super(props)

        this.state = {
            filteredCountry: '',
            filteredState: '',
            availableStatesFilter: [],
            availableCountryFilter: [],
            availableCityFilter: [],
            filteredCity: '',
            filteredCompany: '',
            selectedCompany: null,
            authorised: true,
            companies: [],
            showModal: false,
            name: '',
            legalName: '',
            address: '',
            city: '',
            state: '',
            pin: '',
            country: '',
            gst: '',
            contractDate: '',
            startDate: '',
            endDate: '',
            isChild: false,
            parentCompany: [],
            contacts: [],
            errorMessage: null,
            pagination: {
                totalDocs: null,
                totalPages: null,
                page: 1,
                pagingCounter: 1,
                hasPrevPage: false,
                hasNextPage: true,
                prevPage: null,
                nextPage: 1
            },
            perPage: 20,
            sortBy: '_id',       // column to sort by
            sortDirection: -1,   // sort direction (asc or desc)
            isLoading: false,
            hasMoreDomainData: true,
            companyOption: [],
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.changeInputBox = this.changeInputBox.bind(this);
        this.addNewContact = this.addNewContact.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.listAllCompanies = this.listAllCompanies.bind(this);
        this.handleLimit = this.handleLimit.bind(this);
        this.sortTable = this.sortTable.bind(this);
        this.inputSearchBox = this.inputSearchBox.bind(this);
        this.handleSearchButton = this.handleSearchButton.bind(this);
        this.myPageChange = this.myPageChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(option) {
        this.setState({ parentCompany: [option] });
    }

    myPageChange(newPage) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                page: newPage
            }
        });
    }

    handleSearchButton() {
        this.listAllCompanies(true);
    };

    inputSearchBox(search) {
        this.setState({ search });
    };

    sortTable = (column) => {
        let { sortBy, sortDirection } = this.state;

        if (sortBy === column) {
            sortDirection = sortDirection === 1 ? -1 : 1;
        } else {
            sortBy = column;
            sortDirection = 1;
        }

        // Update state
        this.setState({ sortBy, sortDirection });
    };

    handleLimit(e) {
        this.setState({ perPage: e })
        this.setState(prevState => ({
            pagination: {
                ...prevState.pagination,
                page: 1
            }
        }));
    }

    async handleCompanyFilterChange(e) {
        await this.setState(() => ({
            filteredCompany: e.target.value
        }));
    }


    async handleCountryFilterChange(e) {
        await this.setState(() => ({
            filteredCountry: e.target.value
        }));
    }

    async handleCityFilterChange(e) {
        await this.setState(() => ({
            filteredCity: e.target.value
        }));
    }

    async handleStateFilterChange(e) {
        await this.setState(() => ({
            filteredState: e.target.value
        }));
    }

    renderLoader() {
        if (this.state.isLoading) {
            return <div className="text-center">
                <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={10}
                    width={200}
                    timeout={5000} //3 secs
                />
            </div>
        }
    }

    async resetForm() {
        await this.setState(() => {
            return {
                name: '',
                legalName: '',
                address: '',
                city: '',
                state: '',
                pin: '',
                country: '',
                gst: '',
                contractDate: '',
                startDate: '',
                endDate: '',
                contacts: [],
                errorMessage: null
            }
        })
    }

    async saveCompany() {
        let params = {
            name: this.state.name,
            legalName: this.state.legalName,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            pin: this.state.pin,
            country: this.state.country,
            gst: this.state.gst,
            contractDate: this.state.contractDate,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            contacts: this.state.contacts,
            isChild: this.state.isChild,
            parentCompany: this.state.isChild ? this.state.parentCompany.map((item) => ({ _id: item.value, name: item.label, })) : []
        }

        await this.setState(() => {
            return {
                errorMessage: null
            }
        })

        let endPoint = '/company';
        if (this.state.selectedCompany) {
            params.companyId = this.state.selectedCompany
            endPoint += `/edit`;
        }

        await window.axios.post(`${endPoint}`, params)
            .then((response) => {
                this.handleCloseModal()
                this.resetForm()
                this.listAllCompanies(true)
                if (this.state.selectedCompany) {
                    this.props.alert.show("Company Updated")
                } else {
                    this.props.alert.show('Your action completed successfully !')
                }
            })
            .catch((error) => {
                let statusCode = error.response.status
                let errors = error.response.data.errors
                let errorMessage = error.response.data.message

                if (statusCode === 422) {
                    this.props.alert.show(errorMessage, { type: 'error' })
                }
            })
    }

    async changeInputBox(e, column) {

        let state = {}
        state[column] = e.target.value
        await this.setState(() => {
            return state
        })
    }

    async changeContactsInputBox(e, column, contactIndex) {
        let contacts = this.state.contacts
        contacts[contactIndex][column] = e.target.value

        await this.setState(() => {
            return {
                contacts
            }
        })
    }

    componentDidMount() {
        this.listAllCompanies()
        this.getUniqueCountries()
        this.getUniqueCities()
        this.getUniqueStates()
    }

    componentDidUpdate(prevProps, prevState) {
        // This runs when the component updates
        // You can check if specific state or props changed and perform side effects accordingly
        if (this.state.pagination.page !== prevState.pagination.page || this.state.perPage !== prevState.perPage || this.state.sortBy !== prevState.sortBy || this.state.sortDirection !== prevState.sortDirection) {
            this.listAllCompanies();
        }
    }

    async getUniqueStates() {
        await window.axios.get('/company/fetch-unique-states').then(async (response) => {
            await this.setState(() => ({
                availableStatesFilter: response.data
            }
            ))
        }).catch(() => {
            console.log('error')
        })
    }
    async getUniqueCountries() {
        await window.axios.get('/company/fetch-unique-countries').then(async (response) => {
            await this.setState(() => ({
                availableCountryFilter: response.data
            }
            ))
        }).catch(() => {
            console.log('error')
        })
    }
    async getUniqueCities() {
        await window.axios.get('/company/fetch-unique-cities').then(async (response) => {
            await this.setState(() => ({
                availableCityFilter: response.data
            }
            ))
        }).catch(() => {
            console.log('error')
        })
    }



    async listAllCompanies(reArrangeRecords = false) {

        let reqData = {
            page: this.state.pagination.page,
            perPage: this.state.perPage,
        }

        reqData.sortBy = this.state.sortBy;
        reqData.sortDirection = this.state.sortDirection;
        if (reArrangeRecords) {
            reqData.page = 1
        }
        reqData.search = this.state.search;

        this.setState(() => ({ isLoading: true }));

        await window.axios.get('/company/v1/allRecords', {
            params: reqData
        }).then(res => {
            this.setState(() => ({
                companies: res.data.docs,
                isLoading: false,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreDomainData: res.data.hasNextPage
            }));
        });
    }

    async showEditPopUp(company) {
        await this.setState((prevState) => {
            return {
                showModal: true,
                selectedCompany: company.id,
                name: company.name,
                legalName: company.legalName,
                address: company.address,
                city: company.city,
                state: company.state,
                pin: company.pin,
                country: company.country,
                gst: company.gst,
                contractDate: company.contractDate,
                startDate: company.startDate,
                endDate: company.endDate,
                contacts: company.contacts,
                isChild: company.isChild,
                parentCompany: company.isChild ? company.parentCompany.map((child) => ({ label: child.name, value: child._id })) : []
            }
        })

        await window.axios
            .get("/company", {
                params: {
                    perPage: 100,
                    showParentCompany: true,
                    excludeIds: company._id
                },
            })
            .then((res) => {
                let options = [];
                options = res.data.docs.map((company) => {
                    return { value: company._id, label: company.name }
                })
                this.setState({
                    companyOption: options,
                });
            });
    }

    async removeCompany(company) {
        await window.axios.delete(`/company/delete/${company?.id}`)
            .then((response) => {
                this.listAllCompanies(true)
                this.props.alert.show(response.data.message)
            })
            .catch((error) => {
                this.props.alert.show('Delete brand first', { type: 'error' })
            })
    }

    async handleOpenModal() {
        this.setState((prevState) => {
            return {
                showModal: true,
                selectedCompany: false
            }
        });

        await window.axios
            .get("/company", {
                params: {
                    perPage: 100,
                    showParentCompany: true
                },
            })
            .then((res) => {
                let options = [];
                options = res.data.docs.map((company) => {
                    return { value: company._id, label: company.name }
                })
                this.setState({
                    companyOption: options,
                });
            });
    }

    handleCloseModal() {
        this.setState((prevState) => {
            return {
                showModal: false
            }
        });
        this.resetForm();
        this.setState({ isChild: false, parentCompany: [] });
    }

    renderHtml() {
        if (!this.state.authorised) {
            return <UnauthorisedPage />
        }

        return <div className="row">

            <AdminSideNav />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="d-flex align-items-center sticky-div-brand justify-content-between mb-2 mt-2">
                        <div className="d-flex gap-4 align-items-center">
                            <InputGroup className="">
                                <FormControl
                                    placeholder=""
                                    aria-label="Search"
                                    aria-describedby="basic-addon2"
                                    value={this.state.search}
                                    onChange={(e) => { this.inputSearchBox(e.target.value) }}
                                    style={{ width: '200px' }}
                                />
                            </InputGroup>
                            <Button className='btn-success' onClick={this.handleSearchButton}>Search</Button>
                            <Button onClick={this.handleOpenModal}>Add Company</Button>
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <PaginationComponentV1
                                totalRecords={this.state.pagination.totalDocs}
                                itemsPerPage={this.state.perPage}
                                currentPage={this.state.pagination.page}
                                onPageChange={this.myPageChange}
                                displayPagesCount={8} />

                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <Dropdown style={{ width: '80px' }} onSelect={this.handleLimit}>
                                <Dropdown.Toggle variant="danger" id="limit">
                                    {this.state.perPage}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="10" active={this.state.perPage == 10}>10</Dropdown.Item>
                                    <Dropdown.Item eventKey="20" active={this.state.perPage == 20}>20</Dropdown.Item>
                                    <Dropdown.Item eventKey="50" active={this.state.perPage == 50}>50</Dropdown.Item>
                                    <Dropdown.Item eventKey="100" active={this.state.perPage == 100}>100</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <span>
                                {this.state.pagination.totalDocs} records
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="card card-small mb-4">

                                <div className="card-body p-0">
                                    <Table width="100%" id="company-table" className="table mb-0">
                                        <thead className="bg-light uppercase-th">
                                            <tr key="row_header">
                                                <th width="3%" scope="col" className="border-0" onClick={() => this.sortTable('_id')}>Sr. {this.state.sortBy === '_id' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('name')}>Name {this.state.sortBy === 'name' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('legalName')}>Legal Name {this.state.sortBy === 'legalName' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('country')}>Country{this.state.sortBy === 'country' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('state')}>State {this.state.sortBy === 'state' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('city')}>City{this.state.sortBy === 'city' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0" onClick={() => this.sortTable('address')}>Address{this.state.sortBy === 'address' ? (this.state.sortDirection === 1 ? '↑' : '↓') : ''}</th>
                                                <th width="10%" scope="col" className="border-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.companies.map((company, companyKey) =>
                                                    <>
                                                        <tr
                                                            key={companyKey}
                                                            data-toggle="collapse"
                                                            data-target={'.multi-collapse' + companyKey}
                                                            aria-controls={'multiCollapseExample' + companyKey}
                                                        >
                                                            <td><p className="mx-0 my-0">{this.state.pagination.pagingCounter + companyKey}</p></td>
                                                            <td><p className="mx-0 my-0">{company.name}</p></td>
                                                            <td><p className="mx-0 my-0 truncate" title={company.legalName}>{company.legalName}</p></td>
                                                            <td><p className="mx-0 my-0 truncate" title={company.country}>{company.country}</p></td>
                                                            <td><p className="mx-0 my-0 truncate capitalize-text" title={company.state}>{company.state}</p></td>
                                                            <td><p className="mx-0 my-0 truncate capitalize-text" title={company.city}>{company.city}</p></td>
                                                            <td><p className="mx-0 my-0 truncate capitalize-text" title={company.address}>{company.address}</p></td>
                                                            <td><a className="text-danger" onClick={(e) => this.removeCompany(company)} href="#"><i className="fa fa-trash"></i></a>
                                                                <a className="text-primary mx-1" onClick={(e) => this.showEditPopUp(company)} href="#"><i className="fa fa-edit"></i></a>
                                                            </td>
                                                        </tr>
                                                        <tr className={'collapse multi-collapse' + companyKey} id={'multiCollapseExample' + companyKey}>
                                                            <td colSpan="7">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <span className="text-muted d-block mb-2">Contract Date</span>
                                                                                    <input type="text" value={company.contractDate} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <span className="text-muted d-block mb-2">GST</span>
                                                                                    <input type="text" value={company.gst} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <span className="text-muted d-block mb-2">Start Date</span>
                                                                                    <input type="text" value={company.startDate} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <span className="text-muted d-block mb-2">Pincode</span>
                                                                                    <input type="text" value={company.pin} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <h5>Contacts</h5>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            company.contacts.map((contact, contactKey) =>
                                                                                <div key={contactKey} className="row">
                                                                                    <div className="col-md-3">
                                                                                        <div className="form-group">
                                                                                            <span className="text-muted d-block mb-2">Contact Person Name</span>
                                                                                            <input type="text" value={contact?.contactPersonName} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <div className="form-group">
                                                                                            <span className="text-muted d-block mb-2">Designation</span>
                                                                                            <input type="text" value={contact?.designation} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <div className="form-group">
                                                                                            <span className="text-muted d-block mb-2">Phone</span>
                                                                                            <input type="text" value={contact?.mobileNumber} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <div className="form-group">
                                                                                            <span className="text-muted d-block mb-2">Email</span>
                                                                                            <input type="text" value={contact?.emailAddress} disabled className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    }

    addNewContact() {

        let contactObject = {
            contactPersonName: '',
            mobileNumber: '',
            emailAddress: '',
            designation: '',
        }

        let contacts = this.state.contacts;

        contacts.push(contactObject)

        this.setState(() => {
            return {
                contacts
            }
        })
    }

    renderContactDetailHeader() {

        if (!this.state.contacts.length) {
            return <></>
        }

        return (
            <div style={{ marginTop: 10 }} className="row">
                <div style={{ fontWeight: 500 }} className="col-3">Contact Name</div>
                <div style={{ fontWeight: 500 }} className="col-2">Mobile Number</div>
                <div style={{ fontWeight: 500 }} className="col-3">Email Address</div>
                <div style={{ fontWeight: 500 }} className="col-3">Designation</div>
            </div>
        )
    }

    async removeContactItem(index) {

        let contacts = this.state.contacts;

        if (index > -1) {
            delete contacts[index]
        }

        await this.setState(() => {
            return {
                contacts
            }
        })
    }

    render() {
        return <>
            {this.renderHtml()}
            <Modal
                size="xl"
                show={this.state.showModal}
                onHide={this.handleCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ color: '#d32f2f' }}>Company Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" onChange={(e) => this.changeInputBox(e, 'name')} value={this.state.name} type="text" placeholder="Enter Company Name" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.name}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Legal Name</Form.Label>
                                    <Form.Control name="legalName" onChange={(e) => this.changeInputBox(e, 'legalName')} value={this.state.legalName} type="text" placeholder="Enter Legal Name" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.legalName}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control name="address" onChange={(e) => this.changeInputBox(e, 'address')} value={this.state.address} type="text" placeholder="Enter Address" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.address}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control name="city" onChange={(e) => this.changeInputBox(e, 'city')} value={this.state.city} type="text" placeholder="Enter City" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.city}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control name="state" onChange={(e) => this.changeInputBox(e, 'state')} value={this.state.state} type="text" placeholder="Enter State Name" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.city}</span>
                                </Form.Group>
                            </div>
                            <div className="col-4">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Pin</Form.Label>
                                    <Form.Control name="pin" onChange={(e) => this.changeInputBox(e, 'pin')} value={this.state.pin} type="text" placeholder="Enter PIN" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.pin}</span>
                                </Form.Group>
                            </div>
                            <div className="col-4">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control name="country" onChange={(e) => this.changeInputBox(e, 'country')} value={this.state.country} type="text" placeholder="Enter Country" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.pin}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control name="gst" onChange={(e) => this.changeInputBox(e, 'gst')} value={this.state.gst} type="text" placeholder="Enter GST" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.gst}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Contract Date</Form.Label>
                                    <Form.Control name="contractDate" onChange={(e) => this.changeInputBox(e, 'contractDate')} value={this.state.contractDate} type="date" placeholder="Contract Date" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.contractDate}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control name="startDate" onChange={(e) => this.changeInputBox(e, 'startDate')} value={this.state.startDate} type="date" placeholder="Start Date" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.startDate}</span>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control name="endDate" onChange={(e) => this.changeInputBox(e, 'endDate')} value={this.state.endDate} type="date" placeholder="End Date" />
                                    <span className="form-text-error-text">{this.state.errorMessage?.endDate}</span>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Is Child</Form.Label>
                                    <div>
                                        <Form.Check inline label="Yes" name="group1" type="radio" id={`inline-radio-1`} checked={this.state.isChild} onChange={() => this.setState({ isChild: true })} />
                                        <Form.Check inline label="No" name="group1" type="radio" id={`inline-radio-2`} checked={!this.state.isChild} onChange={() => this.setState({ isChild: false })} />
                                        <span className="form-text-error-text">{this.state.errorMessage?.isParent}</span>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-6">
                                <Form.Group className="mb-3" controlId="companies">
                                    <Form.Label>Parent Company</Form.Label>
                                    <Select
                                        value={this.state.parentCompany}
                                        onChange={this.handleChange}
                                        options={this.state.companyOption}
                                        isDisabled={!this.state.isChild}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 text-left mb-0">
                                <span style={{ color: '#ff6f00' }} className="text-uppercase page-subtitle">Contacts</span>
                            </div>
                            <div className="col-6 text-right mb-0">
                                <a style={{ color: '#ff6f00' }} href="javascript:;" onClick={this.addNewContact}>Add Contact</a>
                            </div>
                        </div>
                        {this.renderContactDetailHeader()}
                        {
                            this.state.contacts.map((contact, contactIndex) =>
                                <div key={contactIndex} style={{ marginTop: 10 }} className="row">
                                    <div className="col-3">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control onChange={(e) => this.changeContactsInputBox(e, 'contactPersonName', contactIndex)} value={`${this.state.contacts[contactIndex]?.contactPersonName || ''}`} name={`contactPersonName${contactIndex}`} type="text" placeholder="Contact Name" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-2">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control onChange={(e) => this.changeContactsInputBox(e, 'mobileNumber', contactIndex)} value={`${this.state.contacts[contactIndex]?.mobileNumber || ''}`} name={`mobileNumber${contactIndex}`} type="text" placeholder="Mobile Number" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-3">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control onChange={(e) => this.changeContactsInputBox(e, 'emailAddress', contactIndex)} value={`${this.state.contacts[contactIndex]?.emailAddress || ''}`} name={`emailAddress${contactIndex}`} type="text" placeholder="Email Address" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-3">
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control onChange={(e) => this.changeContactsInputBox(e, 'designation', contactIndex)} value={`${this.state.contacts[contactIndex]?.designation || ''}`} name={`designation${contactIndex}`} type="text" placeholder="Designation" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-1">
                                        <Button onClick={(e) => this.removeContactItem(contactIndex)} variant="danger"><i className="material-icons">do_not_disturb_on</i></Button>
                                    </div>
                                </div>
                            )
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal}>
                        Close
                    </Button>
                    <Button onClick={this.saveCompany} variant="primary">{this.state.selectedCompany ? 'Update' : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default withAlert()(connect(mapStateToProps)(authorizeAdmin(requireAuth(ListCompany))));