import React from 'react'
import Footer from '../../common/Footer.js'
import AnalystSideNav from '../../common/AnalystSideNav.js'
import AdminSideNav from '../../common/AdminSideNav.js'
import TopNav from '../../common/TopNav.js'
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeAdmin from '../../hoc/authorizeAdmin'
import { Table } from 'react-bootstrap';
import { castToServerDateFormat, castToTimeFormat } from '../../settings/index'

class ListDataUpdateLog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            perPage: 10,
            logs: [],
            pagination: {
                page: 1,
                nextPage: 1,
                prevPage: null,
                hasNextPage: true,
                hasPrevPage: false,
                pagingCounter: 1,
                totalDocs: null,
                totalPages: null
            },
            hasMoreLogData: false
        }
    }

    componentDidMount() {
        this.fetchLog();
    }

    async fetchLog(reArrangeRecords = false) {
        let reqData = {
            page: this.state.pagination.nextPage,
            perPage: this.state.perPage,
        }

        await window.axios.get('/data-log', {
            params: reqData
        }).then((res) => {
            let logs = [...this.state.logs].concat(res.data.docs)

            if (reArrangeRecords) {
                logs = res.data.docs
            }

            this.setState(() => ({
                logs,
                pagination: {
                    page: res.data.page,
                    nextPage: res.data.nextPage,
                    prevPage: res.data.prevPage,
                    hasNextPage: res.data.hasNextPage,
                    hasPrevPage: res.data.hasPrevPage,
                    pagingCounter: res.data.pagingCounter,
                    totalDocs: res.data.totalDocs,
                    totalPages: res.data.totalPages,
                },
                hasMoreLogData: res.data.hasNextPage
            }));
        })
    }

    renderLogDetail(log) {
        let string = ''
        let recordCount = log.totalAffectedRecords > 1 && log.totalAffectedRecords !== undefined ? `${log.totalAffectedRecords} records` : 'a record';
        let action = 'Updated'
        switch (log.type) {
            case 'add': {
                action = ' Added'
                break
            }
            case 'update': {
                action = ' Updated'
                break
            }
            case 'delete': {
                action = ' Deleted'
                break
            }
            default: {
                action = ' Added'
                break
            }
        }

        string += `${log.updatedBy.firstName}  ${log.updatedBy.lastName} ${action} ${recordCount} (${log.model}) on ${castToServerDateFormat(log.createdAt)} ${castToTimeFormat(log.createdAt)}`;
        //string += ` to ${log.model} Collection By ${log.updatedBy.firstName}  ${log.updatedBy.lastName}`
        //string += ` at ${castToServerDateFormat(log.createdAt)}`

        return <>{string}</>;
    }

    renderHtml() {

        return <div className="row">

            {this.props?.login?.user?.role === 'Admin' ? <AdminSideNav /> : <AnalystSideNav />}

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">

                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="page-header row no-gutters py-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 col-sm-4 text-sm-left mb-0">
                                    <span className="text-uppercase page-subtitle">Data Update Logs</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="card card-small mb-4">
                                <div className="card-body p-0 pb-3">
                                    <Table width="100%" className="table mb-0">
                                        <thead className="bg-light uppercase-th">
                                            <tr>
                                                <th width="100%" scope="col" className="border-0">Data</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.logs.map((log) =>
                                                    <tr>
                                                        <td><p>{this.renderLogDetail(log)}</p></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    }

    render() {
        return <>
            {this.renderHtml()}
        </>
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default connect(mapStateToProps)(authorizeAdmin(requireAuth(ListDataUpdateLog)));