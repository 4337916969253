import { useState } from "react";
import BarChartComponent from "../charts/barChats/BarChartComponent";
import UniversalModal from "../modal/universalModal";
import PieChartGeneric from "../charts/GenericPieChart/PieCharts";
import RadialChart from "../charts/RadiaChart/radialChart";

const SocialMediaDashboard = ({ chartData }) => {
  const { totalDocs, currentMonth, socialMediaPie, reporting } = chartData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelType, setModalType] = useState(null);

  const handleOpenModal = (type) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType(null);
  };

  return (
    <>
      <div className="container">
        <div
          className="row card-body p-0 text-center"
          style={{
            height: "100%",
            minHeight: "450px",
            width: "100%",
            margin: "auto",
          }}
        >
          <div className="col-12 col-md-6 p-2">
            <PieChartGeneric
              data={socialMediaPie}
              totalDocs={totalDocs}
              title="Platform - Distribution"
              openModel={() => handleOpenModal("Tld")}
            />
          </div>

          <div className="col-12 col-md-6 p-2 text-center">
            <RadialChart
              currentMonthData={currentMonth}
              openModel={() => handleOpenModal("total")}
              title="Enforcement Percentage"
              subTitle="(All infringements - Awaiting approval- No Action) / All infringements"
              currentMonth={currentMonth}
            />
          </div>
        </div>
        <div
          className="card-body p-0 text-center"
          style={{
            height: "100%",
            minHeight: "450px",
            width: "100%",
            margin: "auto",
          }}
        >
          <BarChartComponent
            title="Social Media Infringement by Reporting Date"
            data={reporting}
            openModel={() => handleOpenModal("Reporting")}
            reporting={true}
          />
        </div>
      </div>

      {/* modal */}
      <UniversalModal
        title={
          modelType === "Reporting"
            ? "Website Infringement by Reporting Date"
            : modelType === "Tld"
            ? "Platform - Distribution"
            : modelType === "total"
            ? "Enforcement Percentage = (All infringements - Awaiting approval- No Action) / All infringements"
            : "Default Title"
        }
        body={
          <div
            className="card-body p-0 text-center"
            style={{ height: "100%", minHeight: "500px" }}
          >
            {modelType === "Reporting" && (
              <BarChartComponent
                data={reporting}
                modal={true}
                reporting={true}
              />
            )}
            {modelType === "Tld" && (
              <PieChartGeneric
                data={socialMediaPie}
                totalDocs={totalDocs}
                modal={true}
              />
            )}
            {modelType === "total" && (
              <RadialChart
                currentMonthData={currentMonth}
                modal={true}
                currentMonth={currentMonth}
              />
            )}
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size="xl"
        centered={true}
      />
    </>
  );
};

export default SocialMediaDashboard;
