import React from "react";
import { connect } from "react-redux";
import UnauthorisedPage from '../pages/Unauthorised'
import { changeBrand } from "../store/actions/changeBrandAction";
import { changeCompany } from "../store/actions/changeCompanyAction";

const authorizeClient = (ChildComponent) => {

    class AuthorizeClientHOC extends React.Component {

        constructor(props) {
            super(props)
        }

        componentDidMount() {
            if ((this.props.selectedCompany.id === null || this.props.selectedCompany === undefined) && this.props?.login?.user?.role === 'Client') {
                this.props.dispatch(changeCompany({
                    companyName: this.props?.login?.user.companyDetails[0].name || 'All',
                    id: this.props?.login?.user.companyDetails[0]._id || null,
                }))
            }

            if ((!('platforms' in this.props.selectedBrand)
                || this.props.selectedBrand.platforms === null)
                && this.props?.login?.user?.role === 'Client') {
                this.props.dispatch(changeBrand({
                    brandName: 'All',
                    id: null,
                    companyId: this.props?.login?.user.companyDetails[0]._id || null,
                }))
            }
        }

        render() {

            if (this.props?.login?.user?.role === 'Admin' || this.props?.login?.user?.role === 'Analyst') {
                return <UnauthorisedPage />
            }

            return (
                <ChildComponent {...this.props} />
            )
        }
    }

    const mapStateToProps = (state) => ({ login: state.login, response: state.response, user: state.user, selectedBrand: state.selectedBrand, selectedCompany: state.selectedCompany })

    return connect(mapStateToProps)(AuthorizeClientHOC);
}

export default authorizeClient;