import React from "react";
import { connect } from "react-redux";
import UnauthorisedPage from '../pages/Unauthorised'

const authorizeAnalyst = (ChildComponent) => {

    class AuthorizeAnalystHOC extends React.Component {

        constructor(props){
            super(props)
        }

        render(){

            if(this.props?.login?.user?.role === 'Client'){
                return <UnauthorisedPage/>
            }

            return (
                <ChildComponent {...this.props} />
            )
        }
    }

    const mapStateToProps = (state) => ({ login: state.login, response: state.response, user: state.user })

    return connect(mapStateToProps)(AuthorizeAnalystHOC);
}

export default authorizeAnalyst;