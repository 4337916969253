export const NONE = { code: "", text: "Select Status Code" }
export const SUCCESS = { code: "55", text: "Success" };
export const IN_PROGRESS = { code: "11", text: "In Progress" };
export const AWAITING_APPROVAL = { code: "33", text: "Awaiting Approval" };
export const APPROVED = { code: "22", text: "Approved" };
export const NO_FURTHER_ACTION = { code: "99", text: "No Further Action" };
export const NO_ACTION = { code: "00", text: "No Action" };
export const PURCHASE = { code: "44", text: "Purchase" };
export const PURCHASE_IN_PROGRESS = { code: "66", text: "Purchase in progress" };
export const PURCHASE_IN_COMPLETE = { code: "77", text: "Purchase complete" };
export const CLIENT_REVIEW = { code: "88", text: "Client Review" };
export const SELF_RESOLVED = { code: "90", text: "Self Resolved" };
// new status code added on 19-dec-2022
export const PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE = { code: "101", text: "Purchase Incomplete – Product not available" };
export const PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE = { code: "102", text: "Purchase Incomplete – Seller non-responsive" };

export const fetchInfrigementAllStatus = () => {
    return [
        NONE, SUCCESS, IN_PROGRESS, AWAITING_APPROVAL, APPROVED, NO_FURTHER_ACTION, NO_ACTION, CLIENT_REVIEW, SELF_RESOLVED
    ]
}

export const fetchInfrigementEcommerceStatus = () => {
    return [
        NONE, SUCCESS, IN_PROGRESS, AWAITING_APPROVAL, APPROVED, NO_FURTHER_ACTION,
        NO_ACTION, PURCHASE, PURCHASE_IN_PROGRESS, PURCHASE_IN_COMPLETE, CLIENT_REVIEW, SELF_RESOLVED,
        PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE, PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE
    ]
}

export const getInfrigementStatusText = (code) => {
    switch (code) {
        case SUCCESS.code: {
            return SUCCESS.text
        }
        case IN_PROGRESS.code: {
            return IN_PROGRESS.text
        }
        case AWAITING_APPROVAL.code: {
            return AWAITING_APPROVAL.text
        }
        case APPROVED.code: {
            return APPROVED.text
        }
        case NO_FURTHER_ACTION.code: {
            return NO_FURTHER_ACTION.text
        }
        case NO_ACTION.code: {
            return NO_ACTION.text
        }
        case PURCHASE.code: {
            return PURCHASE.text
        }
        case PURCHASE_IN_PROGRESS.code: {
            return PURCHASE_IN_PROGRESS.text
        }
        case PURCHASE_IN_COMPLETE.code: {
            return PURCHASE_IN_COMPLETE.text
        }
        case CLIENT_REVIEW.code: {
            return CLIENT_REVIEW.text
        }
        case SELF_RESOLVED.code: {
            return SELF_RESOLVED.text
        }
        case PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.code: {
            return PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE.text
        }
        case PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.code: {
            return PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE.text
        }
        default: {
            return '';
        }
    }
}
// return all status related to purchase
export const getAllPurchaseStatusText = () => {
    return [
        PURCHASE, PURCHASE_IN_PROGRESS, PURCHASE_IN_COMPLETE,
        PURCHASE_INCOMPLETE_PRODUCT_NOT_AVAILABLE, PURCHASE_INCOMPLETE_SELLER_NOT_RESPONSIVE
    ]
}