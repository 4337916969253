import React from 'react'
import UnauthorisedPage from '../Unauthorised'
import AdminSideNav from '../../common/AdminSideNav.js'
import TopNav from '../../common/TopNav.js'
import { connect } from 'react-redux'
import requireAuth from '../../hoc/requireAuth'
import authorizeAdmin from '../../hoc/authorizeAdmin'
import { castToServerDateFormat } from '../../settings/index'
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import '../../assets/css/domain-search-page.css'
import '../../assets/css/table.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


class DomainSearchPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            authorised: true,
            companies: [],
            brands: [],
            company: '',
            brand: '',
            keywords: '',
            schedules: [],
            errorMessage: {}
        }

    }

    componentDidMount() {
        this.catchTxtFileUploadEvent();
        this.getCompanies();
        this.fetchSchedules();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.company !== this.state.company) {
            this.getBrands();
        }
    }

    async getCompanies() {
        await window.axios.get('/company', {}).then(res => {
            this.setState({
                companies: res.data.docs
            })
        });
    }

    async getBrands() {
        await window.axios.get('/brand', {
            params: {
                company: this.state.company,
                page: 1,
                perPage: 800
            }
        }).then(res => {
            this.setState({
                brands: res.data.docs
            })
        });
    }

    changeCompanySelectBox(e) {
        this.setState(() => ({
            company: e.target.value,
            brand: '',
            brands: []
        }));
    }

    changeBrandSelectBox(e) {
        this.setState(() => ({
            brand: e.target.value
        }));
    }

    async saveNewSchedule() {
        const { brand, company, keywords } = this.state;

        this.setState(() => ({
            errorMessage: null
        }))

        await window.axios.post('/domain-search-schedule/save', {
            brand, company, keywords
        }).then(res => {
            this.setState(({
                keywords: ''
            }))

            this.fetchSchedules();

            console.log('Saved successfully.');
        })
            .catch((error) => {
                const statusCode = error.response.status
                const errorData = error.response.data

                if (statusCode === 422) {
                    this.setState(() => ({
                        errorMessage: errorData.errors
                    }))
                }

                Swal.fire('Oops...', errorData.message, 'error')
            });
    }


    async fetchSchedules() {
        await window.axios.get('/domain-search-schedule/list').then(res => {
            this.setState(({
                schedules: res.data.docs
            }))
        });
    }

    catchTxtFileUploadEvent() {
        const input = document.getElementById('keywordsFile');
        input.addEventListener('change', () => {

            let keywordsFile = null;
            for (let i = 0; i < input.files.length; i++) {
                let file = input.files[i]

                if (file.type === 'text/plain') {
                    keywordsFile = file;
                    break;
                }
            }

            if (keywordsFile === null) {
                alert('Please upload a txt file');
                input.value = null;
                return;
            }

            const reader = new FileReader()
            reader.onload = event => this.setState(({ keywords: event.target.result })) // desired file content
            reader.onerror = () => alert('can not read uploaded txt file');
            reader.readAsText(keywordsFile) // you could also read images and other binaries
            input.value = null;
        })
    }

    renderHtml() {

        if (!this.state.authorised) {
            return <UnauthorisedPage />
        }

        return <div className="row">
            <AdminSideNav />

            <main className="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
                <TopNav />

                <div className="main-content-container container-fluid px-4">
                    <div className="page-header row no-gutters py-4">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6 text-left mb-0">
                                    <span className="text-uppercase page-subtitle">Schedule Domain Search</span>
                                </div>
                                <div className="col-6 text-right mb-0">
                                    <a href='/#' data-toggle="modal" data-target="#myModal2" className="text-uppercase page-subtitle">Add new schedule <i className='fa fa-plus'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="card card-small">
                                <div className="card-body card-body-table">
                                    <div className='table-container'>
                                        <table className='table-custom table table-hover' width="100%" >
                                            <thead>
                                                <tr>
                                                    <th width="10%">Sn.</th>
                                                    <th>Company</th>
                                                    <th>Brand</th>
                                                    <th>Last Executed Date</th>
                                                    <th width="20%">Scheduled Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.schedules.map((schedule, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{schedule?.company?.legalName}</td>
                                                        <td>{schedule?.brand?.brandName}</td>
                                                        <td>{schedule.lastExecutedAt ? castToServerDateFormat(schedule?.lastExecutedAt) : ''}</td>
                                                        <td>{castToServerDateFormat(schedule?.createdAt)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* right side modal */}
                    <div className="modal right fade" id="myModal2" data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel2">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" id="myModalLabel2">Schedule new keywords</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label>Company</label>
                                                <select onChange={(e) => this.changeCompanySelectBox(e)} className='form-control'>
                                                    <option>Select Company</option>
                                                    {
                                                        this.state.companies.map((company, companyIndex) =>
                                                            <option key={companyIndex} value={company.id}>{company.name}</option>
                                                        )
                                                    }
                                                </select>
                                                <span className="form-text-error-text">{this.state.errorMessage?.company}</span>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label>Brand</label>
                                                <select onChange={(e) => this.changeBrandSelectBox(e)} className='form-control'>
                                                    <option>Select Brand</option>
                                                    {
                                                        this.state.brands.map((brand, brandKey) =>
                                                            <option key={brandKey} value={brand.id}>{brand.brandName}</option>
                                                        )
                                                    }
                                                </select>
                                                <span className="form-text-error-text">{this.state.errorMessage?.brand}</span>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label>Keywords (New line each)</label>
                                                <textarea value={this.state.keywords} onChange={(e) => (this.setState({ keywords: e.target.value }))} rows={6} className='form-control'></textarea>
                                                <span className="form-text-error-text">{this.state.errorMessage?.keywords}</span>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <div className='form-group'>
                                                <label>Upload keywords via file</label>
                                                <input id='keywordsFile' type='file' className='form-control' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={() => this.saveNewSchedule()} className='btn btn-primary btn-sm'>Save Schedule</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>
        </div>
    }

    render() {
        return <>
            {this.renderHtml()}
        </>;
    }
}

const mapStateToProps = (state) => ({ login: state.login, response: state.response })

export default connect(mapStateToProps)(authorizeAdmin(requireAuth(DomainSearchPage)));