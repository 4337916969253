import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { validatePassword } from "../helpers";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '../styles/login.css';

export default function ResetPassword() {

    const { token = '' } = useParams();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [cpassword, setCPassword] = useState(false);
    const response = useSelector(store => store.response);
    const [validateError, setValidateError] = useState('');
    let history = useHistory();

    const updatePassword = (e) => {
        e.preventDefault();
        const isLogin = 0;
        const isValidPassword = validatePassword(newPassword, cpassword);
        if (isValidPassword === 1) {
            window.axios.post(`users/v1/updatepassword`, { token, newPassword, isLogin })
                .then((response) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: "Password updated successfully"
                    });
                    setTimeout(() => {
                        history.push('/login');
                        window.location.reload();
                    }, 1000)
                })
                .catch((error) => {
                    console.log('error');
                })
        } else if (isValidPassword === 2) {
            setValidateError('Password must be at least 8 characters long');
        } else if (isValidPassword === 3) {
            setValidateError('Passwords do not match');
        } else if (isValidPassword === 4) {
            setValidateError('Password must contain at least one uppercase letter');
        } else if (isValidPassword === 5) {
            setValidateError('Password must contain at least one lowercase letter');
        } else if (isValidPassword === 6) {
            setValidateError('Password must contain at least one digit');
        } else {
            setValidateError('Password must contain at least one special character');
        }
    }


    useEffect(() => {
        if (token) {
            window.axios.post(`users/verify-token/${token}`)
                .then((response) => {
                    if (response.data.status) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                    }
                })
                .catch((error) => {
                    console.log('error');
                })
        }
    }, [])
    return (
        <div className="row">
            <div className="col px-0 mx-0">
                <div className="side-background"></div>
            </div>
            {isAuthenticated ? (
                <div className="col login-form-section">
                    <div className="logo-section"></div>


                    <div className='container'>
                        <h3>Change Your Password</h3>
                        {validateError.length ? <p style={{ color: 'red', marginTop: '1%' }}>{validateError}</p>
                            :
                            <p style={{ marginTop: '1%' }}>A strong password helps prevent unauthorized<br />access to your email account</p>}
                        <form autoComplete="off" className="form-resetpassword mb-0" onSubmit={updatePassword}>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingInputPassword" onChange={e => setNewPassword(e.target.value)} placeholder="Password" />
                                <label htmlFor="floatingInputPassword">Password</label>
                                {response?.payload?.data?.errors?.username && <p className="text-danger">{response?.payload?.data?.errors?.username}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input type="password" className="form-control" id="floatingInputPassword" onChange={e => setCPassword(e.target.value)} placeholder="Password" />
                                <label htmlFor="floatingInputPassword">Confirm Password</label>
                                {response?.payload?.data?.errors?.password && <p className="text-danger">{response?.payload?.data?.errors?.newPassword}</p>}
                            </div>
                            <button className="login-btn m-2" type="submit">Submit</button>
                        </form>
                        <div>
                            {/* <button className="btn" onClick={() => { setIsForgotPassword(!isForgotPassword) }} style={{ backgroundColor: '#f8433f', color: 'white' }} type="submit">Back to Sign in</button> */}
                        </div>
                    </div>
                </div>) :
                (
                    <div className="col text-center ">
                        <p style={{ margin: "50% 0%" }}>Incorrect URL / Broken URL</p>
                    </div>
                )}
        </div>
    );
}