import { getClientDateFilter } from "../storage";
import * as moment from 'moment'

const initialState = getClientDateFilter() ? getClientDateFilter() : {startDate: moment().startOf('month'), endDate: moment().endOf('month')}

export const clientDateFilterReducer = (state = initialState, action) =>  {
    switch(action.type){
        case 'CHANGE_CLIENT_DATE_FILTER': {
            return {
                startDate: action.filter?.startDate,
                endDate: action.filter?.endDate
            }
        }
        default: {
            return state
        }
    }
}