import React from "react";
import PlatformDropdownCompoent from '../../../components/PlatformDropdownCompoent';
import CompanyDropdownComponent from '../../../components/CompanyDropdownComponent';
import BrandDropdownComponent from '../../../components/BrandDropdownComponent';
import ReviewDataFormComponent from "./ReviewDataFormComponent";

const ReviewDataPresentation = (props) => {
    return <>
        <div className="col my-2 position-fixed">
                <div className="card card-small mb-4 mt-2">
                        <div className="card-body"> 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 font-size-12 text-uppercase">Company</label>
                                                <CompanyDropdownComponent className="form-control" onCompanyChanged={(company) => props.updateSelectedCompany(company)}/>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 text-uppercase font-size-12">Brand</label>
                                                <BrandDropdownComponent className="form-control" onBrandChanged={(brand) => {props.updateSelectedBrand(brand)}} selectedCompany={props.selectedCompany?.id}  />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 text-uppercase font-size-12">Platform</label>
                                                <PlatformDropdownCompoent className="form-control" onPlatformChanged={(platform) => props.updateSelectedPlatform(platform)} />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-success">Fetch Data</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>        
        </div>

        <div className="col my-2">
            <ReviewDataFormComponent records={props.records} {...props} />
        </div>
    </>
}

export default ReviewDataPresentation;