import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../store/actions/authActions";
import { changeBrand } from "../store/actions/changeBrandAction";
import { changeCompany } from "../store/actions/changeCompanyAction";
import { Link } from "react-router-dom";
import { castToTimeFormat, castToDefaultDateFormat } from "./../settings/index";
import { validatePassword } from "../helpers";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { markNotification } from '../store/actions/authActions';

const TopNav = (props) => {
  const [role, setRole] = useState("");
  const [brand, setBrand] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  const [companySearch, setCompanySearch] = useState("");
  const [iconStyle, setIconStyle] = useState(true);

  let history = useHistory();

  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const user = useSelector((state) => state.login.user);
  const token = useSelector((state) => state.login.token);
  const selectedBrand = useSelector((state) => state.selectedBrand);
  const selectedCompany = useSelector((state) => state.selectedCompany);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/login");
    }
  }, [isAuthenticated, props.history]);

  useEffect(() => {
    getNotifications();
  }, []);

  // similiar to componentDidMount
  useEffect(() => {
    setRole(user?.role);
    localStorage.setItem('iconStyle', 'hkjj');
    if (!user?.seen) {
      setIconStyle(false);
      localStorage.setItem('iconStyle', 'hkjj');
    }

    if (user?.brandDetails) {
      setBrand(user?.brandDetails);
    }
    if (user?.companyDetails) {
      setCompanies(user?.companyDetails);
    }
  }, []);

  const getNotifications = async () => {
    await window.axios.get("/notifications").then((res) => {
      setNotifications(res.data.docs);
    });
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutUser({ token }));
  };

  const bellIcon = () => {
    return {
      color: iconStyle ? 'grey' : 'red'
    }
  }
  const markNotificationsAsSeen = async () => {
    setIconStyle(true);
    let params = {
      userId: user.id,
      seen: true
    }
    dispatch(markNotification(params));
  }

  const updatePassword = async (e) => {
    e.preventDefault();
    const { value: formValues } = await Swal.fire({
      title: 'Change your password',
      html:
        '<input type ="password" id="current-password" class="swal2-input" placeholder="Current Password">' +
        '<input type ="password" id="new-password" class="swal2-input" placeholder="New Password">' +
        '<input type ="password" id="confirm-password" class="swal2-input" placeholder="Confirm Password">' +
        '<span id="password-strength"></span>',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Update',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const password = document.getElementById('current-password').value;
          const newPassword = document.getElementById('new-password').value;
          const confirmPassword = document.getElementById('confirm-password').value;
          const isValidPassword = validatePassword(newPassword, confirmPassword);
          if (isValidPassword === 2) {
            Swal.showValidationMessage(`Request failed: Password must be at least 8 characters long`);
          } else if (isValidPassword === 3) {
            Swal.showValidationMessage(`Passwords do not match`);
          } else if (isValidPassword === 4) {
            Swal.showValidationMessage(`Password must contain at least one uppercase letter`);
          } else if (isValidPassword === 5) {
            Swal.showValidationMessage(`Password must contain at least one lowercase letter`);
          } else if (isValidPassword === 6) {
            Swal.showValidationMessage(`Password must contain at least one digit`);
          } else if (isValidPassword === 7) {
            Swal.showValidationMessage(`Password must contain at least one special character`);
          } else {
            const isLogin = 1;
            await window.axios.post(`users/v1/updatepassword`, { token, password, newPassword, isLogin }).then((res) => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                }
              });
              Toast.fire({
                icon: "success",
                title: "Password updated successfully"
              });
              setTimeout(() => {
                dispatch(logoutUser({ token }));
              }, 2000);
            });
          }
        } catch (error) {
          Swal.showValidationMessage(`Current password incorrect.`);
        }
      },
    });
  };

  function changeSelectedBrand(detail) {
    if (detail.id) {
      dispatch(changeBrand({ brandName: detail?.brandName, id: detail?.id }));
    } else {
      dispatch(changeBrand({ brandName: detail?.brandName, id: detail?.id, companyId: selectedCompany.id }));
    }
  }

  function changeSelectedCompany(detail) {
    dispatch(
      changeCompany({
        companyName: detail?.name,
        id: detail?._id,
      })
    );
    dispatch(
      changeBrand({
        brandName: 'All',
        id: null,
        companyName: detail?.name,
        companyId: detail?._id,
      })
    );
    return history.push("/client-dashboard");
  }

  function onClickNotifications(notification) {
    let pathName = "";

    switch (notification.model) {
      case "Domain": {
        pathName = "/domain-dashboard";
        break;
      }

      case "AppStore": {
        pathName = "/app-store-dashboard";
        break;
      }

      case "SocialMedia": {
        pathName = "/social-media-dashboard";
        break;
      }

      case "Website": {
        pathName = "/website-dashboard";
        break;
      }

      case "Ecommerce": {
        pathName = "/ecommerce-dashboard";
        break;
      }

      default: {
        break;
      }
    }

    history.push({
      pathname: pathName,
      state: { notification: notification._id },
    });
  }

  const printNotification = () => {
    if (!notifications.length) {
      return (
        <div className="notification__content">
          <span className="notification__category">No Notification.</span>
        </div>
      );
    }

    return (
      <>
        {notifications.map((notification) => (
          <a
            className="dropdown-item"
            onClick={(e) => onClickNotifications(notification)}
            href="javascript:;"
          >
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">notifications_active</i>
              </div>
            </div>
            <div className="notification__content">
              <p
                dangerouslySetInnerHTML={{
                  __html: notification.notificationText,
                }}
              ></p>
              <p className="notification-time">
                <i className="material-icons">access_time</i>
                {castToDefaultDateFormat(notification.createdDate)}{" "}
                {castToTimeFormat(notification.createdDate)}
              </p>
            </div>
          </a>
        ))}
      </>
    );
  };

  const printCompany = () => {
    if (role === "Client") {
      return (
        <li className="nav-item border-right dropdown min-width-200-px">
          <a
            className="nav-link dropdown-toggle text-nowrap px-3 my-2"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            &nbsp;
            <span className="d-none d-md-inline-block">
              {selectedCompany ? selectedCompany.companyName : 'All'}
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu brand-dropdown">
            <span className="dropdown-item dropdown-large textbox-dropdown">
              <input
                type="text"
                className="form-control brand-search-input shadow-none"
                placeholder="Search..."
                onChange={(event) => setCompanySearch(event.target.value)}
              />
            </span>
            <div className="dropdown-divider"></div>
            {companies
              .filter((item) => {
                if (companySearch == "") {
                  return item;
                } else if (
                  item.name
                    .toLowerCase()
                    .includes(companySearch.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((item, index) => (
                <>
                  <a
                    key={index}
                    onClick={(e) => {
                      changeSelectedCompany(item);
                      window.location.reload(); // Refresh the page
                    }}
                    className="href-beautify dropdown-item"
                    href="#"
                  >
                    {item.name}
                  </a>

                  <div className="dropdown-divider"></div>
                </>
              ))}
          </div>
        </li>
      );
    }
  };

  const printBrand = () => {
    if (role === "Client") {
      return (
        <li className="nav-item border-right dropdown min-width-200-px">
          <a
            className="nav-link dropdown-toggle text-nowrap px-3 my-2"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            &nbsp;
            <span className="d-none d-md-inline-block">
              {selectedBrand ? selectedBrand.brandName : "All"}
            </span>
          </a>
          <div className="dropdown-menu dropdown-menu brand-dropdown">
            <a
              onClick={(e) =>
                changeSelectedBrand({ brandName: "All", id: null })
              }
              className="dropdown-item dropdown-large"
              href="#"
            >
              All
            </a>
            <div className="dropdown-divider"></div>
            <span className="dropdown-item dropdown-large textbox-dropdown">
              <input
                type="text"
                className="form-control brand-search-input shadow-none"
                placeholder="Search..."
                onChange={(event) => setsearchTerm(event.target.value)}
              />
            </span>
            <div className="dropdown-divider"></div>

            {brand
              .filter((brandDetail) => {
                if (brandDetail.company === selectedCompany.id) {
                  return brandDetail;
                }
              })
              .filter((brandDetail) => {
                if (searchTerm == "") {
                  return brandDetail;
                } else if (
                  brandDetail.brandName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return brandDetail;
                }
              })
              .map((brandDetail, brandIndex) => (
                <>
                  <a
                    style={
                      brandDetail.status === false
                        ? { display: "none" }
                        : { display: "" }
                    }
                    key={brandIndex}
                    onClick={(e) => {
                      changeSelectedBrand(brandDetail);
                      window.location.reload(); // Refresh the page
                    }}
                    className="href-beautify dropdown-item"
                    href="#"
                  >
                    {brandDetail.brandName}
                  </a>

                  <div
                    style={
                      brandDetail.status === false
                        ? { display: "none" }
                        : { display: "" }
                    }
                    className="dropdown-divider"
                  ></div>
                </>
              ))}
          </div>
        </li>
      );
    }
  };

  function printShowDataLog() {
    if (role === "Admin") {
      return (
        <>
          <Link className="dropdown-item" to="/data-update-log">
            <i className="material-icons">summarize</i> Data Logs
          </Link>
          <div className="dropdown-divider"></div>
        </>
      );
    }
  }

  return (
    <div className="main-navbar sticky-top bg-white">
      <nav className="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
        <form
          action="#"
          className="main-navbar__search w-100 d-none d-md-flex d-lg-flex"
        >
          {/* <div className="input-group input-group-seamless ml-3">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <i className="fas fa-search"></i>
          </div>
        </div>
        <input className="navbar-search form-control" type="text" placeholder="Search for something..." aria-label="Search" /> </div> */}
        </form>
        <ul className="navbar-nav border-left flex-row ">
          <li className="nav-item border-right dropdown notifications" onClick={markNotificationsAsSeen}>
            <a
              className="nav-link nav-link-icon text-center"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="nav-link-icon__wrapper">
                <i className="material-icons" style={bellIcon()}>notifications</i>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-small"
              aria-labelledby="dropdownMenuLink"
            >
              {printNotification()}
              <Link
                className="dropdown-item notification__all text-center"
                to="/mynotifications"
              >
                {" "}
                View all Notifications{" "}
              </Link>
            </div>
          </li>
          {/* <li className="nav-item border-right dropdown notifications">
        <a className="nav-link nav-link-icon text-center" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div className="nav-link-icon__wrapper">
            <i className="material-icons">settings</i>
          </div>
        </a>
        <div className="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuLink">
          <a className="dropdown-item" href="#">
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">&#xE6E1;</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category">Analytics</span>
              <p>Your website’s active users count increased by
                <span className="text-success text-semibold">28%</span> in the last week. Great job!</p>
            </div>
          </a>
          <a className="dropdown-item" href="#">
            <div className="notification__icon-wrapper">
              <div className="notification__icon">
                <i className="material-icons">&#xE8D1;</i>
              </div>
            </div>
            <div className="notification__content">
              <span className="notification__category">Sales</span>
              <p>Last week your store’s sales count decreased by
                <span className="text-danger text-semibold">5.52%</span>. It could have been worse!</p>
            </div>
          </a>
          <a className="dropdown-item notification__all text-center" href="#"> View all Notifications </a>
        </div>
      </li> */}
          {/* company */}

          {printCompany()}

          {/* brand */}

          {printBrand()}

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle text-nowrap px-3"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="user-avatar rounded-circle mr-2"
                src="https://via.placeholder.com/150"
                alt="User Avatar"
              />
              {isAuthenticated && (
                <span className="d-none d-md-inline-block">{`${user.firstName} ${user.lastName}`}</span>
              )}
            </a>
            <div className="dropdown-menu dropdown-menu-small">
              {printShowDataLog()}
              {isAuthenticated && user.isGoogleAuth !== 1 && (
                <>
                  <a
                    className="dropdown-item"
                    onClick={updatePassword}
                    href="#"
                  >
                    <i className="material-icons">key</i> Update Password
                  </a>
                  <div className="dropdown-divider"></div>
                </>
              )}
              <a
                className="dropdown-item text-danger"
                onClick={logout}
                href="#"
              >
                <i className="material-icons text-danger">&#xE879;</i> Logout{" "}
              </a>
            </div>
          </li>
        </ul>
        <nav className="nav">
          <a
            href="#"
            className="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center border-left"
            data-toggle="collapse"
            data-target=".header-navbar"
            aria-expanded="false"
            aria-controls="header-navbar"
          >
            <i className="material-icons">&#xE5D2;</i>
          </a>
        </nav>
      </nav>
    </div>
  );
};

export default TopNav;
