import React from 'react'
import { Button, Form } from 'react-bootstrap';
import * as moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

class TmchNotificationFormComponent extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            dateFormat: "Y-MM-DD",
            domain: "",
            date: "",
            recordId: this.props.recordId,
            recordIndex: this.props.recordIndex,
            errorMessage: {}
        }

        this.handleSaveTmchNotification = this.handleSaveTmchNotification.bind(this)
    }

    async changeInputBox(e, column){
       
        let state = {}
        state[column] = e.target.value
        await this.setState(() => {
            return state
        })
    }

    async handleSaveTmchNotification(){

        this.setState(() => ({
            errorMessage: null
        }))

        let endpoint = `/tmch/${this.props.recordId}/add-notification`
        let requestMethod = 'post'

        await window.axios[requestMethod](endpoint, {
            domain: this.state.domain,
            date: this.state.date,
        })
        .then((response) => {
            this.props.onSaveTmchNotificationData(response.data, this.props.recordIndex )
            Swal.fire('Yeah..', response.data.message, 'success')
        })
        .catch((error) => {
            const statusCode = error.response.status
            const errorData = error.response.data

            if(statusCode === 422){
                this.setState(() => ({
                    errorMessage: errorData.errors
                }))
            }

            Swal.fire('Oops...', errorData.message, 'error')
        })
    }

    render(){
        return <>
            <Form>
                <div className="row">
                    <div className="col-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Domain</Form.Label>
                            <Form.Control name="domain" onChange={(e) => this.changeInputBox(e, 'domain')} value={this.state.domain} type="text" placeholder="Enter Domain Name" />
                            <span className="form-text-error-text">{this.state.errorMessage?.domain}</span>
                        </Form.Group>
                    </div>
                    <div className="col-12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Date</Form.Label>
                            <Form.Control name="date" onChange={(e) => this.changeInputBox(e, 'date')} value={this.state.date} type="date" />
                            <span className="form-text-error-text">{this.state.errorMessage?.date}</span>
                        </Form.Group>
                    </div>
                    <div className="col-12">
                        <Button onClick={this.handleSaveTmchNotification} variant="success">Save</Button>
                    </div>
                </div>
            </Form>
        </>
    }
}

export default TmchNotificationFormComponent;