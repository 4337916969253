import PlatformDropdownCompoent from '../../../components/PlatformDropdownCompoent';
import CompanyDropdownComponent from '../../../components/CompanyDropdownComponent';
import BrandDropdownComponent from '../../../components/BrandDropdownComponent';
import StagingDataFormComponent from './StagingDataFormComponent';
import StagingDataDraftDropdownComponentDropdown from './../../../components/StagingDataDraftDropdownComponentDropdown'
import propTypes from 'prop-types';

const StagingDataPresentation = (props) => {
    return (
        <>
            <div className="col my-2 position-fixed">
                <div className="card card-small mb-4 mt-2">
                        <div className="card-body"> 
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 font-size-12 text-uppercase">Company</label>
                                                <CompanyDropdownComponent className="form-control" onCompanyChanged={(company) => props.updateSelectedCompany(company)}/>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 text-uppercase font-size-12">Brand</label>
                                                <BrandDropdownComponent className="form-control" onBrandChanged={(brand) => {props.updateSelectedBrand(brand)}} selectedCompany={props.selectedCompany?.id}  />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 text-uppercase font-size-12">Platform</label>
                                                <PlatformDropdownCompoent className="form-control" onPlatformChanged={(platform) => props.updateSelectedPlatform(platform)} />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="h6 text-uppercase font-size-12">Or Select Draft (Optional)</label>
                                                <StagingDataDraftDropdownComponentDropdown 
                                                    platform={props.selectedPlatform?.key} 
                                                    brand={props.selectedBrand?.id} 
                                                    company={props.selectedBrand?.id} 
                                                    className="form-control" 
                                                    draftsChangeDetector={props.draftsChangeDetector}
                                                    onDraftChanged={(platform) => props.updateSelectedDraft(platform)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>        
            </div>

            {(props.selectedPlatform?.key) &&<div className="col my-2 h6">
                <div className="col-14 text-right mb-2 ">
                    <a href="#" className="d-inline-block text-primary h4 px-1" onClick={() => props.addNewStagingRecords()}><i className="fa fa-plus-square" aria-hidden="true"></i></a>
                    <a href="#" className="d-inline-block text-success h4 px-1" onClick={() => props.submitDraft()}><i className="fa fa-check-circle" aria-hidden="true"></i></a>
                </div>
            </div>}

            <div className="col my-2">
                <StagingDataFormComponent records={props.records} {...props}/>
            </div>
        </>
    )
}

StagingDataPresentation.propTypes = {
    updateSelectedBrand: propTypes.func,
    updateSelectedCompany: propTypes.func,
    updateSelectedPlatform: propTypes.func,
    addNewStagingRecords: propTypes.func,
    updateRecordValue: propTypes.func,
    handleInputChange: propTypes.func,
    updateSelectedDraft: propTypes.func
}

export default StagingDataPresentation;