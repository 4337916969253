import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";

const UniversalModal = ({
  title,
  body,
  footer,
  isOpen,
  onClose,
  centered = true,
  scrollable = false,
  customWidth = "80%", // Customizable width via props
}) => {
  const modalRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        modalRef.current &&
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        modalRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    const handleEscapeKey = (event) => {
      if (isOpen && event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return createPortal(
    <div
      ref={modalRef}
      className="modal fade show"
      tabIndex="-1"
      style={{
        display: "block",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        ref={contentRef}
        className={`modal-dialog ${centered ? "modal-dialog-centered" : ""} ${
          scrollable ? "modal-dialog-scrollable" : ""
        }`}
        style={{
          maxWidth: customWidth,
          width: customWidth,
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <strong>{title}</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">{body}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default UniversalModal;
