import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";

import PieChartComponents from "./PieCharts/PieChartComponent";
import BarChartComponent from "./barChats/BarChartComponent";
import UniversalModal from "../modal/universalModal";

const DashboardCard = ({
  title,
  data,
  link,
  children,
  useAccordion = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelType, setModalType] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleOpenModal = (type) => {
    setIsModalOpen(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType(null);
  };

  const { totalDocs, pie, bar } = data;

  const cardContent = (
    <div
      className="row card-body p-0 text-center"
      style={{
        height: "100%",
        minHeight: "450px",
        width: "100%",
        margin: "auto",
      }}
    >
      <div className="col-12 col-md-8 p-2">
        <BarChartComponent
          title={`Monthly ${title} Infringements`}
          data={bar}
          openModel={() => handleOpenModal("bar")}
        />
      </div>

      <div className="col-12 col-md-4 p-2 text-center">
        <PieChartComponents
          data={pie}
          title={`${title} Infringements Status`}
          totalDocs={totalDocs}
          openModel={() => handleOpenModal("pie")}
        />
      </div>

      {children}
    </div>
  );

  return (
    <>
      <div className="col">
        {useAccordion ? (
          <div className="card card-small mb-4">
            <div className="card-header border-bottom d-flex justify-content-between align-items-center">
              <h6
                className="my-auto"
                style={{ width: "fit-content", color: "red" }}
              >
                {title}
              </h6>

              <button
                className="btn btn-outline-light btn-sm"
                onClick={() => setIsOpen(!isOpen)}
              >
                <img
                  src={`/icons/${isOpen ? "down-arrow" : "up-arrow"}.svg`}
                  alt={isOpen ? "openIcon" : "closeIcon"}
                  width={14}
                  height={14}
                />
              </button>
            </div>

            <Collapse in={isOpen}>
              <div>{cardContent}</div>
            </Collapse>
          </div>
        ) : (
          <div className="card card-small mb-4">
            <div className="card-header border-bottom">
              <h6 className="my-auto " style={{ width: "fit-content" }}>
                <Link className="nav-link" to={link}>
                  <span style={{ color: "red" }}>{title}</span>
                </Link>
              </h6>
            </div>
            {cardContent}
          </div>
        )}
      </div>

      {/* modal */}
      <UniversalModal
        title={
          modelType === "bar"
            ? `Monthly ${title} Infringements`
            : `${title} Infringements by Status`
        }
        body={
          <div
            className="card-body p-0 text-center"
            style={{ height: "100%", minHeight: "500px" }}
          >
            {modelType === "bar" ? (
              <div className="col-12">
                <BarChartComponent data={bar} modal={true} />
              </div>
            ) : (
              <div className="col-12 text-center">
                <PieChartComponents
                  data={pie}
                  totalDocs={totalDocs}
                  modal={true}
                />
              </div>
            )}
          </div>
        }
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        size="xl"
        centered={true}
      />
    </>
  );
};

export default DashboardCard;
